import Modal from "../../UI/Modal";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  authenticateAdmin2,
  authenticateAdmin,
  createAdminAction,
  setShowKeyboard,
  setKeyboardConfiguration
} from "../../../redux/slices";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../../utils/schemas";
import { Spinner } from "../../UI/Spinner";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../context/KeyboardContext";

const OpenDrawer = ({ openStatus, closeFunc }) => {
  const { adminAuthError2, adminAuthStatus2 } = useSelector(
    state => state.items
  );
  const { t } = useTranslation(["Common", "Admin"]);
  const [userNumber, setUserNumber] = useState("");
  const [pin, setPin] = useState("");
  const dispatch = useDispatch();
  const printRef = useRef();
  const { setSetSharedInput } = useKeyboard()

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      userNumber: "",
      pin: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    setValue("userNumber", userNumber);
    setValue("pin", pin);
  }, [userNumber, pin]);

  const onSubmit = data => {
    dispatch(authenticateAdmin2({ ...data, action: 7 }))
      .unwrap()
      .then(() => {
        dispatch(
          createAdminAction({
            doneById: data.userNumber,
            details: null,
            action: 7
          })
        );
        handlePrint();
        setValue("userNumber", "");
        setValue("pin", "");
        setPin("");
        setUserNumber("");
        closeFunc();
      })
      .catch(err => {});
  };

  return (
    <Modal openStatus={openStatus} panelStyles="bg-white w-[30vw] ">
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className=" flex justify-center p-2"
      >
        <div className="">
          <p className=" font-bold text-[20px]">
            {" "}
            {t("Admin:modals.drawer.title")}
          </p>
          <div className="mt-2 deleteModal">
            {adminAuthError2 && (
              <p className="text-red-500 text-center"> {adminAuthError2}</p>
            )}
            <label>{t("admin_form.label.user")}</label>
            <br />
            <input
              type="text"
              autoComplete="off"
              value={userNumber}
              placeholder={t("admin_form.placeholder.user")}
              className=" w-[100%]  h-[35px] border border-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numeric",
                    sharedInput: userNumber,
                    placeHolder: t("keyboard.placeholder.user"),
                    // setSharedInput: x => {
                    //   setUserNumber(x);
                    // }
                  })
                );
                setSetSharedInput(() => setUserNumber)
              }}
            />
            {errors?.userNumber?.message && (
              <p className="text-red-500 text-center">
                {errors?.userNumber?.message}
              </p>
            )}
          </div>
          <div className="mt-2 deleteModal">
            <label> {t("admin_form.label.pin")}</label> <br />
            <input
              type="password"
              value={pin}
              autoComplete="new-password"
              placeholder={t("admin_form.placeholder.pin")}
              className=" w-[100%] h-[35px] border border-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numericPin",
                    sharedInput: pin,
                    placeHolder: t("keyboard.placeholder.user"),
                    // setSharedInput: x => {
                    //   setPin(x);
                    // }
                  })
                );
                setSetSharedInput(() => setPin)
              }}
            />
            {errors?.pin?.message && (
              <p className="text-red-500 text-center">{errors.pin.message}</p>
            )}
          </div>
          {/* {showError && <p className="text-red-700">Invalid pin</p>} */}

          <div className="mt-4 mb-3 ">
            <button
              type="submit"
              className="inline-flex mr-2 border justify-center rounded-md border-blue-900 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >
              <span> {t("button.submit")} </span>
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                status={adminAuthStatus2}
              />
            </button>
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={() => {
                setValue("userNumber", "");
                setValue("pin", "");
                setPin("");
                setUserNumber("");
                closeFunc();
              }}
            >
              {t("button.cancel")}
            </button>
          </div>
        </div>
      </form>
      <div ref={printRef}></div>
    </Modal>
  );
};
export default OpenDrawer;
