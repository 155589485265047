import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import { DateTimeInput } from "../../UI/DateTimeInput";
import { clearOrder, getShowtimes } from "../../../redux/slices";
import { compareDiffInHours } from "../../../utils/dateHelpers";
import startOfDay from "date-fns/startOfDay";
import { useTranslation } from "react-i18next";

const ShowtimeDateModal = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["Home", "Common"]);
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState(new Date().toISOString());
  const [dateError, setDateError] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    const checkDiff = compareDiffInHours(new Date(selectedTime), new Date());

    if (checkDiff >= -24) {
      dispatch(getShowtimes({ startDate: selectedTime }))
        .unwrap()
        .then(data => {
          setDateError(false);
          closeFunc();
          setSelectedTime(data.paramFilter.dateTo);
          dispatch(clearOrder());
        });
    } else {
      setDateError(true);
    }
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex mt-[10%]  justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center mb-4"
                >
                  {t("ticket.date.title")}
                </Dialog.Title>
                <form onSubmit={onSubmit}>
                  {dateError && (
                    <p className="text-red-500 text-center">
                      {t("ticket.date.error")}
                    </p>
                  )}
                  <div className="px-[50px]">
                    <DatePicker
                      placeholderText={t("ticket.date.placeholder")}
                      selected={new Date(selectedTime)}
                      onChange={value => {
                        setSelectedTime(startOfDay(value).toISOString());
                      }}
                      customInput={
                        <DateTimeInput
                          className="bg-primary"
                          dateFormat={"EEE, MMM dd, yyyy"}
                        />
                      }
                    />
                  </div>
                  <div className="mt-4 flex justify-center">
                    <button
                      type="submit"
                      className="inline-flex mr-2 border justify-center rounded-md border-blue-900 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("ticket.date.submit")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        closeFunc();
                        setDateError(false);
                      }}
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default ShowtimeDateModal;
