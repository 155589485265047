import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import NumPad from "react-numpad";
import {
  getTotals,
  setShowKeyboard,
  setKeyboardConfiguration,
} from "../../redux/slices";
import { useSelector, useDispatch } from "react-redux";
import { STATUS } from "../../utils/constants";
import { numToCurrency } from "../../utils/currencyHelper";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../context/KeyboardContext";
const CashChangeModal = ({
  openStatus,
  closeFunc,
  setPaymentMode,
  setMethodsList,
  cashAmount,
  setCashAmount,
}) => {
  const [feedBackMsg, setFeedBackMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const orders = useSelector((state) => state.orders);
  const { offlineMode, cinemaSettings } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { orderTotalAmount } = orders;
  const { i18n, t } = useTranslation(["OrderPayment", "Common"]);
    const { setSetSharedInput } = useKeyboard();

  useEffect(() => {
    dispatch(getTotals());
  }, [orders, dispatch]);

  const resetIsDisabled = () => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (cashAmount < orderTotalAmount) {
      setFeedBackMsg(
        ` 
        ${t("OrderPayment:cashChange_modal.feedBack.order", {
          cashAmount: numToCurrency(+cashAmount),
          totalAmount: numToCurrency(orders.orderTotalAmount),
        })}
          `
      );
      setIsError(true);
      return;
    }

    if (cashAmount > orderTotalAmount) {
      setFeedBackMsg(
        ` 
    ${t("OrderPayment:cashChange_modal.feedBack.change", {
      change: numToCurrency(cashAmount - orderTotalAmount),
    })}
        `
      );
      setIsError(false);
      return;
    }
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t("header.cashPayment")}
                </Dialog.Title>
                <form onSubmit={onSubmit}>
                  {feedBackMsg && (
                    <p
                      className={
                        isError
                          ? " text-red-600 text-center"
                          : " text-blue-600 text-center"
                      }
                    >
                      {feedBackMsg}
                    </p>
                  )}
                  <div className="mt-2 deleteModal">
                    <input
                      type="text"
                      value={cashAmount}
                      placeholder={t("Common:input.placeholder.amount")}
                      className=" w-[200px]  h-[35px] border border-secondary rounded py-2 px-3"
                      onClick={() => {
                        dispatch(setShowKeyboard(true));
                        dispatch(
                          setKeyboardConfiguration({
                            type: "numeric",
                            sharedInput: cashAmount,
                            placeHolder: t(
                              "Common:keyboard.placeholder.amount"
                            ),
                            // setSharedInput: (x) => {
                            //   setCashAmount(x);
                            // },
                          })
                        );
                        setSetSharedInput(() => setCashAmount)
                      }}
                    />
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent ml-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                    >
                      {t("cashChange_modal.getchange_btn")}
                    </button>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      disabled={
                        offlineMode || cinemaSettings?.orderCreation === 1
                          ? isDisabled
                          : false
                      }
                      onClick={() => {
                        if (
                          offlineMode ||
                          cinemaSettings?.orderCreation === 1
                        ) {
                          setIsDisabled(true);
                          resetIsDisabled();
                          clearTimeout(resetIsDisabled);
                        }

                        if (orders.createOrderStatus !== STATUS.PENDING) {
                          setPaymentMode([
                            {
                              channelId: "channel-55402e",
                              amount: orderTotalAmount,
                            },
                          ]);
                          setMethodsList([
                            `${t(
                              "cashChange_modal.label.cash"
                            )}:${numToCurrency(orderTotalAmount)}`,
                          ]);
                          closeFunc();

                          setFeedBackMsg("");
                          setIsError(false);
                        }
                      }}
                    >
                      {t("Common:button.confirm")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      onClick={() => {
                        closeFunc();

                        setIsError(false);
                        setFeedBackMsg("");
                      }}
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default CashChangeModal;
