import { useEffect, useMemo, useRef, useState } from "react";
import { ClientPaginatedTable2 } from "../../Tables";
import { getSalesByUser, getStaff } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import { DateTimeInput } from "../../UI/DateTimeInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import format from "date-fns/format";
import { OrderDetailsModal } from "./OrderDetailsModal";
import { Spinner } from "../../UI/Spinner";
import { numToCurrency } from "../../../utils/currencyHelper";
import useSchema from "../../../hooks/useSchema";
import { useTranslation } from "react-i18next";

const SalesTable = ({ closeFunc }) => {
  const { t } = useTranslation(["POS"]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const { userData, salesSummary } = useSelector(state => state.profile);
  const { allStaff, userSales, userSalesStatus } = useSelector(
    state => state.orders
  );
  const [show, setShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");

  const { userSalesSchema } = useSchema();

  const salesData = useMemo(() => {
    return userSales.pagedList;
  }, [userSales]);
  const dispatch = useDispatch();
  const summaryRef = useRef();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(userSalesSchema),
    defaultValues: {
      userId: "",
      todayDate: selectedDate
    },
    mode: "onChange"
  });

  const handlePrint = useReactToPrint({
    content: () => summaryRef.current
  });

  const SummaryReport = () => {
    return (
      <div
        id="summaryReport"
        className=" relative z-10  max-w-[300px] text-[8px] p-[10px]"
        ref={summaryRef}
      ></div>
    );
  };

  const onSubmit = data => {
    dispatch(getSalesByUser(data));
  };
  useEffect(() => {
    setValue("todayDate", selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    dispatch(getStaff(userData.cinemaId));
  }, [dispatch, userData]);

  const columns = useMemo(() => {
    return [
      {
        Header: t("sales.columns.e"),
        accessor: "id"
      },
      {
        Header: t("sales.columns.f"),
        accessor: "user"
      },
      {
        Header: t("sales.columns.d"),
        accessor: "amount",
        Cell: ({ value }) => numToCurrency(value, true)
      },
      {
        Header: t("sales.columns.g"),
        accessor: "dateCreated",
        Cell: ({ value }) => format(new Date(value), "HH:mm:ss")
      },
      // {
      //   Header: "Has Refunds",
      //   accessor: "hasRefunds",
      //   Cell: ({ value, row }) => {
      //     return value ? (
      //       <p className="text-center">{"Yes"}</p>
      //     ) : (
      //       <p className="text-center">{"No"}</p>
      //     );
      //   },
      // },
      {
        Header: t("sales.columns.h"),
        accessor: "paymentChannels",
        Cell: ({ value }) => {
          let channels = "";
          value?.map(channel => {
            return (channels =
              channels +
              `${channel.channel}: ${numToCurrency(channel.amount, true)}  `);
          });

          return channels;
        }
      }
    ];
  }, []);

  const onRowClick = row => () => {
    setSelectedOrder(row?.original);
    setShow(true);
  };
  return (
    <div className="bg-primary">
      <p className="text-center text-[#FEE27D] py-2 text-[20px]  ">
        {" "}
        {t("sales.title.user")}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" flex items-center justify-center py-3 w-[100%] px-[50px]">
          <select
            className="rounded-lg ml-[50px]  mr-3 h-[38px]"
            {...register("userId")}
          >
            <option value={""}>{t("sales.all.placeholder.user")}</option>
            {allStaff?.map(singleStaff => {
              return (
                <option value={singleStaff.id}>
                  {`${singleStaff.firstName} ${singleStaff.lastName}`}{" "}
                </option>
              );
            })}
          </select>

          <DatePicker
            placeholderText={t("sales.all.placeholder.date")}
            selected={new Date(selectedDate)}
            onChange={value => {
              setSelectedDate(value.toISOString());
            }}
            customInput={
              <DateTimeInput
                className="bg-primary w-[200px]"
                dateFormat={"EEE, MMM dd, yyyy"}
              />
            }
          />

          <button
            type="submit"
            className="  py-1 px-3 w-[250px] bg-[#FEE27D] text-primary rounded flex items-center justify-center "
          >
            <span>{t("sales.all.button.submit")}</span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-primary"
              status={userSalesStatus}
            />
          </button>
        </div>
      </form>
      <div>
        {errors?.userId?.message && (
          <p className="text-red-500 text-center">{errors?.userId?.message}</p>
        )}
      </div>
      <div className="h-[50%] bg-secondary pb-[10px] ">
        <ClientPaginatedTable2
          className="text-white"
          closeFunc={closeFunc}
          // toolbarChildren={

          // }
          onRowClick={onRowClick}
          printSummary={handlePrint}
          tableData={salesData}
          columns={columns}
          getHeaderProps={column => ({
            className: "px-2 text-base font-medium border border-[#00000033] ",
            ...(column.id === "item" ? column?.getSortByToggleProps() : {})
          })}
          getCellProps={cellInfo => ({
            style: {},
            className: "text-app-purple-9"
          })}
          getColumnProps={() => ({ style: {} })}
        />
      </div>
      <div className="hidden">
        <SummaryReport />
      </div>
      {show && (
        <OrderDetailsModal
          show={show}
          onClose={() => {
            setShow(false);
          }}
          selectedOrder={selectedOrder}
        />
      )}
    </div>
  );
};
export default SalesTable;
