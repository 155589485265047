import { useState, useEffect } from "react";
import { numToCurrency } from "../../../../utils/currencyHelper";
import Modal from "../../../UI/Modal";
import { Spinner } from "../../../UI/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  initiateFullRefund,
  getSalesByUser,
  setShowKeyboard,
  setKeyboardConfiguration,
  getOrderById,
} from "../../../../redux/slices";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../../context/KeyboardContext";
import { downloadMcf } from "../../../../utils/mcfHelper";

const RefundModal = ({ openStatus, closeFunc, orderInfo }) => {
  const { t } = useTranslation(["Admin", "Common"]);
  const dispatch = useDispatch();
  const [refundReason, setRefundReason] = useState("");
  const { userData } = useSelector((state) => state.profile);
  const {
    singleOrder,
    fullRefundStatus,
    fullRefundErr,
    customChannels,
  } = useSelector((state) => state.orders);
  const [showError, setShowError] = useState("false");
  const { setSetSharedInput } = useKeyboard();

  useEffect(() => {
    dispatch(getOrderById(orderInfo?.orderId));
  }, [orderInfo]);

  const refundOrder = () => {
    dispatch(
      initiateFullRefund({
        reason: refundReason,
        orderId: orderInfo.orderId,
      })
    )
      .unwrap()
      .then((data) => {
        downloadMcf({
          taxData: data,
          isRefund: true,
          order: {
            orderTotalAmount: singleOrder?.amount,
            orderItems: [
              ...singleOrder?.itemSales?.map((x) => {
                return {
                  id: x.itemId,
                  name: x.item,
                  price: x.price,
                  orderQuantity: x.quantity,
                };
              }),
              ...singleOrder?.ticketSales?.map((x) => {
                return {
                  id: x.ticketId,
                  name: x.film,
                  price: x.ticketPrice,
                  orderQuantity: x.quantity,
                  showtimeId: x.showtimeId,
                };
              }),
            ],
          },
          userData,
          paymentMode: singleOrder?.paymentChannels?.map((i) => {
            //the if check helps to format it for cases where it is a custom channel
            if (
              customChannels?.find((x) => {
                return x.id === i?.channelId;
              })
            ) {
              return {
                channelId: "channel-2d1980",
                otherChannelId: i?.channelId,
                amount: i?.amount,
                name: i?.name,
              };
            } else {
              return i;
            }
          }),
          cashAmount:
            singleOrder?.paymentChannels?.find((x) => {
              return x.channel === "Cash";
            })?.amount || 0,
          invoiceRefOrig: singleOrder?.serialNo,
        });

        dispatch(
          getSalesByUser({
            userId: userData.id,
            todayDate: new Date().toISOString(),
          })
        );
        closeFunc();
      })
      .catch((error) => {
        setShowError(true);
      });
  };
  return (
    <Modal openStatus={openStatus} panelStyles="bg-white p-[30px] w-[30vw] ">
      {showError === true && (
        <p className="text-red-500 text-center">{fullRefundErr}</p>
      )}
      <p className="text-center">
        {t("modals.order.refund.full.confirm", {
          id: orderInfo?.orderId,
          total: numToCurrency(orderInfo?.amount),
        })}
      </p>
      <textarea
        className="border-2 rounded w-full m-2 p-2"
        rows={5}
        placeholder={t("modals.order.refund.placeholder")}
        value={refundReason}
        onClick={() => {
          dispatch(setShowKeyboard(true));
          dispatch(
            setKeyboardConfiguration({
              type: "alphaNumeric",
              sharedInput: refundReason,
              placeHolder: t("Common:keyboard.placeholder.refund.reason"),
              // setSharedInput: x => {
              //   setRefundReason(x);
              // }
            })
          );
          setSetSharedInput(() => setRefundReason);
        }}
      ></textarea>
      <div className="flex justify-around my-2">
        <button
          className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
          onClick={refundOrder}
        >
          <span> {t("Common:button.confirm")} </span>
          <Spinner
            className="!w-3 !h-3 mx-1 text-white fill-blue-800"
            status={fullRefundStatus}
          />
        </button>
        <button
          className="inline-flex justify-center items-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
          onClick={() => {
            closeFunc();
            setRefundReason("");
            setShowError(false);
          }}
        >
          <span> {t("Common:button.cancel")} </span>
        </button>
      </div>
    </Modal>
  );
};
export default RefundModal;
