import axios from "axios";
import { redirectFunc } from "../../utils/redirect";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUS, tokenKey } from "../../utils/constants";

export const checkLumiGiftcard = createAsyncThunk(
  "check_lumi_giftcard",
  async (giftcard, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}LumiLoyaltyGiftCards/Redeem?giftCardCode=${giftcard}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const data = response.data.data;

      return fulfillWithValue(data);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

export const getCustomerData = createAsyncThunk(
  "get_customer_data",
  async (
    { email, phoneNumber, amount },
    { fulfillWithValue, rejectWithValue }
  ) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }Members/GetLoyaltyDetails?phoneNumber=${phoneNumber}&amount=${amount}${
          email ? `&email=${email}` : ""
        }`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const customerData = response.data.data;

      return fulfillWithValue(customerData);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  lumiGiftcardError: "",
  lumiGiftcardStatus: STATUS.IDLE,
  lumiGiftcardData: {},
  lumiRewardError: "",
  lumiRewardStatus: STATUS.IDLE,
  lumiRewardData: {},
  customerDataError: "",
  customerDataStatus: STATUS.IDLE,
  customerData: {},
};

export const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkLumiGiftcard.pending, (state) => {
        state.lumiGiftcardError = "";
        state.lumiGiftcardStatus = STATUS.PENDING;
      })
      .addCase(checkLumiGiftcard.fulfilled, (state, action) => {
        state.lumiGiftcardError = "";
        state.lumiGiftcardStatus = STATUS.RESOLVED;
        state.lumiGiftcardData = action.payload;
      })
      .addCase(checkLumiGiftcard.rejected, (state, action) => {
        state.lumiGiftcardError = action.payload;
        state.lumiGiftcardStatus = STATUS.REJECTED;
      })
      .addCase(getCustomerData.pending, (state) => {
        state.customerDataError = "";
        state.customerDataStatus = STATUS.PENDING;
      })
      .addCase(getCustomerData.fulfilled, (state, action) => {
        state.customerDataError = "";
        state.customerDataStatus = STATUS.RESOLVED;
        state.customerData = action.payload;
      })
      .addCase(getCustomerData.rejected, (state, action) => {
        state.customerDataError = action.payload;
        state.customerDataStatus = STATUS.REJECTED;
      });
  },
});

export const loyaltyReducer = loyaltySlice.reducer;
