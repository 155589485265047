import Modal from "../../../UI/Modal";
import { useMemo, useState, useEffect } from "react";
import { claimsKey } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { numToCurrency } from "../../../../utils/currencyHelper";
import { ClientPaginatedTable2 } from "../../../Tables";
import { getSalesByUser, initiateFullRefund } from "../../../../redux/slices";
import format from "date-fns/format";
import { OrderDetailsModal } from "../../AllSalesByUser/OrderDetailsModal";
import { Spinner } from "../../../UI/Spinner";
import { STATUS } from "../../../../utils/constants";
import { Spinner2 } from "../../../UI/Spinner2";
import RefundModal from "./RefundModal";
import PartialRefundModal from "./PartialRefundModal";
import { useTranslation } from "react-i18next";
const OrderListModal = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["Admin"]);

  const { userData } = useSelector(state => state.profile);
  const { userSales, userSalesStatus } = useSelector(state => state.orders);
  const dispatch = useDispatch();
  const [selectedOrder, setSelectedOrder] = useState("");
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showPartialRefundModal, setShowPartialRefundModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const userClaims = JSON.parse(localStorage?.getItem(claimsKey));
  const fetchSales = () => {
    dispatch(
      getSalesByUser({
        userId: userData.id,
        todayDate: new Date().toISOString()
      })
    );
  };

  useEffect(() => {
    fetchSales();
  }, []);
  const salesData = useMemo(() => {
    return userSales.pagedList;
  }, [userSales]);

  const columns = useMemo(() => {
    return [
      {
        Header: t("modals.order.columns.a"),
        accessor: "id"
      },
      //   {
      //     Header: "Staff Name",
      //     accessor: "user",
      //   },
      {
        Header: t("modals.order.columns.b"),
        accessor: "amount",
        Cell: ({ value }) => numToCurrency(value, true)
      },
      {
        Header: t("modals.order.columns.c"),
        accessor: "dateCreated",
        Cell: ({ value }) => format(new Date(value), "HH:mm:ss")
      },

      {
        Header: t("modals.order.columns.d"),
        accessor: "paymentChannels",
        Cell: ({ value }) => {
          let channels = "";
          value?.map(channel => {
            return (channels =
              channels +
              `${channel.channel}: ${numToCurrency(channel.amount, true)}  `);
          });

          return channels;
        }
      },
      {
        Header: t("modals.order.columns.e"),

        Cell: ({ value, row }) => {
          return (
            <div className="flex justify-around py-2">
              <button
                className="rounded bg-primary py-1 px-2 text-yellow-500 mx-2"
                onClick={() => {
                  setSelectedOrder(row?.original);
                  setShowOrderDetails(true);
                }}
              >
                {" "}
                {t("modals.order.columns.actions.v")}
              </button>
              {!row.original.hasBeenRefunded && userClaims?.CanRefundOnPOS && (
                <>
                  <button
                    className="rounded bg-primary py-1 px-2 text-yellow-500 mx-2"
                    onClick={() => {
                      setShowRefundModal(true);
                      setOrderInfo({
                        orderId: row?.original?.id,
                        amount: row?.original?.amount
                      });
                    }}
                  >
                    {t("modals.order.columns.actions.f")}
                  </button>
                  <button
                    className="rounded bg-primary py-1 px-2 text-yellow-500 mx-2"
                    onClick={() => {
                      setShowPartialRefundModal(true);
                      setOrderInfo({
                        orderId: row?.original?.id,
                        amount: row?.original?.amount
                      });
                    }}
                  >
                    {t("modals.order.columns.actions.p")}
                  </button>
                </>
              )}
            </div>
          );
        }
      }
    ];
  }, []);
  return (
    <Modal openStatus={openStatus}>
      <div className="h-[50%] bg-secondary pb-[10px] ">
        <ClientPaginatedTable2
          className="text-white"
          closeFunc={closeFunc}
          // toolbarChildren={

          // }
          //   onRowClick={onRowClick}
          //   printSummary={handlePrint}
          tableData={salesData}
          columns={columns}
          getHeaderProps={column => ({
            className: "px-2 text-base font-medium border border-[#00000033] ",
            ...(column.id === "item" ? column?.getSortByToggleProps() : {})
          })}
          getCellProps={cellInfo => ({
            style: {},
            className: "text-app-purple-9"
          })}
          getColumnProps={() => ({ style: {} })}
        />
      </div>

      {userSalesStatus === STATUS.PENDING && (
        <div className="flex justify-center p-5 items-center">
          <Spinner
            className="!w-10 !h-10 mx-1 text-white fill-primary"
            status={userSalesStatus}
          />
        </div>
      )}
      {showOrderDetails && (
        <OrderDetailsModal
          show={showOrderDetails}
          onClose={() => {
            setShowOrderDetails(false);
          }}
          selectedOrder={selectedOrder}
        />
      )}
      {showRefundModal && (
        <RefundModal
          openStatus={showRefundModal}
          orderInfo={orderInfo}
          closeFunc={() => {
            setShowRefundModal(false);
            setOrderInfo({});
          }}
        />
      )}
      {showPartialRefundModal && (
        <PartialRefundModal
          openStatus={showPartialRefundModal}
          orderInfo={orderInfo}
          closeFunc={() => {
            setShowPartialRefundModal(false);
            setOrderInfo({});
          }}
        />
      )}
    </Modal>
  );
};
export default OrderListModal;
