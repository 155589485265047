import { timeString } from "../../../../utils/constants";
import { numToCurrency } from "../../../../utils/currencyHelper";
import { useSelector, useDispatch } from "react-redux";
import { setOfflineRestriction } from "../../../../redux/slices";
import { useTranslation } from "react-i18next";
const FilmShowData = ({
  id,
  NewTicketData,
  addTicketToOrder,
  setTicketDataForAllocation
}) => {
  const { t } = useTranslation(["Home"]);
  const { cinemaSettings, offlineMode } = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const showtime = NewTicketData?.find(x => {
    return x.id === id;
  });
  return (
    <div className="p-2 text-white">
      {showtime && (
        <div>
          <p className="text-center font-bold"> {showtime.film}</p>
          <p className="text-center">
            {" "}
            {` ${showtime?.screen} ${timeString(
              showtime?.startTime
            )}- ${timeString(showtime?.endTime)}`}
          </p>
          <p className="text-center">
            <span> {t("ticket.film.details.seats")} </span>
            <span className="font-bold">
              {showtime?.totalSeats - showtime?.seatsSold}
            </span>
            <span> {t("ticket.film.details.screens")} </span>
            <span className="font-bold">{showtime?.totalSeats}</span>
          </p>
          <div className="grid grid-cols-3 gap-4 p-3">
            {showtime?.priceCard?.tickets?.map((ticket, index) => {
              return (
                <div
                  className="rounded border-2 p-2 bg-white text-black"
                  key={index}
                  onClick={() => {
                    if (cinemaSettings?.seatAllocation && ticket?.seatClassId) {
                      // if (!offlineMode) {
                        setTicketDataForAllocation({
                          pricecard: ticket,
                          showtime
                        });
                        return;
                      // } else {
                      //   dispatch(setOfflineRestriction(true));
                      // }
                    } else {
                      addTicketToOrder({ pricecard: ticket, item: showtime });
                    }
                  }}
                >
                  <p className="text-[10px] text-center">{ticket?.shortName}</p>
                  <p className="text-[10px] text-center">{` ${numToCurrency(
                    ticket?.price
                  )}`}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!showtime && (
        <p className="text-center">{t("ticket.film.details.empty")}</p>
      )}
    </div>
  );
};
export { FilmShowData };
