import { CancelBtn } from "../../../UI/FormElements";
import Modal from "../../../UI/Modal";

const SwapErrorModal = ({ closeFunc, errMsg }) => {
  return (
    <Modal
      openStatus={!!errMsg}
      closeFunc={closeFunc}
      panelStyles="bg-white p-[30px] min-h-[150px] min-w-[400px]"
    >
      {errMsg && <p className="text-center text-red-500">{errMsg}</p>}
      <div className="flex justify-center my-2">
        <CancelBtn btnFunc={closeFunc} btnText={"Close"} />
      </div>
    </Modal>
  );
};
export default SwapErrorModal;
