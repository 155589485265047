import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  decreaseOrder,
  addToOrder,
  holdOrder,
  setOfflineRestriction,
  removeFromOrder,
  createAdminAction,
} from "../../redux/slices";
import Trash from "../../assets/images/trash.png";
import { Element, Link } from "react-scroll";
import UpSign from "../../assets/images/up.png";
import DownSign from "../../assets/images/down.png";
import Hand from "../../assets/images/hand.png";

import DeleteModal from "./deleteModal";
import OrderItemQuantityModal from "./orderItemQuantityModal";
import OrderErrorModal from "./orderErrorModal";
import HoldOrderModal from "./HoldOrderModal";
import HeldOrdersModal from "./HeldOrdersModal";
import { numToCurrency } from "../../utils/currencyHelper";
import {useTranslation} from "react-i18next";

const OrderList = () => {
  const { t } = useTranslation(["OrderList"]);
  const [counter, setCounter] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showHoldOrderModal, setShowHoldOrderModal] = useState(false);
  const [showHeldOrdersModal, setShowHeldOrdersModal] = useState(false);
  const { offlineMode, userData } = useSelector((state) => state.profile);
  const { orderItems, orderHeld, orderError, heldOrders } = useSelector(
    (state) => state.orders
  );
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState("");
  const dispatch = useDispatch();

  const nextAction = () => {
    if (`order${counter}` !== `order${orderItems.length - 1}`) {
      setCounter(counter + 1);
    }
  };
  const prevAction = () => {
    if (`order${counter}` !== "order0") {
      setCounter(counter - 1);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const modifyQuantity = (item) => {
    if (item.canModify || item.packageItemData || item.packageTicketData) {
      return () => {
        setSelectedItemData(item);

        setShowQuantityModal(true);
      };
    } else {
      return () => {};
    }
  };

  return (
    <div className="h-[80%]">
      <div className=" h-[85%] border-2 overflow-auto" id="containerParent">
        {orderItems?.filter(item => !item?.hideItem).map((item, index) => {
          return (
            <Element name={`order${index}`} key={index}>
              <div
                className={`px-1 py-1 border-2 flex w-[100%] items-center ${
                  `order${index}` === `order${counter}`
                    ? "bg-secondary text-white"
                    : ""
                } `}
              >
                <div
                  className="w-[39%] h-auto overflow-auto pl-1 truncate"
                  onClick={modifyQuantity(item)}
                >
                  {item.name || item.title}
                </div>
                {item.canModify ? (
                  <div className="w-[37%] flex h-[40px]">
                    <button
                      className="text-primary text-center bg-white font-extrabold rounded shadow-md w-[38%] flex items-center justify-center"
                      onClick={() => {
                        dispatch(decreaseOrder(item));
                      }}
                    >
                      -
                    </button>
                    <div className="flex items-center justify-center  w-[24%] ">
                      {item.orderQuantity}
                    </div>
                    <button
                      className="text-primary font-extrabold bg-white  rounded shadow-md w-[38%] text-center flex items-center justify-center "
                      onClick={() => {
                        dispatch(addToOrder(item));
                      }}
                    >
                      +
                    </button>{" "}
                  </div>
                ) : (
                  <div className="w-[37%] flex h-[40px]">
                    {" "}
                    <p className="flex items-center justify-center w-[100%] ">
                      {item.orderQuantity}
                    </p>
                  </div>
                )}
                <p className="w-[24%] text-right p-1">{`${numToCurrency(
                  item.price * item.orderQuantity
                )}  `}</p>
              </div>
            </Element>
          );
        })}
      </div>
      <div className=" h-[15%] border-2 flex justify-between py-2">
        {" "}
        <Link
          to={`order${counter}`}
          smooth={true}
          duration={500}
          offset={50}
          containerId={"containerParent"}
          className="border-r border-primary w-[19%] bg-no-repeat bg-[50%] bg-center"
          onClick={() => {
            nextAction();
          }}
          style={{
            backgroundImage: `url(${DownSign})`,
          }}
        >
          <button
            type="button"
            // onClick={() => {
            //   nextAction();
            //   console.log(`order+${counter}`);
            // }}
          ></button>
        </Link>{" "}
        <Link
          to={`order${counter}`}
          smooth={true}
          duration={500}
          offset={-50}
          containerId={"containerParent"}
          className="border-r border-primary  w-[19%] bg-no-repeat bg-[50%] bg-center"
          onClick={() => {
            prevAction();
          }}
          style={{
            backgroundImage: `url(${UpSign})`,
          }}
        >
          <button
            type="button"
            // onClick={() => {
            //   prevAction();
            // }}
          ></button>
        </Link>
        <button
          className={`border-r border-primary  w-[19%] bg-no-repeat bg-[50%] bg-center`}
          style={{
            backgroundImage: `url(${Hand})`,
          }}
          onClick={() => {
            if (orderItems.length > 0) {
              setShowHoldOrderModal(true);
            }
          }}
        ></button>
        <button
          className={`border-r border-primary   w-[19%] bg-no-repeat bg-[50%] bg-center font-extrabold text-[12px] ${
            heldOrders?.length > 0 ? "bg-secondary text-white" : "text-primary"
          }`}
          onClick={() => {
            setShowHeldOrdersModal(true);
          }}
        >
          {" "}
          {t("hold.title")}
        </button>
        <button
          className=" w-[19%] bg-no-repeat bg-[50%] bg-center"
          style={{
            backgroundImage: `url(${Trash})`,
          }}
          onClick={() => {
            if (userData.roleName === "POSUser") {
              offlineMode
                ? dispatch(setOfflineRestriction(true))
                : setIsOpen(true);
            } else {
              if (!offlineMode) {
                dispatch(
                  createAdminAction({
                    doneById: userData.userNumber,
                    details: null,
                    action: 1,
                  })
                );
                dispatch(removeFromOrder(orderItems[counter]));
              } else {
                dispatch(setOfflineRestriction(true));
              }
            }
          }}
        ></button>
      </div>
      {orderItems.length ? (
        <DeleteModal
          openStatus={isOpen}
          closeFunc={closeModal}
          counter={counter}
        />
      ) : (
        ""
      )}
      <OrderItemQuantityModal
        openStatus={showQuantityModal}
        closeFunc={() => {
          setShowQuantityModal(false);
        }}
        itemData={selectedItemData}
      />
      <HoldOrderModal
        openStatus={showHoldOrderModal}
        closeFunc={() => {
          setShowHoldOrderModal(false);
        }}
      />
      {showHeldOrdersModal && (
        <HeldOrdersModal
          openStatus={showHeldOrdersModal}
          closeFunc={() => {
            setShowHeldOrdersModal(false);
          }}
        />
      )}
      {orderError && <OrderErrorModal />}
    </div>
  );
};
export default OrderList;
