import { useState } from "react";
import { FilmsList } from "./FilmsList";
import { FilmShowData } from "./FilmShowData";
import { useTranslation } from "react-i18next";
const ListByFilms = ({
  NewTicketData,
  start,
  end,
  addTicketToOrder,
  setTicketDataForAllocation
}) => {
  const { t } = useTranslation(["Home"]);
  const [module, setModule] = useState("filmsList");
  const [showtimeId, setShowtimeId] = useState("");
  return (
    <div className=" h-[100%]  p-2  w-[93%] ">
      <div className="flex justify-center p-2 ">
        <div className="flex border-2 rounded h-[40px]">
          <div
            onClick={() => {
              setModule("filmsList");
            }}
            className={`p-2 px-3  text-center ${
              module === "filmsList"
                ? " bg-primary text-white"
                : "bg-white text-black"
            }`}
          >
            {t("ticket.film.tabs.a")}
          </div>
          <div
            onClick={() => {
              setModule("filmShowData");
            }}
            className={`p-2 px-3   text-center ${
              module === "filmShowData"
                ? "bg-primary text-white"
                : " bg-white text-black"
            }`}
          >
            {t("ticket.film.tabs.b")}
          </div>
        </div>
      </div>
      {module === "filmsList" && (
        <FilmsList
          NewTicketData={NewTicketData}
          start={start}
          end={end}
          showTickets={() => {
            setModule("filmShowData");
          }}
          setShowtimeId={setShowtimeId}
        />
      )}
      {module === "filmShowData" && (
        <FilmShowData
          NewTicketData={NewTicketData}
          id={showtimeId}
          showFilmsList={() => {
            setModule("filmsList");
          }}
          addTicketToOrder={addTicketToOrder}
          setTicketDataForAllocation={setTicketDataForAllocation}
        />
      )}
    </div>
  );
};
export { ListByFilms };
