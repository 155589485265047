import Drink from "../assets/images/Drink.png";
import Popcorn from "../assets/images/popcorn.png";
import Hotdog from "../assets/images/Hotdog.png";
import Chocolate from "../assets/images/Chocolate.png";
import Icecream from "../assets/images/icecream.png";
import Combo from "../assets/images/combo.png";
import Spiderman from "../assets/images/spiderman.png";

export const ConcessionData = [
  {
    img: Drink,
    title: "Coke Parent",
    id: "con1",
    subItems: [
      { img: Drink, title: "coke1", price: 350, id: "coke1", canModify: true },
      { img: Drink, title: "coke2", price: 350, id: "coke2", canModify: true },
      { img: Drink, title: "coke3", price: 350, id: "coke3", canModify: true },
      { img: Drink, title: "coke4", price: 350, id: "coke4", canModify: true },
      { img: Drink, title: "coke5", price: 350, id: "coke5", canModify: true },
      { img: Drink, title: "coke6", price: 350, id: "coke6", canModify: true },
    ],
  },
  { img: Drink, title: "Fanta", price: 350, id: "con2" },
  { img: Drink, title: "Sprite", price: 250, id: "con3" },
  { img: Drink, title: "Malt", price: 450, id: "con4" },
  { img: Popcorn, title: "Small Popcorn", price: 500, id: "con5" },
  {
    img: Popcorn,
    title: "Medium Popcorn",
    price: 1000,
    id: "con6",
  },
  { img: Popcorn, title: "Large Popcorn", price: 500, id: "con7" },
  { img: Hotdog, title: "Hotdog 1", price: 500, id: "con8" },
  { img: Hotdog, title: "Hotdog 2", price: 600, id: "con9" },
  { img: Hotdog, title: "Hotdog 3", price: 700, id: "con10" },
  { img: Hotdog, title: "Hotdog 4", price: 800, id: "con11" },
  { img: Chocolate, title: "Chocolate 1", price: 550, id: "con12" },
  { img: Chocolate, title: "Chocolate 2", price: 680, id: "con13" },
  { img: Chocolate, title: "Chocolate 3", price: 900, id: "con14" },
  { img: Icecream, title: "Icecream 1", price: 700, id: "con15" },
  { img: Icecream, title: "Icecream 2", price: 800, id: "con16" },
  { img: Icecream, title: "Icecream 3", price: 900, id: "con17" },
  { img: Combo, title: "Combo 1", price: 3000, id: "con18" },
  { img: Combo, title: "Combo 2", price: 5000, id: "con19" },
  { img: Combo, title: "Combo 3", price: 7000, id: "con20" },

  { img: Drink, title: "Coke", price: 300, id: "con1a" },
  { img: Drink, title: "Fanta", price: 350, id: "con2a" },
  { img: Drink, title: "Sprite", price: 250, id: "con3a" },
  { img: Drink, title: "Malt", price: 450, id: "con4a" },
  { img: Popcorn, title: "Small Popcorn", price: 500, id: "con5a" },
  {
    img: Popcorn,
    title: "Medium Popcorn",
    price: 1000,
    id: "con6a",
  },
  { img: Popcorn, title: "Large Popcorn", price: 500, id: "con7a" },
  { img: Hotdog, title: "Hotdog 1", price: 500, id: "con8a" },
  { img: Hotdog, title: "Hotdog 2", price: 600, id: "con9a" },
  { img: Hotdog, title: "Hotdog 3", price: 700, id: "con10a" },
  { img: Hotdog, title: "Hotdog 4", price: 800, id: "con11a" },
  { img: Chocolate, title: "Chocolate 1", price: 550, id: "con12a" },
  { img: Chocolate, title: "Chocolate 2", price: 680, id: "con13a" },
  { img: Chocolate, title: "Chocolate 3", price: 900, id: "con14a" },
  { img: Icecream, title: "Icecream 1", price: 700, id: "con15a" },
  { img: Icecream, title: "Icecream 2", price: 800, id: "con16a" },
  { img: Icecream, title: "Icecream 3", price: 900, id: "con17a" },
  { img: Combo, title: "Combo 1", price: 3000, id: "con18a" },
  { img: Combo, title: "Combo 2", price: 5000, id: "con19a" },
  { img: Combo, title: "Combo 3", price: 7000, id: "con20a" },
];

export const ticketData = [
  {
    img: Spiderman,
    title: "Spiderman far from home",
    type: "3d",
    time: "12:30pm - 2:30pm",
    price: "4000",
    id: "mov1",
  },
  {
    img: Spiderman,
    title: "Spiderman homecoming ",
    type: "4d",
    time: "1:30pm - 3:30pm",
    price: "5000",
    id: "mov2",
  },
  {
    img: Spiderman,
    title: "Spiderman 1",
    type: "3d",
    time: "4:30pm - 6:30pm",
    price: "7000",
    id: "mov3",
  },
  {
    img: Spiderman,
    title: "Spiderman into the spiderverse",
    type: "5d",
    time: "12:30pm - 2:30pm",
    price: "3500",
    id: "mov4",
  },
  {
    img: Spiderman,
    title: "Spiderman 2",
    type: "3d",
    time: "12:30pm - 2:30pm",
    price: "2800",
    id: "mov5",
  },
  {
    img: Spiderman,
    title: "The amazing Spiderman",
    type: "2d",
    time: "12:30pm - 2:30pm",
    price: "4300",
    id: "mov6",
  },

  {
    img: Spiderman,
    title: "Spiderman far from home",
    type: "3x",
    time: "12:30pm - 2:30pm",
    price: "4000",
    id: "mov1x",
  },
  {
    img: Spiderman,
    title: "Spiderman homecoming ",
    type: "4x",
    time: "1:30pm - 3:30pm",
    price: "5000",
    id: "mov2x",
  },
  {
    img: Spiderman,
    title: "Spiderman 1",
    type: "3d",
    time: "4:30pm - 6:30pm",
    price: "7000",
    id: "mov3x",
  },
  {
    img: Spiderman,
    title: "Spiderman into the spiderverse",
    type: "5d",
    time: "12:30pm - 2:30pm",
    price: "3500",
    id: "mov4x",
  },
  {
    img: Spiderman,
    title: "Spiderman 2",
    type: "3d",
    time: "12:30pm - 2:30pm",
    price: "2800",
    id: "mov5x",
  },
  {
    img: Spiderman,
    title: "The amazing Spiderman",
    type: "2d",
    time: "12:30pm - 2:30pm",
    price: "4300",
    id: "mov6x",
  },
  {
    img: Spiderman,
    title: "The amazing Spiderman",
    type: "2d",
    time: "12:30pm - 2:30pm",
    price: "4300",
    id: "mov7x",
  },
];

export const ticketSales = [
  { item: "Spiderman 1", quantity: 5, cost: 5000 },
  { item: "Spiderman 2", quantity: 4, cost: 2500 },
  { item: "Spiderman 3", quantity: 3, cost: 3000 },
  { item: "Spiderman homecoming", quantity: 1, cost: 4000 },
  { item: "Spiderman 1", quantity: 5, cost: 5000 },
  { item: "Spiderman 2", quantity: 4, cost: 2500 },
  { item: "Spiderman 3", quantity: 3, cost: 3000 },
  { item: "Spiderman homecoming", quantity: 1, cost: 4000 },
];

export const concessionSales = [
  { item: "Popcorn", quantity: 5, cost: 1000 },
  { item: "Drink", quantity: 2, cost: 300 },
  { item: "Hotdog", quantity: 15, cost: 500 },
  { item: "Chocolate", quantity: 6, cost: 250 },
  { item: "Popcorn", quantity: 5, cost: 1000 },
  { item: "Drink", quantity: 2, cost: 300 },
  { item: "Hotdog", quantity: 15, cost: 500 },
  { item: "Chocolate", quantity: 6, cost: 250 },
];

export const packageTicketData = {
  drinks: [
    { img: Drink, title: "Coke", price: 0, id: "ptd1" },
    { img: Drink, title: "Fanta", price: 0, id: "ptd2" },
    { img: Drink, title: "Sprite", price: 0, id: "ptd3" },
    { img: Drink, title: "Chapman", price: 0, id: "ptd4" },
    { img: Drink, title: "Malt", price: 0, id: "ptd5" },
  ],
  popcorn: [
    { img: Popcorn, title: "Sugar Popcorn", price: 0, id: "ptp1" },
    { img: Popcorn, title: "Salt Popcorn", price: 0, id: "ptp2" },
    { img: Popcorn, title: "Mixed Popcorn", price: 0, id: "ptp3" },
  ],
};

export const sampleCustomLayout = [
  {
    serviceArea: 0,
    pages: [
      {
        pageName: "Drinks",
        pageItem: [
          { itemId: "b67d0984-1015-4203-8c73-c608ae94773c" },
          { itemId: "e4b99865-62c8-4244-a5b8-0ec358c54e80" },
          { itemId: "60ad3fd5-a0c8-4c5c-8539-e55f329c63b4" },
        ],
      },
      {
        pageName: "Snacks",
        pageItem: [
          { itemId: "c29a7895-a8ef-470c-8b87-ec228027c2b9" },
          { itemId: "61cedc3e-a709-4060-bddb-a650c44a6f55" },
          { itemId: "d4ce7c9a-6dea-47b5-ac63-8879157420f1" },
        ],
      },
      {
        pageName: "Popcorn",
        pageItem: [
          { itemId: "19165a9c-fdb9-4b16-91a5-2584d1ed33e0" },
          { itemId: "60ad3fd5-a0c8-4c5c-8539-e55f329c63b4" },
          { itemId: "c0271509-7459-48e1-9e93-6be8e755dcc2" },
        ],
      },
    ],
  },
  {
    serviceArea: 1,
    pages: [
      {
        pageName: "Bestsellers",
        pageItem: [
          { itemId: "dbbd5aa5-0690-48b6-889b-ad62cdfd100b" },
          { itemId: "c15caba3-fe0b-4abb-b659-69ceef6e4501" },
          { itemId: "6b50ef06-36ba-41b7-a766-b6220b7aeb1f" },
        ],
      },
      {
        pageName: "Cocktails",
        pageItem: [
          { itemId: "8dd48408-df14-450c-a6c4-4ae14722bf7f" },
          { itemId: "1da38c29-600a-4b8c-b4ce-1c0c4c64058d" },
          { itemId: "f21cc640-1413-4a33-94ce-b3977cfd0437" },
        ],
      },
      {
        pageName: "Mocktails",
        pageItem: [
          { itemId: "c29a7895-a8ef-470c-8b87-ec228027c2b9" },
          { itemId: "61cedc3e-a709-4060-bddb-a650c44a6f55" },
          { itemId: "e4b99865-62c8-4244-a5b8-0ec358c54e80" },
        ],
      },
    ],
  },
];

export const sampleInScreenOrder = [
  {
    name: "Bauer Rich",
    screen: "Screen 1",
    seat: "A55",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 1",
    seat: "c35",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:43:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Taye Taiwo",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 1",
    seat: "d20",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T04:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ojo Dele",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 1",
    seat: "A55",
    status: "Completed",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:12:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 1",
    seat: "f13",
    status: "Completed",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 1",
    seat: "A55",
    status: "Completed",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 2",
    seat: "A55",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 2",
    seat: "A55",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 2",
    seat: "A55",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 2",
    seat: "A55",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 2",
    seat: "A55",
    status: "Completed",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
  {
    name: "Bauer Rich",
    screen: "Screen 3",
    seat: "A55",
    status: "Pending",
    dateCreated: "2023-08-11T03:08:58.655Z",
    dateCompleted: "2023-08-11T03:30:25.407Z",
    phoneNumber: "08145470233",
    completedBy: "Ciroma Adekunle",
  },
];
