import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { STATUS } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const FetchErrorModal = () => {
  const { t } = useTranslation(["POS", "Common"]);

  const [errorMessage, setErrorMessage] = useState(false);
  const { status: itemsStatus } = useSelector(state => state.items);
  const { status: showTimesStatus } = useSelector(state => state.showtimes);

  useEffect(() => {
    if (
      itemsStatus === STATUS.REJECTED &&
      showTimesStatus !== STATUS.REJECTED
    ) {
      setErrorMessage(t("errors.items"));
    }
    if (
      itemsStatus !== STATUS.REJECTED &&
      showTimesStatus === STATUS.REJECTED
    ) {
      setErrorMessage(t("errors.showtimes"));
    }
    if (
      itemsStatus === STATUS.REJECTED &&
      showTimesStatus === STATUS.REJECTED
    ) {
      setErrorMessage(t("errors.both"));
    }
  }, [itemsStatus, showTimesStatus]);

  return (
    <Transition appear show={!!errorMessage} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg text-red-500 font-medium leading-6 text-center"
                >
                  {t("Common:error.title")}
                </Dialog.Title>

                <p className="text-center py-4">{errorMessage}</p>
                <div className="flex justify-center mt-3">
                  <button
                    type="button"
                    className="inline-flex mr-2 border justify-center rounded-md border-blue-900 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      setErrorMessage(false);
                    }}
                  >
                    {t("Common:button.close")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default FetchErrorModal;
