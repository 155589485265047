import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  createAdminAction,
  addToOrder,
  setPrintLastOrder,
  setShowKeyboard,
  setKeyboardConfiguration
} from "../../redux/slices";
import NumPad from "react-numpad";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../utils/schemas";
import { authenticateAdmin2 } from "../../redux/slices";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../context/KeyboardContext";

const AuthReprintModal = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["Common", "POS"]);
  const dispatch = useDispatch();
  const { adminAuthError2 } = useSelector(state => state.items);
  const [userNumber, setUserNumber] = useState("");
  const [pin, setPin] = useState("");
  const { setSetSharedInput } = useKeyboard()
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      userNumber: "",
      pin: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    setValue("userNumber", userNumber);
    setValue("pin", pin);
  }, [userNumber, pin]);

  const bringOldOrders = () => {
    let lastSale = JSON.parse(localStorage.getItem("lastOrder")) || [];
    if (lastSale.length > 0) {
      lastSale.forEach(singleOrder => {
        dispatch(addToOrder(singleOrder));
      });
      dispatch(setPrintLastOrder(true));
    }
  };

  const onSubmit = data => {
    dispatch(authenticateAdmin2(data))
      .unwrap()
      .then(() => {
        dispatch(
          createAdminAction({
            doneById: data.userNumber,
            details: null,
            action: 9
          })
        );
        bringOldOrders();
        setValue("userNumber", "");
        setValue("pin", "");
        setPin("");
        setUserNumber("");
        closeFunc();
      })
      .catch(err => console.log(err));
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {t("POS:reprint.title")}
                </Dialog.Title>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {adminAuthError2 && (
                    <p className="text-red-500 text-center">
                      {" "}
                      {adminAuthError2}
                    </p>
                  )}
                  <div className="flex justify-center">
                    <div className="mt-2 deleteModal">
                      <label>{t("admin_form.label.user")}</label>
                      <br />
                      <input
                        type="text"
                        autoComplete="off"
                        value={userNumber}
                        placeholder={t("admin_form.placeholder.user")}
                        className=" w-[100%]  h-[35px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "numeric",
                              sharedInput: userNumber,
                              placeHolder: t("keyboard.placeholder.user"),
                              // setSharedInput: x => {
                              //   setUserNumber(x);
                              // }
                            })
                          );
                          setSetSharedInput(() => setUserNumber)
                        }}
                      />
                      {errors?.userNumber?.message && (
                        <p className="text-red-500 text-center">
                          {errors?.userNumber?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="mt-2 deleteModal">
                      <label> {t("admin_form.label.pin")}</label> <br />
                      <input
                        type="password"
                        value={pin}
                        autoComplete="new-password"
                        placeholder={t("admin_form.placeholder.pin")}
                        className=" w-[100%] h-[35px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "numericPin",
                              sharedInput: pin,
                              placeHolder: t("keyboard.placeholder.pin"),
                              // setSharedInput: x => {
                              //   setPin(x);
                              // }
                            })
                          );
                          setSetSharedInput(() => setPin)
                        }}
                      />
                      {errors?.pin?.message && (
                        <p className="text-red-500 text-center">
                          {errors.pin.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="submit"
                      className=" mr-3 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("POS:reprint.submit")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setValue("userNumber", "");
                        setValue("pin", "");
                        setPin("");
                        setUserNumber("");
                        closeFunc();
                      }}
                    >
                      {t("button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default AuthReprintModal;
