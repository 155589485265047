import { useState } from "react";
import { CancelBtn, InputField, SubmitBtn } from "../../../UI/FormElements";
import Modal from "../../../UI/Modal";
import TicketList from "./TicketListModal";
import {
  getOrderById,
  authenticateAdmin2,
  createAdminAction,
} from "../../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../../../utils/constants";

const SwapTicket = ({ openStatus, closeFunc }) => {
  const [orderId, setOrderId] = useState("");
  // const [bookingCode, setBookingCode] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [pin, setPin] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [showTicketList, setShowTicketList] = useState(false);

  const dispatch = useDispatch();
  const { singleOrder, singleOrderStatus } = useSelector(
    (state) => state.orders
  );
  const { adminAuthStatus2 } = useSelector((state) => state.items);

  const submitFunc = () => {
    if (!userNumber || !pin || !orderId) {
      setErrMsg("Ensure all the fields are filled");
      return;
    }
    dispatch(authenticateAdmin2({ userNumber, pin, action: 14 }))
      .unwrap()
      .then(() => {
        dispatch(
          createAdminAction({
            doneById: userNumber,
            details: `Attempted swap for order with order id ${orderId}`,
            action: 14,
          })
        );
        dispatch(getOrderById(orderId))
          .unwrap()
          .then(() => {
            setShowTicketList(true);
          })
          .catch((data) => {
            setErrMsg(data);
          });
      })
      .catch((data) => {
        setErrMsg(data);
      });
  };
  return (
    <Modal
      openStatus={openStatus}
      closeFunc={closeFunc}
      panelStyles="bg-white p-[30px] min-h-[250px] min-w-[400px]"
    >
      <p className="text-[20px] font-bold">Find Tickets by Order Id</p>

      {errMsg && <p className="text-center text-red-500 my-2">{errMsg}</p>}

      <InputField
        inputLabel={"User Number"}
        placeholder={"Enter User Number"}
        value={userNumber}
        keyboardType="numeric"
        setValue={setUserNumber}
      />
      <InputField
        inputLabel={"User Pin"}
        placeholder={"Enter pin"}
        value={pin}
        keyboardType="numericPin"
        setValue={setPin}
        inputType="password"
      />
      <InputField
        inputLabel={"Order Id"}
        placeholder={"Enter Order Id"}
        value={orderId}
        keyboardType="alphaNumeric"
        setValue={setOrderId}
      />
      {/* <InputField
        inputLabel={"Booking Code"}
        placeholder={"Enter Booking Code"}
        value={bookingCode}
        keyboardType="numeric"
        setValue={setBookingCode}
      /> */}
      <div className="flex justify-center my-3">
        <SubmitBtn
          btnFunc={submitFunc}
          status={
            singleOrderStatus === STATUS.PENDING ||
            adminAuthStatus2 === STATUS.PENDING
              ? STATUS.PENDING
              : ""
          }
        />
        <CancelBtn btnFunc={closeFunc} />
      </div>
      <TicketList
        openStatus={showTicketList}
        closeFunc={() => {
          setShowTicketList(false);
        }}
        orderData={singleOrder}
        closeParent={() => {
          closeFunc();
          setOrderId("");
        }}
      />
    </Modal>
  );
};

export default SwapTicket;
