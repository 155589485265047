import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SearchKeyBoard from "./SearchKeyBoard";
import { useSelector } from "react-redux";
import { setShowKeyboard } from "../../redux/slices";
import { useTranslation } from "react-i18next";

const SearchKeyboardModal = ({ setSearchResults, openStatus, closeFunc }) => {
  const { showKeyboard } = useSelector(state => state.profile);
  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-end p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[520px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="App flex items-center justify-center">
                  <SearchKeyBoard
                    setSearchResults={setSearchResults}
                    closeFunc={closeFunc}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default SearchKeyboardModal;
