import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useSelector, useDispatch } from "react-redux";
import { resetOrderError } from "../../redux/slices";
import {useTranslation} from "react-i18next";

const OrderErrorModal = () => {

  const { t } = useTranslation(["Common"]);

  const dispatch = useDispatch();
  const { orderError } = useSelector((state) => state.orders);

  return (
    <Transition appear show={!!orderError} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-red-500 text-center"
                >
                  {t("error.title")}
                </Dialog.Title>

                <p className="text-center py-4">{orderError}</p>
                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    onClick={() => {
                      dispatch(resetOrderError());
                    }}
                    className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                  >
                    {t("button.close")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default OrderErrorModal;
