import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  checkGiftcard,
  setKeyboardConfiguration,
  setShowKeyboard
} from "../../redux/slices";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner } from "../UI/Spinner";
import { numToCurrency } from "../../utils/currencyHelper";
import { useTranslation } from "react-i18next";
import useSchema from "../../hooks/useSchema";
import { useKeyboard } from "../../context/KeyboardContext";

const GiftcardModal = ({ openStatus, closeFunc, setGiftcardData }) => {
  const dispatch = useDispatch();
  const { error, status } = useSelector(state => state.giftcard);
  const [giftcardCode, setGiftcardCode] = useState("");
  const [amount, setAmount] = useState("");
  const [actionError, setActionError] = useState(true);
  const { t } = useTranslation(["OrderPayment","Common"]);
const { setSetSharedInput } = useKeyboard()
  const { giftcardSchema } = useSchema();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(giftcardSchema),
    defaultValues: {
      giftcardCode: "",
      amount: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    setValue("giftcardCode", giftcardCode);
    setValue("amount", amount);
  }, [giftcardCode, amount]);

  const onSubmit = formData => {
    dispatch(
      checkGiftcard({
        code: formData.giftcardCode
      })
    )
      .unwrap()
      .then(data => {
        if (data.balance < formData.amount) {
          setActionError(
            `
            ${t("OrderPayment:paymentchannel_modal.errors.giftcardErr", {
              balance: numToCurrency(data?.balance),
              amount: numToCurrency(+formData?.amount),
            })}
            `
          );
        } else {
          setGiftcardData({ amount: formData.amount, id: data.id });
          setValue("giftcardCode", "");
          setValue("amount", "");
          setGiftcardCode("");
          setAmount("");
          closeFunc();
        }
      })
      .catch(err => {
        console.log(error);
        setActionError(error);
      });
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Enter Admin Pin
                </Dialog.Title> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  {actionError && (
                    <p className="text-red-500 text-center"> {actionError}</p>
                  )}
                  <div className="mt-2 deleteModal flex justify-center">
                    <div>
                      <label className="text-center block">{t("OrderPayment:paymentchannel_modal.label.giftcard_cd")}</label>
                      <br />
                      <input
                        type="text"
                        value={giftcardCode}
                        placeholder="Enter Giftcard Code"
                        className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "alphaNumeric",
                              sharedInput: giftcardCode,
                              placeHolder: t("OrderPayment:paymentchannel_modal.placeholder.giftcard"),
                              // setSharedInput: (x) => {
                              //   setGiftcardCode(x);
                              // }
                            })
                          );
                          setSetSharedInput(() => setGiftcardCode)
                        }}
                      />
                      {errors?.giftcardCode?.message && (
                        <p className="text-red-500 text-center">
                          {errors?.giftcardCode?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-2 deleteModal flex justify-center">
                    <div>
                      <label className="text-center block">{t("OrderPayment:paymentchannel_modal.label.amount")}</label>
                      <br />
                      <input
                        type="text"
                        value={amount}
                        placeholder={t("Common:input.placeholder.amount")}
                        className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "numeric",
                              sharedInput: amount,
                              placeHolder: t("Common:keyboard.placeholder.amount"),
                              // setSharedInput: (x) => {
                              //   setAmount(x);
                              // }
                            })
                          );
                          setSetSharedInput(() => setAmount)
                        }}
                      />
                      {errors?.amount?.message && (
                        <p className="text-red-500 text-center">
                          {errors?.amount?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-5 flex justify-center">
                    <button
                      type="submit"
                      className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      <span>{t("Common:button.submit")} </span>
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                        status={status}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setValue("giftcardCode", "");
                        setGiftcardCode("");
                        setValue("amount", "");
                        setAmount("");
                        setActionError("");
                        closeFunc();
                      }}
                      className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default GiftcardModal;
