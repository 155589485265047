import { useState } from "react";
import SearchKeyboardModal from "../../../utils/KeyboardModal/SearchKeyboardModal";
import Concession from "../home/concession";
import { useTranslation } from "react-i18next";

const ItemSearch = () => {
  const { t } = useTranslation(["Search"]);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchKeyboard, setShowSearchKeyboard] = useState(true);
  return (
    <>
      <div className="flex justify-evenly h-[8%] items-center">
        <span className="text-primary font-bold text-[20px]">{t("title")}</span>
        <button
          className="bg-primary text-white px-3 py-1 rounded"
          onClick={() => {
            setShowSearchKeyboard(true);
          }}
        >
          {" "}
          {t("keyboard")}
        </button>
      </div>
      <div className="h-[92%]">
        <Concession itemSearchModule={true} searchResults={searchResults} />
      </div>
      <SearchKeyboardModal
        setSearchResults={setSearchResults}
       
        openStatus={showSearchKeyboard}
        closeFunc={() => {
          setShowSearchKeyboard(false);
        }}
      />
    </>
  );
};
export default ItemSearch;
