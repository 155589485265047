import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  clearHeldOrders,
  clearOrder,
  forgortPassword,
  getCinemaSettings,
  getItems,
  getItemsLayout,
  getShowtimes,
  setKeyboardConfiguration,
  setResetForgotPwd,
  setShowKeyboard,
  userLogin,
  getCustomChannels,
} from "../redux/slices";
import { loginSchema } from "../utils/schemas";
import { useLoggedIn } from "../hooks";
import { Spinner } from "../components/UI/Spinner";
import KeyboardModal from "../utils/KeyboardModal";
import { currencyKey } from "../utils/constants";
import { useTranslation } from "react-i18next";
import ForgotPwdModal from "../components/ForgotPwdModal";
import { useKeyboard } from "../context/KeyboardContext";
import { unwrapResult } from "@reduxjs/toolkit";
import { decryptId, encode, encryptId } from "../utils/miscHelper";

const Login = () => {
  const { i18n } = useTranslation();
  const [forgotPwd, setForgotPwd] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userNumber, setUserNumber] = useState("");
  const [pin, setPin] = useState("");
  const { error, status, forgotPwdError, forgotPwdStatus } = useSelector(
    (state) => state.profile
  );
  const [userError, setUserError] = useState(null);
  const { setSetSharedInput } = useKeyboard();
  const { checkAuth } = useLoggedIn();
  const valid = checkAuth();
  

  
  useEffect(() => {
    if (valid) {
      navigate("/");
    }
  }, [navigate, valid]);

  const {
    control,
    setValue,
    getValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      userNumber: "",
      pin: "",
    },
    mode: "onChange",
  });

  const handleForgotPwd = async () => {
    try {
      if (!userNumber) {
        throw new Error("Enter your user number");
      }
      const resultAction = await dispatch(forgortPassword(userNumber));
      const resp = unwrapResult(resultAction);
      if (resp) {
        dispatch(setResetForgotPwd());
        setUserError(null);
        setForgotPwd(true);
      } else {
        throw new Error("seems you have an invalid email on reach");
      }
    } catch (error) {
      setUserError(null);
      if (error instanceof Error) {
        dispatch(setResetForgotPwd());
        setUserError(error.message);
      }
    }
  };

  useEffect(() => {
    setValue("userNumber", userNumber);
    setValue("pin", pin);
  }, [userNumber, pin, setValue]);

  const onSubmit = (data) => {
    dispatch(userLogin(data))
      .unwrap()
      .then((loginResponse) => {
       
        if (loginResponse?.isDefaultPin) {
          
          const resetToken=encode(loginResponse?.resetToken)
         
          navigate(`/change-password?resetToken=${resetToken}`)
        } else {
          i18n.changeLanguage(loginResponse.language);
          dispatch(
            getCinemaSettings({
              cinemaId: loginResponse?.cinemaId,
              circuitId: loginResponse?.circuitId,
            })
          );
          localStorage.setItem(currencyKey, loginResponse?.currency);
          dispatch(getCustomChannels(loginResponse?.cinemaId));
          dispatch(getItems()).then(() => {
            dispatch(getShowtimes()).then(() => {
              dispatch(getItemsLayout());
              dispatch(clearOrder());
              dispatch(clearHeldOrders());
              navigate("/");
            });
          });
          
        }
      })
      .catch(() => {});
  };

  return (
    <div className="bg-primary h-[100vh] w-[100vw] p-4">
      <form
        autoComplete="off"
        className="loginForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex justify-center h-[20vh] rounded">
          <div
            className=" w-[20%] h-[100% ]  bg-no-repeat bg-contain bg-center "
            style={{
              backgroundImage: `url(${process.env.REACT_APP_LOGO_URL})`,
            }}
          ></div>
        </div>
        {error && <p className="text-red-500 text-center">{error}</p>}
        {forgotPwdError && (
          <p className="text-red-500 text-center">{forgotPwdError}</p>
        )}
        {userError && <p className="text-red-500 text-center">{userError}</p>}
        <p className="text-white text-[24px] pb-[20px] ">User Number</p>

        <input
          type="text"
          value={userNumber}
          autoComplete="off"
          placeholder="Enter User Number"
          className=" w-[100%] text-white h-[50px] bg-secondary rounded py-2 px-3"
          onClick={() => {
            dispatch(setShowKeyboard(true));
            dispatch(
              setKeyboardConfiguration({
                type: "numeric",
                sharedInput: userNumber,
                placeHolder: " Enter User Number",
                // setSharedInput: x => {

                //   setUserNumber(x);
                // }
              })
            );
            setSetSharedInput(() => setUserNumber);
          }}
        />
        {errors?.userNumber?.message && (
          <p className="text-red-500 text-center">
            {errors?.userNumber?.message}
          </p>
        )}
        <p className="text-white mt-[10vh] text-[24px] pb-[20px]">Pin</p>

        <input
          type="password"
          value={pin}
          autoComplete="new-password"
          placeholder="Enter Pin"
          className=" w-[100%] text-white h-[50px] bg-secondary rounded py-2 px-3"
          onClick={() => {
            dispatch(setShowKeyboard(true));
            dispatch(
              setKeyboardConfiguration({
                type: "numericPin",
                sharedInput: pin,
                placeHolder: " Enter Pin",
                // setSharedInput: x => {
                //   setPin(x);
                // }
              })
            );
            setSetSharedInput(() => setPin);
          }}
        />
        {errors?.pin?.message && (
          <p className="text-red-500 text-center">{errors?.pin?.message}</p>
        )}

        <button
          type="button"
          onClick={() => handleForgotPwd()}
          className="text-white flex items-center pt-[20px]  "
        >
          <span>Forgot Password? &nbsp;</span>
          <span className="text-[#FEE27D]">Request password change</span>
          <Spinner
            className="!w-3 !h-3 mx-1 text-white fill-pink-400"
            status={forgotPwdStatus}
          />
        </button>
        <div className="flex justify-center mt-[20px]">
          <button
            className="bg-app-secondary2 text-white p-2 rounded flex items-center"
            type="submit"
          >
            <span>Login</span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-pink-400"
              status={status}
            />
          </button>
        </div>
      </form>
      <KeyboardModal />
      <ForgotPwdModal openStatus={forgotPwd} setForgotPwd={setForgotPwd} />
    </div>
  );
};
export default Login;
