import { useState } from "react";
import Modal from "../../UI/Modal";
import { Spinner } from "../../UI/Spinner";
import {
  setKeyboardConfiguration,
  setShowKeyboard
} from "../../../redux/slices";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../context/KeyboardContext";

const QtyModalForAllocation = ({
  openStatus,
  closeFunc,
  ticketData,
  setAllocationData,
  addTicketToOrder
}) => {
  const { t } = useTranslation(["Home", "Common"]);
  const [qty, setQty] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const { setSetSharedInput } = useKeyboard()

  const onSubmit = e => {
    e.preventDefault();
    const seatsLeft =
      ticketData?.showtime?.totalSeats - ticketData?.showtime?.seatsSold;
    if (!qty) {
      setErrorMsg(t("ticket.quantity.error.required"));
      return;
    }

    const ticket = ticketData?.pricecard;

    if (qty && qty > seatsLeft) {
      setErrorMsg(
        t("ticket.quantity.error.amount", { quantity: qty, count: seatsLeft })
      );
      return;
    }

    //if it's a standard ticket call seat allocation modal so seats can be picked 1st
    // if (ticket.ticketGroup === "Standard" && ticket.price > 0) {
    if (ticket.ticketGroup === "Standard") {
      closeFunc();
      setAllocationData({ ...ticketData, qtyOfSeats: +qty });
      return;
    }

    //If its a package ticket where there is just one ticket
    if (
      ticket.ticketGroup === "Package" &&
      ticket.ticketPackage.tickets.length === 1
    ) {
      const callbackFunc = () => {
        setAllocationData({
          ...ticketData,
          qtyOfSeats: +qty * ticket?.ticketPackage?.tickets[0]?.quantity
        });
      };
      addTicketToOrder({
        pricecard: ticketData.pricecard,
        item: ticketData.showtime,
        numOfSeats: +qty,
        callback: callbackFunc
      });
      closeFunc();
      return;
    }

    setErrorMsg(t("ticket.quantity.error.seat"));
    setQty("");
    // setErrorMsg("");
  };

  return (
    <>
      <Modal
        openStatus={!!ticketData}
        panelStyles="bg-white w-[30vw] h-[250px]"
      >
        <form onSubmit={onSubmit}>
          <div className="mt-2 deleteModal flex justify-center">
            <div className="px-3">
              <label className="text-center block font-bold mb-1 ">
                {t("ticket.quantity.label")}
              </label>
              <br />
              {errorMsg && (
                <p className="text-red-500 text-center p-2 px-3 ">
                  {" "}
                  {errorMsg}
                </p>
              )}
              <input
                type="text"
                value={qty}
                placeholder={t("ticket.quantity.placeholder")}
                className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                onClick={() => {
                  dispatch(setShowKeyboard(true));
                  dispatch(
                    setKeyboardConfiguration({
                      type: "numeric",
                      sharedInput: qty,
                      placeHolder: t("Common:keyboard.placeholder.quantity"),
                      // setSharedInput: x => {
                      //   setQty(x);
                      // }
                    })
                  );
                  setSetSharedInput(() => setQty)
                }}
              />
            </div>
          </div>

          <div className="mt-5 flex justify-center">
            <button
              type="submit"
              className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >
              <span>{t("Common:button.submit")} </span>
              <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                //   status={status}
              />
            </button>
            <button
              type="button"
              onClick={() => {
                setQty("");
                closeFunc();
                setErrorMsg("");
              }}
              className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >
              {t("Common:button.cancel")}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default QtyModalForAllocation;
