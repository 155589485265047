import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const DefaultColumnFilter = ({
  column: { filterValue = "", setFilter = () => {} }
}) => {
  const { t } = useTranslation(["Common"]);
  return (
    <div className={`relative `}>
      <div
        className={`absolute inset-y-0 left-0  flex items-center pl-3 pointer-events-none`}
      >
        <svg
          className="w-5 h-5 text-[#CEBCCE] "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
      <input
        type="search"
        id="default-search"
        className={`block min-w-[280px] w-full p-3 text-sm pl-10 rounded-xl h-[30px] text-white bg-primary outline-none `}
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={t("search.placeholder.search")}
      />
    </div>
  );
};

const StatusFilter = ({ column: { setFilter = () => {} } }) => {
  const { t } = useTranslation(["Common"]);

  return (
    <div className={`relative flex items-center space-x-3`}>
      <p> {t("search.status.state")}</p>
      <button
        className="p-2 text-base text-white rounded shadow bg-app-blue"
        onClick={() => setFilter(undefined)}
        type="button"
      >
        {t("search.button.on")}
      </button>
      <button
        className="p-2 text-base rounded shadow bg-app-green "
        onClick={() => setFilter(true)}
        type="button"
      >
        {t("search.button.off")}
      </button>
    </div>
  );
};

const SearchAndStatusFilter = ({
  columns = [],
  children = null,
  wrapperClass = "",
  filtersWrapperClass = "",
  childrenWrapperClass = ""
}) => {
  if (!columns.length) {
    return <></>;
  }

  return (
    <div className={`px-2 py-2 bg-secondary ${wrapperClass}`}>
      <div
        className={`inline-flex items-center space-x-7 ${filtersWrapperClass}`}
      >
        {columns.map((col, i) => (
          <Fragment key={i}>{col.render("Filter")}</Fragment>
        ))}
      </div>
      {children ? <div className={childrenWrapperClass}>{children}</div> : null}
    </div>
  );
};

export { SearchAndStatusFilter, DefaultColumnFilter, StatusFilter };
