import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { addToOrder, getSeatLayout, clearOrder } from "../../../redux/slices";

import { Spinner } from "../../UI/Spinner";
import { STATUS } from "../../../utils/constants";
import Sample from "./sample";
import { useTranslation } from "react-i18next";

const SeatAllocationModal = ({
  allocationData,
  closeFunc,
  addTicketToOrder
}) => {
  const { t } = useTranslation(["Home", "Common"]);
  const dispatch = useDispatch();
  const { seatLayout, seatLayoutErr, seatLayoutStatus } = useSelector(
    state => state.showtimes
  );

  const [selectedSeats, setSelectedSeats] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  //comment to push again

  const formatPkgTktPayload = () => {
    const data = allocationData.pricecard;
    const x = allocationData.showtime;

    return {
      id: data.ticketId,
      availableQuantity: x.totalSeats - x.seatsSold,
      name: x.film,
      price: 0,
      priceInPackage: data?.ticketPackage?.tickets[0]?.price,
      totalPackagePrice: data.price,
      canModify: false,
      showtimeId: x.id,
      packageTicketId: data.ticketId,
      ticketId: data.ticketId,
      //using this base quantity to preserve the quantity of the package so if its an item in the package has a quantity of
      // 2 when the package is added to the cart again it is incremented by 2 not the default one
      baseQuantity: 1,
      orderQuantity: 1,
      //this id would serve as a unique identifier for all the children of a package ticket so if any of them gets deleted
      //the siblings would be deleted too
      idForDelete: data.id,
      priceCardTicketId: data.id,
      screen: x.screen || t("ticket.empty.screen"),
      startTime: x.startTime,
      showtimeDate: new Date(x.startTime).toDateString(),
      time:
        new Date(x.startTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit"
        }) +
        "-" +
        new Date(x.endTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit"
        })
    };
  };

  useEffect(() => {
    // if (
    //   allocationData?.showtime?.id &&
    //   allocationData?.pricecard?.seatClassId
    // ) {
    dispatch(
      getSeatLayout({
        showtimeId: allocationData?.showtime?.id,
        seatClassId: allocationData?.pricecard?.seatClassId
      })
    );
    // }
  }, [allocationData]);

  // const sampleSeats = [
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  //   "H",
  //   "I",
  //   "J",
  //   "K",
  //   "L",
  // ];

  // const newSample = sampleSeats
  //   .map((q) => {
  //     return Array.from({ length: 25 }, (x, i) => {
  //       return {
  //         seat: q + (i + 1),
  //         status: q === "K" || q == "L" ? 0 : Math.floor(Math.random() * 2) + 1,
  //       };
  //     });
  //   })
  //   .reverse();

  //available 0
  //reserved 1
  // unavailable 2

  const selectFunc = data => {
    if (data.status === "Available") {
      let updatedSelectedSeats = [...selectedSeats];
      let identifier = data?.name + data?.seatNumber;
      let isSelected = selectedSeats.find(x => {
        return x.identifier === identifier;
      });
      if (isSelected) {
        updatedSelectedSeats = selectedSeats.filter(x => {
          return x.identifier !== identifier;
        });
        setSelectedSeats(updatedSelectedSeats);
      } else {
        if (selectedSeats?.length < allocationData?.qtyOfSeats) {
          updatedSelectedSeats.push({
            seatNumber: data.seatNumber,
            identifier,
            seatLayoutId: data.id,
            rowName: data.name
          });
          setSelectedSeats(updatedSelectedSeats);
        }
      }
    }
  };

  const submitFunc = () => {
    if (selectedSeats?.length < allocationData?.qtyOfSeats) {
      setErrorMsg(t("ticket.seat.error.select"));
      return;
    }
    setErrorMsg("");
    if (allocationData?.pricecard?.ticketGroup === "Standard") {
      addTicketToOrder({
        pricecard: allocationData?.pricecard,
        item: allocationData?.showtime,
        selectedSeats
      });
      setSelectedSeats([]);
      closeFunc();
    }
    if (allocationData?.pricecard?.ticketGroup === "Package") {
      const formattedPkgData = formatPkgTktPayload();
     
      selectedSeats.forEach(seat => {
        dispatch(
          addToOrder({
            ...formattedPkgData,
            seatLayoutId: seat.seatLayoutId,
            seatNumber: seat.seatNumber,
            seatIdentifier: seat.identifier,
            rowName: seat.rowName
          })
        );
      });
      setSelectedSeats([]);
      closeFunc();
    }
  };

  return (
    <Transition appear show={!!allocationData} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className=" overflow-auto  transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* <Dialog.Title
                  as="h1"
                  className="text-lg font-medium leading-6 bold text-gray-900 text-center"
                >
                  Seat Allocation
                </Dialog.Title> */}
                <div className="flex justify-center py-2">
                  {" "}
                  <Spinner status={seatLayoutStatus} />{" "}
                </div>
                <div className=" flex justify-center mb-2">
                  <button
                    type="button"
                    onClick={() => {
                      closeFunc();
                      dispatch(clearOrder());
                    }}
                    className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  >
                    {t("Common:button.cancel")}
                  </button>
                  <button
                    type="button"
                    className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={submitFunc}
                  >
                    <span>{t("Common:button.submit")} </span>
                  </button>
                </div>
                {errorMsg && (
                  <p className="text-red-500 text-center"> {errorMsg}</p>
                )}
                {seatLayoutErr && (
                  <p className="text-red-500 text-center"> {seatLayoutErr}</p>
                )}
                {seatLayoutStatus === STATUS.RESOLVED &&
                  seatLayout?.length < 1 && (
                    <p className="text-red-500 text-center">
                      {t("ticket.seat.load")}
                    </p>
                  )}
                <div className="flex justify-between items-center">
                  <div className="flex justify-start text-xs mb-2">
                    <div className="flex items-center">
                      <div className="bg-primary w-3 h-3 mr-1"></div>
                      <span className="mr-1">{t("ticket.seat.status.a")}</span>
                    </div>
                    <div className="flex items-center">
                      <div className="bg-gray-500 w-3 h-3 mr-1"></div>
                      <span className="mr-1">{t("ticket.seat.status.r")}</span>
                    </div>
                    <div className="flex items-center">
                      <div className="bg-gray-800 w-3 h-3 mr-1"></div>
                      <span className="mr-1">{t("ticket.seat.status.u")}</span>
                    </div>
                    <div className="flex items-center">
                      <div className="bg-blue-500 w-3 h-3 mr-1"></div>
                      <span className="mr-1">{t("ticket.seat.status.s")}</span>
                    </div>
                  </div>
                </div>
                <p className="text-center font-bold">
                  {t("ticket.seat.count.selected", {
                    count: selectedSeats?.length,
                    total: allocationData?.qtyOfSeats || 0
                  })}
                </p>
                {seatLayoutStatus === STATUS.RESOLVED && (
                  <div>
                    <div className=" flex justify-center pt-2">
                      {" "}
                      <div className="bg-primary p-1 text-white rounded text-xs">
                        {" "}
                        {t("ticket.seat.title")}
                      </div>
                    </div>
                    {seatLayout?.map(x => {
                      return (
                        <div className=" my-1 flex justify-around">
                          {x.map(y => {
                            return (
                              <div
                                className={`px-1 mx-1 text-white text-sm rounded ${
                                  y.status === "Unavailable"
                                    ? "bg-gray-800"
                                    : y.status === "Reserved"
                                    ? "bg-gray-500"
                                    : y.status === "Available"
                                    ? "bg-primary"
                                    : ""
                                } ${
                                  selectedSeats
                                    .map(x => {
                                      return x.identifier;
                                    })
                                    .includes(y?.name + y?.seatNumber)
                                    ? "bg-blue-500"
                                    : ""
                                }`}
                                onClick={() => {
                                  selectFunc(y);
                                }}
                              >
                                {" "}
                                {y?.name + y?.seatNumber}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* <Sample /> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default SeatAllocationModal;
