import { useState } from "react";
import Modal from "../../UI/Modal";
import { Spinner } from "../../UI/Spinner";
import {
  getItems,
  getShowtimes,
  setKeyboardConfiguration,
  setLoyaltyData,
  setShowKeyboard
} from "../../../redux/slices";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../context/KeyboardContext";

const LoyaltyReload = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["POS", "Common"]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
const { setSetSharedInput } = useKeyboard()
  const onSubmit = e => {
    e.preventDefault();
    if (!phoneNumber) {
      setErrorMsg(t("loyalty.errors.phone.required"));
      return;
    }

    if (phoneNumber && phoneNumber?.length !== 11) {
      setErrorMsg(t("loyalty.errors.phone.length"));
      return;
    }
    dispatch(getItems({ phoneNumber }));
    dispatch(getShowtimes({ phoneNumber }));
    dispatch(setLoyaltyData({ phoneNumber }));
    closeFunc();
    setErrorMsg("");
  };

  return (
    <Modal openStatus={openStatus} panelStyles="bg-white w-[30vw] h-[28vh]">
      <form onSubmit={onSubmit}>
        <div className="mt-2 deleteModal flex justify-center">
          <div>
            <label className="text-center block font-bold mb-1 ">
              {t("loyalty.label.phone")}
            </label>
            <br />
            {errorMsg && (
              <p className="text-red-500 text-center pb-2 "> {errorMsg}</p>
            )}
            <input
              type="text"
              value={phoneNumber}
              placeholder={t("loyalty.placeholder.phone")}
              className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numeric",
                    sharedInput: phoneNumber,
                    placeHolder: t(
                      "Common:keyboard.placeholder.customer.phone"
                    ),
                    // setSharedInput: x => {
                    //   setPhoneNumber(x);
                    // }
                  })
                );
                setSetSharedInput(() => setPhoneNumber)
              }}
            />
          </div>
        </div>

        <div className="mt-5 flex justify-center">
          <button
            type="submit"
            className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            <span>{t("Common:button.submit")} </span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-blue-800"
              //   status={status}
            />
          </button>
          <button
            type="button"
            onClick={() => {
              setPhoneNumber("");
              closeFunc();
              setErrorMsg("");
            }}
            className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            {t("Common:button.cancel")}
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default LoyaltyReload;
