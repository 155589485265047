import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { changePwdSchema } from "../utils/schemas";
import { useKeyboard } from "../context/KeyboardContext";
import { useDispatch, useSelector } from "react-redux";
import {
  changePin,
  clearHeldOrders,
  clearOrder,
  getCinemaSettings,
  getCustomChannels,
  getItems,
  getItemsLayout,
  getShowtimes,
  setKeyboardConfiguration,
  setShowKeyboard,
} from "../redux/slices";
import KeyboardModal from "../utils/KeyboardModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decode, decryptId } from "../utils/miscHelper";
import { Spinner } from "../components/UI/Spinner";
import { useTranslation } from "react-i18next";
import { currencyKey } from "../utils/constants";

const ChangePin = () => {
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { changePinStatus,changePinError } = useSelector((state) => state.profile);
  const { setSetSharedInput } = useKeyboard();
  const [searchParams] = useSearchParams();
  const resetToken = decode(searchParams.get("resetToken"));

  const {
    control,
    setValue,
    getValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePwdSchema),
    defaultValues: {
      oldPin: "",
      newPin: "",
      confirmPin: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    setValue("oldPin", oldPin);
    setValue("newPin", newPin);
    setValue("confirmPin", confirmPin);
  }, [oldPin, newPin, setValue, confirmPin]);
  const handleChangePwd = (values) => {
    dispatch(
      changePin({
        newPin: values.newPin,
        oldPin: values.oldPin,
        resetToken: resetToken,
      })
    )
      .unwrap()
      .then((resp) => {
      
        i18n.changeLanguage(resp.language);
        dispatch(
          getCinemaSettings({
            cinemaId: resp?.cinemaId,
            circuitId: resp?.circuitId,
          })
        );
        localStorage.setItem(currencyKey, resp?.currency);
        dispatch(getCustomChannels(resp?.cinemaId));
        dispatch(getItems()).then(() => {
          dispatch(getShowtimes()).then(() => {
            dispatch(getItemsLayout());
            dispatch(clearOrder());
            dispatch(clearHeldOrders());
            navigate("/");
          });
        });
      });
  };

  return (
    <div className="bg-primary h-[100vh] w-[100vw] p-4">
      <form className="loginForm" onSubmit={handleSubmit(handleChangePwd)}>
        <div className="flex justify-center uppercase mb-10">
          <h1 className="text-white text-3xl font-bold">
            You are required to change your default pin
          </h1>
        </div>
            {changePinError? (
          <p className="text-red-500 text-center lowercase">{changePinError}</p>
        ):null}
        <section className="space-y-5">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="oldPin"
              className="text-white text-[24px] pb-[20px] "
            >
              Old Pin
            </label>
            <input
              id="oldPin"
              type="text"
              value={oldPin}
              autoComplete="off"
              placeholder="Enter Old Pin"
              className=" w-[100%] text-white h-[50px] bg-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numeric",
                    sharedInput: oldPin,
                    placeHolder: " Enter Old Pin",
                  })
                );
                setSetSharedInput(() => setOldPin);
              }}
            />
            {errors?.oldPin?.message && (
              <p className="text-red-500 text-center">
                {errors?.oldPin?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="newPin"
              className="text-white text-[24px] pb-[20px] "
            >
              New Pin
            </label>
            <input
              id="newPin"
              type="text"
              value={newPin}
              autoComplete="off"
              placeholder="Enter New Pin"
              className=" w-[100%] text-white h-[50px] bg-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numeric",
                    sharedInput: newPin,
                    placeHolder: " Enter New Pin",
                  })
                );
                setSetSharedInput(() => setNewPin);
              }}
            />
            {errors?.newPin?.message && (
              <p className="text-red-500 text-center">
                {errors?.newPin?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="confirmPin"
              className="text-white text-[24px] pb-[20px] "
            >
              Confirm Pin
            </label>
            <input
              id="confirmPin"
              type="text"
              value={confirmPin}
              autoComplete="off"
              placeholder="Enter Confirm Pin"
              className=" w-[100%] text-white h-[50px] bg-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numeric",
                    sharedInput: confirmPin,
                    placeHolder: " Enter Confirm Pin",
                  })
                );
                setSetSharedInput(() => setConfirmPin);
              }}
            />
            {errors?.confirmPin?.message && (
              <p className="text-red-500 text-center">
                {errors?.confirmPin?.message}
              </p>
            )}
          </div>
        </section>
        <div className="flex justify-center mt-[20px]">
          <button
            className="bg-app-secondary2 text-white p-2 rounded flex items-center"
            type="submit"
          >
            <span>Change Pin</span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-pink-400"
              status={changePinStatus}
            />
          </button>
        </div>
      </form>
      <KeyboardModal />
    </div>
  );
};

export default ChangePin;
