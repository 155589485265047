import axios from "axios";
import { redirectFunc } from "../../utils/redirect";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUS, tokenKey } from "../../utils/constants";

export const checkDiscountCode = createAsyncThunk(
  "check_discount_code",
  async ({ discountCode, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}DiscountCodes/ValidateCode?discountCode=${discountCode}&cinemaId=${cinemaId}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const discountCodeData = response.data.data;

      return fulfillWithValue(discountCodeData);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  error: "",
  status: STATUS.IDLE,
  discountCodeData: {},
};

export const discountCodeSlice = createSlice({
  name: "discountCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkDiscountCode.pending, (state) => {
        state.error = "";
        state.status = STATUS.PENDING;
      })
      .addCase(checkDiscountCode.fulfilled, (state, action) => {
        state.error = "";
        state.status = STATUS.RESOLVED;
        state.discountCodeData = action.payload;
      })
      .addCase(checkDiscountCode.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUS.REJECTED;
      });
  },
});

export const discountCodeReducer = discountCodeSlice.reducer;
