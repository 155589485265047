import i18n from "i18next";
import Backend from "i18next-http-backend";
import ChainedBackend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import LocalStorageBackend from "i18next-localstorage-backend";

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [LocalStorageBackend, Backend],
      // translation file path
      backendOptions: [
        {
          expirationTime: 4 * 7 * 24 * 60 * 60 * 1000 // 7 days
        },
        {
          loadPath: "/assets/i18n/{{ns}}/{{lng}}.json"
        }
      ]
    },
    fallbackLng: "en",
    // please disable debug in production
    debug: true,
    //ns means namespace. It is used to group translations into different files.
    // can have multiple namespaces, in case you want to divide a huge
    // translation into smaller pieces and load them on demand
    keySeparator: ".",
    nsSeparator: ":",
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    react: {
      wait: true
    }
  });

export default i18n;
