import ReachLogo from "../../assets/images/reachlogo.png";
import { useTranslation } from "react-i18next";

const SmallScreen = () => {
  const { t } = useTranslation(["Common"]);
  return (
    <div className=" h-[100vh] w-[100vw] flex items-center justify-center">
      <div>
        <div className="flex justify-center">
          <div
            className=" w-[10rem] h-[10rem]  bg-no-repeat bg-contain bg-center"
            style={{
              backgroundImage: `url(${ReachLogo})`
            }}
          ></div>
        </div>
        <p className="text-primary text-center text-[20px] font-bold px-[5rem]">
          {t("screen.small")}
        </p>
      </div>
    </div>
  );
};
export default SmallScreen;
