import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_SALT;
export const changeArrShape = (prop, arr, shape = {}) => {
  const newShape = arr?.reduce((acc, curr) => {
    shape[curr[prop]] = curr;
    return acc;
  }, shape);
  return newShape || [];
};

export const encode = (value) => {
    if (!secretKey) {
        throw new Error("Missing SALT")
    }
  const ciphertext = CryptoJS.AES.encrypt(value, secretKey).toString();
  return encodeURIComponent(ciphertext);
};

export const decode = (value) => {
    if (!secretKey) {
      throw new Error("Missing 'SALT'");
    }

    if (!value) {
      throw new Error("Missing value");
    }
  const bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(value),
    secretKey
  );
  const originalId = bytes.toString(CryptoJS.enc.Utf8);
  return originalId;
};
