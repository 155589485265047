import React, { useState, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";

const layout = {
  default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
  shift: ["_ / #", "- % ^", "& * (", "{shift} ) +", ". {enter}"],
  alphaNum1: [
    "1 2 3 4 5 6 7 8 9 0",
    "q w e r t y u i o p",
    ". a s d f g h j k l -",
    "@ z x c v b n m _",
    "{caps} {bksp} {enter}",
  ],
  alphaNum2: [
    "1 2 3 4 5 6 7 8 9 0",
    "Q W E R T Y U I O P",
    ". A S D F G H J K L -",
    "@ Z X C V B N M _",
    "{caps} {bksp} {enter}",
  ],
};

//OVERWTITE CLASSES WITH CUSTOM CLASSES DEFINED IN index.css
//or global.css if you're using nextjs
//SPECIFICITY IS IMPORTANT SO BE CAREFUL
// const buttonTheme = [
//   {
//     class: "font-bold hg-red text-white",
//     buttons: "",
//   },
// ];

const KeyboardWrapper = ({
  onChange,
  handleEnter,
  keyboardRef,
  itemSearch = false,
}) => {
  const [layoutName, setLayoutName] = useState("default");
  const { type } = useSelector((state) => state.profile.keyboardConfigurations);

  const {t} = useTranslation(["Keyboard"])

  const display = {
    "{bksp}": t("bksp"),
    "{enter}": t("enter"),
    "@": "@",
    "{caps}": t("caps"),
  };

  useEffect(() => {
    if (type === "alphaNumeric" || itemSearch) {
      setLayoutName("alphaNum1");
    }
  }, [type]);

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      setLayoutName(layoutName === "default" ? "shift" : "default");
    }
    if (button === "{caps}") {
      setLayoutName(layoutName === "alphaNum1" ? "alphaNum2" : "alphaNum1");
    }

    if (button === "{enter}") {
      handleEnter();
    }
  };

  return (
    <Keyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      layoutName={layoutName}
      onChange={onChange}
      onKeyPress={onKeyPress}
      layout={layout}
      display={display}
      // buttonTheme={buttonTheme}
    />
  );
};

export default KeyboardWrapper;
