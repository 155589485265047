import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import NumPad from "react-numpad";
import {
  getTotals,
  setOfflineRestriction,
  setLoyaltyData,
} from "../../redux/slices";
import { useSelector, useDispatch } from "react-redux";
import { STATUS } from "../../utils/constants";
import GiftcardModal from "./GiftcardModal";
import DiscountCodeModal from "./DiscountCodeModal";
import PromoCodeModal from "./PromoCodeModal";
import LoyaltyDetailsModal from "./LoyaltyDetailsModal";
import LumiModal from "./LumiModal";
import { setShowKeyboard, setKeyboardConfiguration } from "../../redux/slices";
import { numToCurrency } from "../../utils/currencyHelper";
import { useTranslation } from "react-i18next";
import CustomChannelsModal from "./CustomChannelsModal";
import { useKeyboard } from "../../context/KeyboardContext";

const PaymentChannels = ({
  openStatus,
  closeFunc,
  setPaymentMode = () => {},
  setDiscountCodeId = () => {},
  setPromoCodeId = () => {},
  setLumiData,
  lumiData = { giftcardCodeValue: 0 },
  setMethodsList = () => {},
  swapTicketUseCase = false,
  setChannelsForSwapTicket = () => {},
  swapTicketCallBackFunc = () => {},
  swapTicketPaymentTotal = 0,
}) => {
  const [cashAmount, setCashAmount] = useState("");
  const [cardAmount, setCardAmount] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [externalVoucherAmount, setExternalVoucherAmount] = useState("");
  const [giftcardData, setGiftcardData] = useState({ amount: 0, id: "" });
  const [discountCodeData, setDiscountCodeData] = useState({
    amount: 0,
    id: null,
  });
  const [promoCodeData, setPromoCodeData] = useState({ amount: 0, id: null });
  const [openGiftcardModal, setOpenGiftcardModal] = useState(false);
  const [openDiscountCodeModal, setOpenDiscountCodeModal] = useState(false);
  const [openPromoCodeModal, setOpenPromoCodeModal] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [showOthers, setShowOthers] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openLoyaltyModal, setOpenLoyaltyModal] = useState(false);
  const [openLumiModal, setOpenLumiModal] = useState(false);
  const [loyaltyValue, setLoyaltyValue] = useState({ amount: 0, memberId: "" });
  const [loyaltyNumber, setLoyaltyNumber] = useState("");
  const [openCustomChannels, setOpenCustomChannels] = useState(false);
  const orders = useSelector((state) => state.orders);
  const { offlineMode, cinemaSettings } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { t } = useTranslation(["OrderPayment", "Common"]);
  const [customChannelsAmount, setCustomChannelsAmount] = useState([]);
  const { setSetSharedInput } = useKeyboard();

  const orderTotalAmount = swapTicketPaymentTotal || orders.orderTotalAmount;

  useEffect(() => {
    dispatch(getTotals());
  }, [orders, dispatch]);

  const resetIsDisabled = () => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };

  const cashChangeHandler = (amount) => {
    setCashAmount(amount);
  };

  const cardChangeHandler = (amount) => {
    setCardAmount(amount);
  };

  const transferChangeHandler = (amount) => {
    setTransferAmount(amount);
  };

  const externalVoucherChangeHandler = (amount) => {
    setExternalVoucherAmount(amount);
  };

  const loyaltyChangeHandler = (x) => {
    setLoyaltyNumber(x);
  };

  const channelsBreakdown = [];
  const methods = [];

  const customChannelsTotal = customChannelsAmount.reduce(
    (accumulator, transaction) => {
      return accumulator + +transaction.amount;
    },
    0
  );
  const totalEntered =
    +cashAmount +
    +cardAmount +
    +transferAmount +
    +externalVoucherAmount +
    +giftcardData?.amount +
    +discountCodeData.amount +
    +promoCodeData.amount +
    +loyaltyValue.amount +
    lumiData?.giftcardCodeValue +
    customChannelsTotal;

  const resetFunc = () => {
    setCashAmount("");
    setCardAmount("");
    setTransferAmount("");
    setExternalVoucherAmount("");
    setGiftcardData({ amount: 0, id: "" });
    setDiscountCodeData({ amount: 0, id: null });
    setPromoCodeData({ amount: 0, id: null });
    setLoyaltyValue({ amount: 0, memberId: "" });
    setAmountError("");
    closeFunc();
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (offlineMode || cinemaSettings?.orderCreation === 1) {
      setIsDisabled(true);
      resetIsDisabled();
      clearTimeout(resetIsDisabled);
    }

    if (loyaltyNumber && loyaltyNumber.length !== 11) {
      // we might need to adjust for intl numbers
      setAmountError(t("OrderPayment:paymentchannel_modal.errors.phone"));
      return;
    }
    if (loyaltyNumber && loyaltyNumber.length === 11) {
      dispatch(setLoyaltyData({ phoneNumber: loyaltyNumber }));
    }
    if (totalEntered !== orderTotalAmount) {
      setAmountError(
        ` ${t("OrderPayment:paymentchannel_modal.errors.order", {
          totalAmount: numToCurrency(orderTotalAmount),
          totalEntered: numToCurrency(totalEntered),
        })}`
      );
    } else {
      if (cashAmount) {
        channelsBreakdown.push({
          channelId: "channel-55402e",
          amount: +cashAmount,
        });
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.cash"
          )}:${numToCurrency(cashAmount)}`
        );
      }
      if (cardAmount) {
        channelsBreakdown.push({
          channelId: "channel-c2e01f",
          amount: +cardAmount,
        });
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.card"
          )}:${numToCurrency(cardAmount)}`
        );
      }
      if (transferAmount) {
        channelsBreakdown.push({
          channelId: "channel-34d874",
          amount: +transferAmount,
        });
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.transfer"
          )}:${numToCurrency(transferAmount)}`
        );
      }
      if (externalVoucherAmount) {
        channelsBreakdown.push({
          channelId: "channel-4a7cfb",
          amount: +externalVoucherAmount,
        });
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.extvoucher"
          )}:${numToCurrency(externalVoucherAmount)}`
        );
      }
      if (giftcardData?.amount > 0) {
        channelsBreakdown.push({
          channelId: "channel-eecf16",
          amount: +giftcardData.amount,
          giftCardId: giftcardData.id,
        });
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.giftcard"
          )}:${numToCurrency(giftcardData.amount)}`
        );
      }
      if (loyaltyValue.amount > 0) {
        channelsBreakdown.push({
          channelId: "channel-26998e",
          ...loyaltyValue,
          amount: +loyaltyValue.amount,
        });
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.loyalty"
          )}:${numToCurrency(loyaltyValue.amount)}`
        );
      }
      if (discountCodeData.amount > 0) {
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.discount"
          )}:${numToCurrency(discountCodeData.amount)}`
        );
      }
      if (promoCodeData.amount > 0) {
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.promo"
          )}:${numToCurrency(promoCodeData.amount)}`
        );
      }
      if (lumiData.giftcardCodeValue > 0) {
        channelsBreakdown.push({
          channelId: "channel-293e75",
          amount: +lumiData.giftcardCodeValue,
          giftCardCode: lumiData.giftcardCode,
        });
        methods.push(
          ` ${t(
            "OrderPayment:paymentchannel_modal.label.lumi"
          )}:${numToCurrency(lumiData.giftcardCodeValue)}`
        );
      }

      if (customChannelsAmount?.length > 0) {
        customChannelsAmount?.forEach((channel) => {
          channelsBreakdown.push({
            channelId: "channel-2d1980",
            amount: +channel?.amount,
            otherChannelId: channel?.channelId,
          });
          methods.push(` ${channel?.name}:${numToCurrency(channel?.amount)}`);
        });
      }
      if (orders.createOrderStatus !== STATUS.PENDING && !swapTicketUseCase) {
        setPaymentMode(channelsBreakdown);
        setMethodsList(methods);
        setDiscountCodeId(discountCodeData?.id);
        setPromoCodeId(promoCodeData?.id);
        resetFunc();
      }

      if (swapTicketUseCase) {
        setChannelsForSwapTicket(channelsBreakdown);
        resetFunc();
        swapTicketCallBackFunc();
      }
    }
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className=" transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {t("OrderPayment:header.payChannel")}
                </Dialog.Title>

                <form onSubmit={onSubmit}>
                  {amountError && (
                    <p className=" text-red-600 text-center">{amountError}</p>
                  )}
                  <p className="text-center font-extrabold py-5">
                    {t("OrderPayment:paymentchannel_modal.sub-header")}:
                    <span
                      className={
                        orderTotalAmount - totalEntered !== 0
                          ? "text-red-500"
                          : "text-black"
                      }
                    >{` ${numToCurrency(
                      orderTotalAmount - totalEntered
                    )}`}</span>
                  </p>
                  <div className="grid grid-cols-3 gap-x-5 ">
                    <div className="mt-2 deleteModal">
                      <label>
                        {" "}
                        {t("OrderPayment:paymentchannel_modal.label.card")}{" "}
                      </label>
                      <br />
                      <NumPad.Number
                        placeholder={t(
                          "OrderPayment:paymentchannel_modal.placeholder.card"
                        )}
                        value={cardAmount}
                        onChange={cardChangeHandler}
                      />
                    </div>
                    <div className="mt-2 deleteModal">
                      <label>
                        {t("OrderPayment:paymentchannel_modal.label.cash")}
                      </label>
                      <br />
                      <NumPad.Number
                        placeholder={t(
                          "OrderPayment:paymentchannel_modal.placeholder.cash"
                        )}
                        value={cashAmount}
                        onChange={cashChangeHandler}
                      />
                    </div>
                    <div className="mt-2 deleteModal">
                      <label>
                        {" "}
                        {t(
                          "OrderPayment:paymentchannel_modal.label.transfer"
                        )}{" "}
                      </label>
                      <br />
                      <NumPad.Number
                        placeholder={t(
                          "OrderPayment:paymentchannel_modal.placeholder.transfer"
                        )}
                        value={transferAmount}
                        onChange={transferChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center py-3">
                    {offlineMode && (
                      <div>
                        <label className="text-center block ">
                          {t(
                            "OrderPayment:paymentchannel_modal.label.loyalty_num"
                          )}
                        </label>

                        <input
                          type="text"
                          value={loyaltyNumber}
                          placeholder={t(
                            "Common:keyboard.placeholder.customer.phone"
                          )}
                          className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "numeric",
                                sharedInput: loyaltyNumber,
                                placeHolder: t(
                                  "Common:keyboard.placeholder.customer.phone"
                                ),
                                // setSharedInput: (x) => {
                                //   setLoyaltyNumber(x);
                                // },
                              })
                            );
                            setSetSharedInput(() => setLoyaltyNumber);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className={showOthers ? "block" : "hidden"}>
                    <div className="flex justify-center items-center mt-2 py-3">
                      <button
                        type="button"
                        onClick={() => {
                          setOpenCustomChannels(true);
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      >
                        Custom Channels
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setOpenLoyaltyModal(true);
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      >
                        {t("OrderPayment:paymentchannel_modal.button.loyalty")}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setOpenGiftcardModal(true);
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      >
                        {t("OrderPayment:paymentchannel_modal.button.giftcard")}
                      </button>
                      {!swapTicketUseCase && (
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              setOpenDiscountCodeModal(true);
                            }}
                            className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                          >
                            {t(
                              "OrderPayment:paymentchannel_modal.button.discount"
                            )}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setOpenPromoCodeModal(true);
                            }}
                            className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                          >
                            {t(
                              "OrderPayment:paymentchannel_modal.button.promo"
                            )}
                          </button>
                        </>
                      )}
                      {/* <button
                        type="button"
                        onClick={() => {
                          setOpenLumiModal(true);
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      >
                        {t("OrderPayment:paymentchannel_modal.button.bond")}
                      </button> */}
                    </div>
                    <div className="grid grid-cols-3 gap-x-5 ">
                      <div className="mt-2 deleteModal">
                        <label>Custom Total</label>
                        <br />
                        <input
                          value={customChannelsTotal}
                          className=" rounded border border-[black] p-[4px] pl-[12px]"
                          readOnly
                        />
                      </div>
                      {/* <div className="mt-2 deleteModal">
                        <label>
                          {" "}
                          {t(
                            "OrderPayment:paymentchannel_modal.label.extvoucher"
                          )}
                        </label>
                        <br />
                        <NumPad.Number
                          placeholder={t(
                            "OrderPayment:paymentchannel_modal.placeholder.extvoucher"
                          )}
                          value={externalVoucherAmount}
                          onChange={externalVoucherChangeHandler}
                        />
                      </div> */}
                      <div className="mt-2 deleteModal">
                        <label>
                          {" "}
                          {t("OrderPayment:paymentchannel_modal.label.point")}
                        </label>
                        <br />
                        <input
                          value={loyaltyValue?.amount}
                          className=" rounded border border-[black] p-[4px] pl-[12px]"
                          readOnly
                        />
                      </div>

                      <div className="mt-2 deleteModal">
                        <label>
                          {" "}
                          {t(
                            "OrderPayment:paymentchannel_modal.label.giftcard"
                          )}
                        </label>
                        <br />
                        <input
                          // placeholder="Enter Transfer Amount"
                          value={giftcardData?.amount}
                          className=" rounded border border-[black] p-[4px] pl-[12px]"
                          // onChange={transferChangeHandler}
                          readOnly
                        />
                      </div>
                      {!swapTicketUseCase && (
                        <>
                          <div className="mt-2 deleteModal">
                            <label>
                              {" "}
                              {t(
                                "OrderPayment:paymentchannel_modal.label.discount_cd"
                              )}{" "}
                            </label>
                            <br />
                            <input
                              // placeholder="Enter Transfer Amount"
                              value={discountCodeData?.amount}
                              className=" rounded border border-[black] p-[4px] pl-[12px]"
                              // onChange={transferChangeHandler}
                              readOnly
                            />
                          </div>
                          <div className="mt-2 deleteModal">
                            <label>
                              {" "}
                              {t(
                                "OrderPayment:paymentchannel_modal.label.promo_cd"
                              )}
                            </label>
                            <br />
                            <input
                              // placeholder="Enter Transfer Amount"
                              value={promoCodeData?.amount}
                              className=" rounded border border-[black] p-[4px] pl-[12px]"
                              // onChange={transferChangeHandler}
                              readOnly
                            />
                          </div>{" "}
                        </>
                      )}
                      {/* <div className="mt-2 deleteModal">
                        <label>
                          {" "}
                          {t("OrderPayment:paymentchannel_modal.label.bond")}
                        </label>
                        <br />
                        <input
                          value={lumiData?.giftcardCodeValue}
                          className=" rounded border border-[black] p-[4px] pl-[12px]"
                          readOnly
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      onClick={() => {
                        offlineMode
                          ? dispatch(setOfflineRestriction(true))
                          : setShowOthers((prev) => !prev);
                      }}
                      className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                    >
                      {!showOthers
                        ? t("OrderPayment:paymentchannel_modal.button.show")
                        : t("OrderPayment:paymentchannel_modal.button.hide")}
                    </button>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      onClick={() => {
                        closeFunc();
                        setCashAmount("");
                        setCardAmount("");
                        setTransferAmount("");
                        setExternalVoucherAmount("");
                        setGiftcardData({ amount: 0, id: "" });
                        setLoyaltyValue({ memberId: "", amount: 0 });
                        setAmountError("");
                        setShowOthers(false);
                        dispatch(setLoyaltyData({}));
                        setCustomChannelsAmount([]);
                      }}
                    >
                      {t("Common:button.cancel")}
                    </button>
                    <button
                      disabled={
                        offlineMode || cinemaSettings?.orderCreation === 1
                          ? isDisabled
                          : false
                      }
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                    >
                      {t("Common:button.submit")}
                    </button>
                  </div>
                </form>
                {openGiftcardModal && (
                  <GiftcardModal
                    openStatus={openGiftcardModal}
                    closeFunc={() => {
                      setOpenGiftcardModal(false);
                    }}
                    setGiftcardData={setGiftcardData}
                  />
                )}
                <DiscountCodeModal
                  openStatus={openDiscountCodeModal}
                  closeFunc={() => {
                    setOpenDiscountCodeModal(false);
                  }}
                  setDiscountCodeData={setDiscountCodeData}
                />
                <PromoCodeModal
                  openStatus={openPromoCodeModal}
                  closeFunc={() => {
                    setOpenPromoCodeModal(false);
                  }}
                  setPromoCodeData={setPromoCodeData}
                />
                <LoyaltyDetailsModal
                  openStatus={openLoyaltyModal}
                  closeFunc={() => {
                    setOpenLoyaltyModal(false);
                  }}
                  setLoyaltyValue={setLoyaltyValue}
                />
                {/* <LumiModal
                  openStatus={openLumiModal}
                  closeFunc={() => {
                    setOpenLumiModal(false);
                  }}
                  setLumiData={setLumiData}
                /> */}
                <CustomChannelsModal
                  openStatus={openCustomChannels}
                  closeFunc={() => {
                    setOpenCustomChannels(false);
                  }}
                  customChannelsAmount={customChannelsAmount}
                  setCustomChannelsAmount={setCustomChannelsAmount}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default PaymentChannels;
