import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  createOfflineOrder,
  setOfflineMode,
  setOfflineRestriction,
  resetIds,
  getShowtimes,
  getItems,
} from "../../redux/slices";
import { Offline, Online } from "react-detect-offline";
import { Spinner2 } from "../UI/Spinner2";
import { STATUS } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const ModeModal = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["POS", "Common"]);

  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(false);

  const { offlineMode } = useSelector((state) => state.profile);
  const {
    createdOfflineOrder,
    createOfflineOrderStatus,
    createOfflineOrderError,
  } = useSelector((state) => state.orders);
  let offlineSales = JSON.parse(localStorage.getItem("offlineOrders")) || [];

  const createFunction = () => {
    //foreach helps delete duplicates that might have happened due to double clicking
    let filteredOfflineSales = [];
    offlineSales.forEach((element) => {
      if (
        !filteredOfflineSales
          .map((x) => {
            return x.offlineId;
          })
          .includes(element.offlineId)
      ) {
        filteredOfflineSales.push(element);
      }
    });

    dispatch(createOfflineOrder(filteredOfflineSales))
      .unwrap()
      .then(() => {
        // if (
        //   createOfflineOrderStatus === STATUS.RESOLVED
        // ) {
        dispatch(resetIds());
        localStorage.setItem("offlineOrders", JSON.stringify([]));
        setShowMessage(true);
        dispatch(getShowtimes());
        dispatch(getItems());
        // }
      })
      .catch(() => {
        setShowMessage(createOfflineOrderError);
      });
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-center items-center">
                  <p>
                    {t("mode.title")}:{" "}
                    {offlineMode ? (
                      <span className="text-red-500">{t("mode.off")}</span>
                    ) : (
                      t("mode.on")
                    )}
                  </p>

                  <button
                    type="button"
                    className="inline-flex ml-3 justify-center rounded-md border border-transparent hover: border-blue-500 bg-blue-500 px-4 py-1 text-sm  text-white hover:bg-blue-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#FEE27D] focus-visible:ring-offset-2"
                    onClick={() => {
                      dispatch(setOfflineMode());
                    }}
                  >
                    {t("mode.toggle")}
                  </button>
                </div>
                <p className="text-center mt-3">
                  {t("mode.sales")}: {offlineSales.length}
                </p>

                {offlineSales.length > 0 && (
                  <>
                    {" "}
                    <Online>
                      <div className="flex justify-center mt-3">
                        <button
                          type="button"
                          disabled={createOfflineOrderStatus === STATUS.PENDING}
                          className="inline-flex ml-3 justify-center rounded-md border border-transparent hover: border-blue-500 bg-blue-500 px-4 py-1 text-sm  text-white hover:bg-blue-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-[#FEE27D] focus-visible:ring-offset-2"
                          onClick={() => {
                            offlineMode
                              ? dispatch(setOfflineRestriction(true))
                              : createFunction();
                          }}
                        >
                          {t("mode.upload.start")}
                        </button>
                      </div>
                    </Online>
                    <Offline>
                      <p className="text-red-500 text-center">
                        {t("mode.network")}
                      </p>
                    </Offline>
                  </>
                )}
                {createOfflineOrderStatus === STATUS.PENDING && (
                  <>
                    <p className="text-center text-[20px]">
                      {t("mode.upload.progress")}
                    </p>
                    <div className="flex justify-center">
                      <Spinner2 />
                    </div>
                  </>
                )}
                {showMessage && createOfflineOrderStatus === STATUS.RESOLVED && (
                  <p
                    className={`text-center ${
                      createdOfflineOrder.failed > 0 ? "text-red-500" : ""
                    }`}
                  >
                    {" "}
                    {t("mode.upload.success", {
                      success: createdOfflineOrder.success,
                      failed: createdOfflineOrder.fail,
                      prompt: `${
                        createdOfflineOrder.fail > 0
                          ? "Kindly notify your Admin"
                          : ""
                      }`,
                    })}
                  </p>
                )}

                {showMessage &&
                  createOfflineOrderStatus === STATUS.REJECTED && (
                    <p className={`textCenter text-red-500`}>
                      {t("mode.upload.failed")}
                    </p>
                  )}
                <div className="flex justify-center mt-3">
                  <button
                    type="button"
                    className="inline-flex mr-2 border justify-center rounded-md border-blue-900 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      closeFunc();
                      setShowMessage(false);
                    }}
                  >
                    {t("Common:button.close")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default ModeModal;
