import React, { useState, useRef, useEffect } from "react";
import KeyboardWrapper from "./KeyBoardWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setSharedInput, setShowKeyboard } from "../../redux/slices/profileSlice";
import {useTranslation} from "react-i18next";
import { useKeyboard } from "../../context/KeyboardContext";

const Eye = ({ show }) => {
  return show ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
      />
    </svg>
  );
};

const enterPress = (input) =>
  console.log(input || "enter pressed, but no input value");

const AppKeyBoard = ({ handleEnter = enterPress }) => {
  const {t} = useTranslation(["Common"]);
  const [show, setShow] = useState(true);
  const keyboard = useRef(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const { type, sharedInput, placeHolder } = useSelector(
    (state) => state.profile.keyboardConfigurations
  );
  const [input, setInput] = useState(sharedInput);
  const [showEye, setShowEye] = useState(false);
  const { setSharedInput } = useKeyboard()
  useEffect(() => {
    if (sharedInput && keyboard.current) {
      keyboard?.current?.setInput(sharedInput);
    }
  }, [sharedInput]);


  useEffect(() => {
    if (type === "numeric" || type === "alphaNumeric") {
      setShowEye(false);
      setShow(true);
    }

    if (type === "numericPin") {
      setShowEye(true);
      setShow(false);
    }
  }, [type]);

  const onChangeInput = (event) => {
    const newInput = event.target.value;
    setInput(newInput);
    keyboard?.current?.setInput(newInput);
  };

  const onEnterPress = () => {
    setSharedInput(input.trim());
    // dispatch(setSharedInput(input.trim()))
    dispatch(setShowKeyboard(false));
  };

  return (
    <div>
      <div className="my-4 relative ">
        <input
          type={show ? "text" : "password"}
          value={input}
          placeholder={placeHolder || ""}
          onChange={(e) => onChangeInput(e)}
          className={`${
            type === "alphaNumeric" ? "w-[400px]" : ""
          } border rounded p-2 pr-9 `}
          ref={inputRef}
        />

        {showEye && (
          <button
            type="button"
            onClick={() => setShow((s) => !s)}
            className="absolute right-2 top-2/4 -translate-y-1/2"
          >
            <Eye show={show} />
          </button>
        )}
      </div>
      <KeyboardWrapper
        handleEnter={onEnterPress}
        keyboardRef={keyboard}
        onChange={setInput}
      />
      <div className="my-3  flex justify-center">
        <button
          className="bg-primary text-white px-3 py-1 rounded"
          onClick={() => {
            dispatch(setShowKeyboard(false));
          }}
        >
          {" "}
          {t("button.close")}{" "}
        </button>
      </div>
    </div>
  );
};

export default AppKeyBoard;
