import ReachLogo from "../../assets/images/reachlogo.png";
import { useTranslation } from "react-i18next";
const ComingSoon = () => {
  const { t } = useTranslation(["Common"]);
  return (
    <div className=" h-[100%] flex items-center justify-center">
      <div>
        <div
          className=" w-[10rem] h-[10rem]  bg-no-repeat bg-contain bg-center"
          style={{
            backgroundImage: `url(${ReachLogo})`
          }}
        ></div>
        <p className="text-primary text-center text-[20px] font-bold">
          {t("coming.title")}
        </p>
      </div>
    </div>
  );
};
export default ComingSoon;
