import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  createAdminAction,
  addToOrder,
  setShowKeyboard,
  setKeyboardConfiguration
} from "../../../redux/slices";
import NumPad from "react-numpad";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../../utils/schemas";
import { authenticateAdmin2 } from "../../../redux/slices";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../context/KeyboardContext";

const TicketSaleAuthModal = ({ ticketData, closeFunc }) => {
  const { t } = useTranslation(["Home", "Common"]);
  const dispatch = useDispatch();
  const { adminAuthError2 } = useSelector(state => state.items);
  const [userNumber, setUserNumber] = useState("");
  const [pin, setPin] = useState("");
  const { setSetSharedInput } = useKeyboard()
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      userNumber: "",
      pin: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    setValue("userNumber", userNumber);
    setValue("pin", pin);
  }, [userNumber, pin]);

  const onSubmit = data => {
    dispatch(authenticateAdmin2(data))
      .unwrap()
      .then(() => {
        dispatch(
          createAdminAction({
            doneById: data.userNumber,
            details: null,
            action: 11
          })
        );
        dispatch(addToOrder(ticketData));
        setValue("userNumber", "");
        setValue("pin", "");
        setPin("");
        setUserNumber("");
        closeFunc();
      })
      .catch(err => console.log(err));
  };

  return (
    <Transition appear show={!!ticketData} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t("Home:ticket.sale.title")}
                </Dialog.Title>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {adminAuthError2 && (
                    <p className="text-red-500 text-center">
                      {" "}
                      {adminAuthError2}
                    </p>
                  )}
                  <div className=" deleteModal my-4 flex justify-center">
                    <input
                      type="text"
                      value={userNumber}
                      autoComplete="off"
                      placeholder={t("Common:admin_form.placeholder.user")}
                      className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                      onClick={() => {
                        dispatch(setShowKeyboard(true));
                        dispatch(
                          setKeyboardConfiguration({
                            type: "numeric",
                            sharedInput: userNumber,
                            placeHolder: t("Common:keyboard.placeholder.user"),
                            // setSharedInput: x => {
                            //   setUserNumber(x);
                            // }
                          })
                        );
                        setSetSharedInput(() => setUserNumber)
                      }}
                    />
                  </div>
                  {errors?.userNumber?.message && (
                    <p className="text-red-500 text-center">
                      {errors.userNumber.message}
                    </p>
                  )}
                  <div className="my-4 flex justify-center deleteModal">
                    <input
                      type="password"
                      value={pin}
                      autoComplete="new-password"
                      placeholder={t("Common:admin_form.placeholder.pin")}
                      className=" w-[100%] h-[50px] border border-secondary rounded py-2 px-3"
                      onClick={() => {
                        dispatch(setShowKeyboard(true));
                        dispatch(
                          setKeyboardConfiguration({
                            type: "numericPin",
                            sharedInput: pin,
                            placeHolder: t("Common:keyboard.placeholder.pin"),
                            // setSharedInput: x => {
                            //   setPin(x);
                            // }
                          })
                        );
                        setSetSharedInput(() => setPin)
                      }}
                    />
                  </div>
                  {errors?.pin?.message && (
                    <p className="text-red-500 text-center">
                      {errors.pin.message}
                    </p>
                  )}

                  <div className="mt-4 flex justify-center">
                    <button
                      type="submit"
                      className="inline-flex justify-center mr-3 rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("Common:button.confirm")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setValue("userNumber", "");
                        setValue("pin", "");
                        setPin("");
                        setUserNumber("");
                        closeFunc();
                      }}
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default TicketSaleAuthModal;
