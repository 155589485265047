import axios from "axios";
import { redirectFunc } from "../../utils/redirect";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUS, tokenKey } from "../../utils/constants";
import addHours from "date-fns/addHours";

export const checkPromoCode = createAsyncThunk(
  "check_promo_code",
  async ({ promoCode, cinemaId }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);
    const todayDate = addHours(new Date(), 1).toISOString();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}PromoCodes/ValidateCode?promoCode=${promoCode}&cinemaId=${cinemaId}&todayDate=${todayDate}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const promoCodeData = response.data.data;

      return fulfillWithValue(promoCodeData);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  error: "",
  status: STATUS.IDLE,
  promoCodeData: {},
};

export const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkPromoCode.pending, (state) => {
        state.error = "";
        state.status = STATUS.PENDING;
      })
      .addCase(checkPromoCode.fulfilled, (state, action) => {
        state.error = "";
        state.status = STATUS.RESOLVED;
        state.promoCodeData = action.payload;
      })
      .addCase(checkPromoCode.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUS.REJECTED;
      });
  },
});

export const promoCodeReducer = promoCodeSlice.reducer;
