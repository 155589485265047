import Modal from "../../UI/Modal";
import NumPad from "react-numpad";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { numToCurrency } from "../../../utils/currencyHelper";
import {
  getCashierSession,
  closeSession,
  setShowKeyboard,
  setKeyboardConfiguration,
  getAllPaymentChannel,
  getCustomChannels,
} from "../../../redux/slices";
import { useLogout } from "../../../hooks";
import { Spinner } from "../../UI/Spinner";
import { useTranslation } from "react-i18next";
import OtherChannelModal from "./OtherChannelModal";
import { changeArrShape } from "../../../utils/miscHelper";
const CloseSession = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["Admin"]);
  const [cashAmount, setCashAmount] = useState("");
  const [cardAmount, setCardAmount] = useState("");
  const [openOtherChannel, setOpenOtherChannel] = useState(false);
  const [showError, setShowError] = useState(false);
  const [transferAmount, setTransferAmount] = useState("");
  const [externalVoucherAmount, setExternalVoucherAmount] = useState("");
  const [channelValues, setChannelValues] = useState({});
  const { cashierSession,userData} = useSelector(
    (state) => state.profile
  );
  const { customChannels } = useSelector((state) => state.orders)
  const { orderItems, closeSessionStatus, closeSessionErr } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();
  const { logout } = useLogout();

useEffect(() => {
dispatch(getCustomChannels(userData?.cinemaId))
},[userData?.cinemaId])
  

  useEffect(() => {
    dispatch(getCashierSession())
      .unwrap()
      .then((data) => {
        setCashAmount(data.actualCash || data?.expectedCash);
        setCardAmount(data.actualCard || data?.expectedCard);
        setTransferAmount(data.actualTransfer || data?.expectedTransfer);
        setExternalVoucherAmount(
          data.actualExternalVoucher || data?.expectedExternalVoucher
        );
        const newSessionChannel = changeArrShape("otherChannelId", data?.sessionChannels, {})
        setChannelValues(customChannels?.reduce((acc, curr) => {
          acc[curr.id] = { actual: newSessionChannel[curr.id]?.actual || 0, expected: newSessionChannel[curr?.id]?.expected || 0 }
          return acc
        }, {}))
      })
  }, []);

 
    const sessionChannels = useMemo(() => {
      if (Object.values(channelValues).length > 0) {
      const value = customChannels?.reduce((acc, curr) => {
        if (curr.id in channelValues) {
          return [
            ...acc,
            {
              otherChannelId: curr?.id,
              // otherChannel: curr?.name,
              actual: Number(channelValues[curr.id]?.actual),
            },
          ];
        }
        return acc
      }, []);
      return value;
    }

  }, [customChannels,  channelValues]);
  const otherChannelAmount = useMemo(() => {
    if (Object.values(channelValues).length > 0) {
      const sum = Object.values(channelValues).reduce((acc, curr) => {
        return acc + +curr.actual;
      }, 0);
      return sum;
    }
  }, [channelValues]);

  const cashChangeHandler = (amount) => {
    setCashAmount(amount);
  };

  const cardChangeHandler = (amount) => {
    setCardAmount(amount);
  };

  const transferChangeHandler = (amount) => {
    setTransferAmount(amount);
  };

  const extVoucherChangeHandler = (amount) => {
    setExternalVoucherAmount(amount);
  };

  const closeSessionHandler = () => {
    let heldOrders = JSON.parse(localStorage.getItem("heldOrders"));
    let offlineOrders = JSON.parse(localStorage.getItem("offlineOrders"));

    if (
      heldOrders?.length > 0 ||
      offlineOrders?.length > 0 ||
      orderItems?.length > 0
    ) {
      return;
    }

    dispatch(
      closeSession({
        actualCash: cashAmount,
        actualCard: cardAmount,
        actualTransfer: transferAmount,
        actualExternalVoucher: externalVoucherAmount,
        actualOtherChannel: otherChannelAmount,
        sessionChannels: sessionChannels,
      })
    )
      .unwrap()
      .then(() => {
        logout();
        localStorage.clear();
      })
      .catch((error) => {
        setShowError(true);
      });
  };

  return (
    <>
    <Modal
      openStatus={openStatus}
      panelStyles="bg-white p-[30px] max-w-[700px] "
    >
      <div className="flex justify-between">
        <div></div>
        <div>
          <p className="text-center text-[20px] font-bold">
            {t("modals.session.title")}
          </p>
          <p className="text-center">{`Total Expected: ${numToCurrency(
            cashierSession?.expectedCash +
              cashierSession?.expectedCard +
              cashierSession?.expectedTransfer +
              cashierSession?.expectedExternalVoucher +
              cashierSession?.expectedOtherChannel
          )}`}</p>
        </div>
        <button
          onClick={() => {
            closeFunc();
            setShowError(false);
          }}
          className="font-bold text-[20px] border-2 rounded px-2 text-blue-500"
        >
          X
        </button>
      </div>
      {showError === true && (
        <p className="text-red-500 text-center">{closeSessionErr}</p>
      )}

      <div className="mt-7 grid grid-cols-3 gap-x-5 gap-y-8 mb-2">
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label> {t("modals.session.expected.cash")} </label>
          <p className="">{numToCurrency(cashierSession?.expectedCash)}</p>
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.actual.cash")}</label>
          <NumPad.Number
            placeholder={t("modals.session.placeholder.cash")}
            value={cashAmount}
            onChange={cashChangeHandler}
          />
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.variance.cash")}</label>
          <p
            className={`  ${cashAmount - cashierSession?.expectedCash !== 0 &&
              "text-red-500"} `}
          >
            {numToCurrency(cashAmount - cashierSession?.expectedCash)}
          </p>
        </div>

        {/* expected card */}
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label> {t("modals.session.expected.card")} </label>
          <p className="  ">{numToCurrency(cashierSession?.expectedCard)}</p>
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.actual.card")}</label>
          <NumPad.Number
            placeholder={t("modals.session.placeholder.card")}
            value={cardAmount}
            onChange={cardChangeHandler}
          />
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.variance.card")}</label>
          <p
            className={`  ${cardAmount - cashierSession?.expectedCard !== 0 &&
              "text-red-500"} `}
          >
            {numToCurrency(cardAmount - cashierSession?.expectedCard)}
          </p>
        </div>

        {/* expected transfer */}
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.expected.transfer")}</label>
          <p className="  ">
            {numToCurrency(cashierSession?.expectedTransfer)}
          </p>
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.actual.transfer")}</label>
          <NumPad.Number
            placeholder={t("modals.session.placeholder.transfer")}
            value={transferAmount}
            onChange={transferChangeHandler}
          />
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.variance.transfer")}</label>
          <p className="  ">
            {numToCurrency(transferAmount - cashierSession?.expectedTransfer)}
          </p>
        </div>

        {/* expected external voucher */}
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.expected.voucher")}</label>
          <p className="  ">
            {numToCurrency(cashierSession?.expectedExternalVoucher)}
          </p>
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.actual.voucher")}</label>
          <NumPad.Number
            placeholder={t("modals.session.placeholder.voucher")}
            value={transferAmount}
            onChange={extVoucherChangeHandler}
          />
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.variance.voucher")}</label>
          <p className="  ">
            {numToCurrency(
              externalVoucherAmount - cashierSession?.expectedExternalVoucher
            )}
          </p>
        </div>
        {/* expected other channel */}

        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.expected.custom")}</label>
          <p className="  ">
            {numToCurrency(cashierSession?.expectedOtherChannel)}
          </p>
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.actual.custom")}</label>
          <input
            readOnly
            value={otherChannelAmount}
            onClick={() => setOpenOtherChannel(true)}
            className="border-[1px] w-full border-black rounded-[5px] pl-3 pr-1 py-1"
          />
        </div>
        <div className="flex flex-col gap-2 items-center deleteModal">
          <label>{t("modals.session.variance.custom")}</label>
          <p className={`${otherChannelAmount-cashierSession?.expectedOtherChannel<0 &&'text-red-500'} ${otherChannelAmount-cashierSession?.expectedOtherChannel>0 &&'text-green-500'}`}>
            {numToCurrency(
              otherChannelAmount - cashierSession?.expectedOtherChannel
            )}
          </p>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          className="inline-flex justify-center items-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
          onClick={closeSessionHandler}
        >
          <span>{t("modals.session.button.submit")}</span>
          <Spinner
            className="!w-3 !h-3 mx-1 text-white fill-blue-800"
            status={closeSessionStatus}
          />
        </button>
      </div>
    </Modal>
      <OtherChannelModal
        channelValues={channelValues}
        customChannels={customChannels}
        setChannelValues={setChannelValues}
        cashierSession={cashierSession}
        openStatus={openOtherChannel}
        onClose={() => setOpenOtherChannel(false)}
      />
    </>
  );
};
export default CloseSession;
