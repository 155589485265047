import Modal from "../../../UI/Modal";
import { useTranslation } from "react-i18next";

const ViewsModal = ({
  openStatus,
  closeFunc,
  selectedView,
  setSelectedView
}) => {
  const { t } = useTranslation(["Home", "Common"]);
  return (
    <Modal openStatus={openStatus} panelStyles="bg-primary w-[35vw] h-[250px]">
      <p className="text-center font-bold text-[20px] p-2 text-white">
        {t("ticket.views.title")}
      </p>
      <div className="flex justify-around mt-2">
        <button
          className={`border-2 rounded p-1 ${
            selectedView === "listByShows" ? "bg-white" : " text-white"
          }  `}
          onClick={() => {
            setSelectedView("listByShows");
            closeFunc();
          }}
        >
          {" "}
          {t("ticket.views.tabs.a")}
        </button>
        <button
          className={`border-2 rounded p-1 ${
            selectedView === "listByFilms" ? "bg-white" : " text-white"
          }  `}
          onClick={() => {
            setSelectedView("listByFilms");
            closeFunc();
          }}
        >
          {t("ticket.views.tabs.b")}
        </button>
        {/* <button
          className={`border-2 rounded p-1 ${
            selectedView === "listByScreens" ? "bg-white" : " text-white"
          }  `}
          onClick={() => {
            setSelectedView("listByScreens");
            closeFunc();
          }}
        >
          List by Screens
        </button> */}
      </div>
      <div className="flex justify-center mt-1">
        {" "}
        <button
          onClick={closeFunc}
          className="px-4 py-1 bg-blue-300 rounded mt-7"
        >
          {t("Common:button.close")}
        </button>
      </div>
    </Modal>
  );
};

export { ViewsModal };
