import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout as appLogout } from "../redux/slices";
import { tokenKey } from "../utils/constants";

const useLogout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem(tokenKey);
    dispatch(appLogout());
    navigate("/login");
  };

  return { logout };
};

export { useLogout };
