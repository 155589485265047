import React, { Component } from "react";

import i18next from "i18next";

class ErrorBoundary extends Component {
  state = {
    error: null
  };
  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="bg-primary">
          <div className="flex items-center justify-center w-9/12 min-h-screen py-16 m-auto">
            <div className="p-8 overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="pt-8 text-center border-gray-200">
                <h1 className="my-2 text-5xl font-bold text-purple-400">
                 {i18next.t("Common:error.header")}
                </h1>

                <hr />
                <p>
                  {" "}
                 {i18next.t("Common:error.label")}
                </p>
              </div>
              <div className="flex justify-center py-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={() => {
                    window.location.reload();
                    console.log("button clicked");
                  }}
                >
                   {i18next.t("Common:button.back")}
                </button>
              </div>
              {/* <ErrorBoundaryBtn /> */}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

