import axios from "axios";
import { redirectFunc } from "../../utils/redirect";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUS, tokenKey } from "../../utils/constants";

export const checkGiftcard = createAsyncThunk(
  "check_Giftcard",
  async ({ code }, { fulfillWithValue, rejectWithValue }) => {
    const token = localStorage.getItem(tokenKey);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}GiftCards/GetByCode?giftCardCode=${code}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      const voucherCodeData = response.data.data;

      return fulfillWithValue(voucherCodeData);
    } catch (err) {
      redirectFunc({ ...err });

      const error = { ...err };
      let message = "Unable to complete request";

      return rejectWithValue(
        error?.response.data.errors[0].errorMessages[0] || message
      );
    }
  }
);

const initialState = {
  error: "",
  status: STATUS.IDLE,
  giftcardData: {},
};

export const giftcardSlice = createSlice({
  name: "giftcards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkGiftcard.pending, (state) => {
        state.error = "";
        state.status = STATUS.PENDING;
      })
      .addCase(checkGiftcard.fulfilled, (state, action) => {
        state.error = "";
        state.status = STATUS.RESOLVED;
        state.giftcardData = action.payload;
      })
      .addCase(checkGiftcard.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUS.REJECTED;
      });
  },
});

export const giftcardReducer = giftcardSlice.reducer;
