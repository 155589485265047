import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  setShowKeyboard,
  setKeyboardConfiguration,
  rewardLumiUser,
  checkLumiGiftcard,
} from "../../redux/slices";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loyaltyDetailsSchema } from "../../utils/schemas";
import { Spinner } from "../UI/Spinner";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../context/KeyboardContext";

const LumiModal = ({ openStatus, closeFunc, setLumiData }) => {
  const dispatch = useDispatch();
  const { error, status } = useSelector((state) => state.giftcard);
  const { orderTotalAmount } = useSelector((state) => state.orders);
  const { lumiGiftcardStatus } = useSelector((state) => state.loyalty);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [giftcardCode, setGiftcardCode] = useState("");
  const [actionError, setActionError] = useState(true);
  const { t } = useTranslation(["Common", "OrderPayment"])
  const { setSetSharedInput } = useKeyboard()

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loyaltyDetailsSchema),
    defaultValues: {
      phoneNumber: "",
      email: "",
      firstName: "",
      lastName: "",
      giftcardCode: "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    setValue("phoneNumber", phoneNumber);
    setValue("email", email);
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("giftcardCode", giftcardCode);
  }, [phoneNumber, email, firstName, lastName, giftcardCode]);

  const onSubmit = (formData) => {
    if (formData.phoneNumber && formData.phoneNumber.length !== 11) {
      setActionError(t("OrderPayment:paymentchannel_modal.errors.phone"));
      return;
    }
    if (
      !formData.phoneNumber &&
      (formData.firstName || formData.lastName || formData.email)
    ) {
      setActionError(t("OrderPayment:paymentchannel_modal.errors.required.phone2"));
      return;
    } else {
      if (formData.giftcardCode) {
        dispatch(checkLumiGiftcard(formData.giftcardCode))
          .unwrap()
          .then((data) => {
            let codeValue = data?.naira_value;
            if (orderTotalAmount < data?.naira_value) {
              codeValue = orderTotalAmount;
            }
            setLumiData({
              phoneNumber: formData?.phoneNumber || null,
              email: formData?.email || null,
              firstName: formData?.firstName || null,
              lastName: formData?.lastName || null,
              giftcardCode: formData?.giftcardCode || null,
              giftcardCodeValue: codeValue,
            });
            closeFunc();
            return;
          })
          .catch((err) => {
            setActionError(err);
            return;
          });
      }
      if (!formData.giftcardCode) {
        setLumiData({
          phoneNumber: formData?.phoneNumber || null,
          email: formData?.email || null,
          firstName: formData?.firstName || null,
          lastName: formData?.lastName || null,
          giftcardCodeValue: 0,
        });
        closeFunc();
        return;
      }
    }
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold text-center leading-6 text-gray-900"
                >
                  {t("OrderPayment:header.bond")}
                </Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {actionError && (
                    <p className="text-red-500 text-center"> {actionError}</p>
                  )}
                  <div className="grid grid-cols-2 gap-x-5">
                    <div className="mt-2 deleteModal flex justify-center ">
                      <div>
                        <label className="text-center block ">
                          {t("Common:label.phone")}
                        </label>

                        <input
                          type="number"
                          value={phoneNumber}
                          placeholder={t("Common:keyboard.placeholder.customer.phone")}
                          className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "numeric",
                                sharedInput: phoneNumber,
                                placeHolder: t("Common:keyboard.placeholder.customer.phone"),
                                // setSharedInput: (x) => {
                                //   setPhoneNumber(x);
                                // },
                              })
                            );
                            setSetSharedInput(() => setPhoneNumber)
                          }}
                        />
                        {errors?.phoneNumber?.message && (
                          <p className="text-red-500 text-center">
                            {t("OrderPayment:paymentchannel_modal.errors.required.phone")}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 deleteModal flex justify-center">
                      <div>
                        <label className="text-center block">
                          {t("OrderPayment:paymentchannel_modal.label.giftcard_cd")}
                        </label>

                        <input
                          type="text"
                          value={giftcardCode}
                          placeholder={t("OrderPayment:paymentchannel_modal.placeholder.giftcard")}
                          className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "alphaNumeric",
                                sharedInput: giftcardCode,
                                placeHolder: t("OrderPayment:paymentchannel_modal.placeholder.giftcard"),
                                // setSharedInput: (x) => {
                                //   setGiftcardCode(x);
                                // },
                              })
                            );
                            setSetSharedInput(() => setGiftcardCode)
                          }}
                        />
                        {errors?.email?.message && (
                          <p className="text-red-500 text-center">
                            {errors?.email?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-5">
                    <div className="mt-2 deleteModal flex justify-center">
                      <div>
                        <label className="text-center block">{t("Common:label.name.first")}</label>

                        <input
                          type="text"
                          value={firstName}
                          placeholder={t("Common:keyboard.placeholder.customer.firstName")}
                          className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "alphaNumeric",
                                sharedInput: firstName,
                                placeHolder: t("Common:keyboard.placeholder.customer.firstName"),
                                // setSharedInput: (x) => {
                                //   setFirstName(x);
                                // },
                              })
                            );
                            setSetSharedInput(() => setFirstName)
                          }}
                        />
                        {errors?.firstName?.message && (
                          <p className="text-red-500 text-center">
                            {errors?.firstName?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-2 deleteModal flex justify-center">
                      <div>
                        <label className="text-center block">{t("Common:label.name.last")}</label>

                        <input
                          type="text"
                          value={lastName}
                          placeholder={t("Common:keyboard.placeholder.customer.lastName")}
                          className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "alphaNumeric",
                                sharedInput: lastName,
                                placeHolder: t("Common:keyboard.placeholder.customer.lastName"),
                                // setSharedInput: (x) => {
                                //   setLastName(x);
                                // },
                              })
                            );
                            setSetSharedInput(() => setLastName)
                          }}
                        />
                        {errors?.lastName?.message && (
                          <p className="text-red-500 text-center">
                            {errors?.lastName?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2 deleteModal flex justify-center">
                      <div>
                        <label className="text-center block">
                          {t("Common:label.mail")}
                        </label>

                        <input
                          type="email"
                          value={email}
                          placeholder={t("Common:keyboard.customer.email")}
                          className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "alphaNumeric",
                                sharedInput: email,
                                placeHolder: t("Common:keyboard.customer.email"),
                                // setSharedInput: (x) => {
                                //   setEmail(x);
                                // },
                              })
                            );
                            setSetSharedInput(() => setEmail)
                          }}
                        />
                        {errors?.email?.message && (
                          <p className="text-red-500 text-center">
                            {errors?.email?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 flex justify-center">
                    <button
                      type="button"
                      onClick={() => {
                        setValue("phoneNumber", "");
                        setValue("email", "");
                        setValue("firstName", "");
                        setValue("lastName", "");
                        setPhoneNumber("");
                        setEmail("");
                        setFirstName("");
                        setLastName("");
                        setActionError("");
                        closeFunc();
                      }}
                      className=" inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("Common:button.cancel")}
                    </button>
                    <button
                      type="submit"
                      className=" ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      <span>{t("Common:button.submit")} </span>
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                        status={lumiGiftcardStatus}
                      />
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default LumiModal;
