import React, { createContext, useContext, useState } from "react";

const KeyboardContext = createContext();

export const useKeyboard = () => {
  return useContext(KeyboardContext);
};

export const KeyboardProvider = ({ children }) => {
  const [setSharedInput, setSetSharedInput] = useState(() => () => {});

  return (
    <KeyboardContext.Provider value={{ setSharedInput, setSetSharedInput }}>
      {children}
    </KeyboardContext.Provider>
  );
};
