import { Spinner } from "./Spinner";
import { useTranslation } from "react-i18next";
import { setShowKeyboard, setKeyboardConfiguration } from "../../redux/slices";
import { useDispatch } from "react-redux";
import { useKeyboard } from "../../context/KeyboardContext";
export const SubmitBtn = ({ status, btnText, btnFunc, type = "button" }) => {
  const { t } = useTranslation(["Common", "Admin"]);
  return (
    <button
      type={type}
      onClick={btnFunc}
      className="inline-flex mr-2 border items-center justify-center rounded-md border-blue-900 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
    >
      <span> {btnText || t("button.submit")} </span>
      <Spinner
        className="!w-3 !h-3 mx-1 text-white fill-blue-800"
        status={status}
      />
    </button>
  );
};

export const CancelBtn = ({ btnText, btnFunc }) => {
  const { t } = useTranslation(["Common", "Admin"]);
  return (
    <button
      type="button"
      onClick={btnFunc}
      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
    >
      {btnText || t("button.cancel")}
    </button>
  );
};

export const InputField = ({
  inputLabel,
  placeholder,
  value,
  inputType = "text",
  keyboardType = "numeric",
  setValue,
  errorMsg,
  ...others
}) => {
  const dispatch = useDispatch();
  const { setSetSharedInput } = useKeyboard();
  return (
    <div className="mt-2 deleteModal">
      {inputLabel && (
        <>
          <label>{inputLabel}</label>
          <br />
        </>
      )}
      <input
        type={inputType}
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        className=" w-[100%]  h-[35px] border border-secondary rounded py-2 px-3"
        onClick={() => {
          dispatch(setShowKeyboard(true));
          dispatch(
            setKeyboardConfiguration({
              type: keyboardType,
              sharedInput: value,
              placeHolder: placeholder,
            })
          );
          setSetSharedInput(() => setValue);
        }}
        {...others}
      />
      {errorMsg && <p className="text-red-500 text-center">{errorMsg}</p>}
    </div>
  );
};

export const QtyModBtn = ({ clickFunc, btnTxt }) => {
  return (
    <button
      className="text-primary text-center bg-white font-extrabold rounded shadow-md p-2  flex items-center justify-center"
      type="button"
      onClick={clickFunc}
    >
      {" "}
      {btnTxt}
    </button>
  );
};
