const STATUS = {
  PENDING: "PENDING",
  IDLE: "IDLE",
  REJECTED: "REJECTED",
  RESOLVED: "RESOLVED",
};

const tokenKey = "Reach-pos-token";
const loginKey = "Reach-pos-lastLogin";
const redirectKey = "Reach-pos-redirect";
const userId = "Reach-pos-userid";
const sessionId = "Reach-pos-sessionid";
const userDataKey = "Reach-pos-userData";
const itemsKey = "Reach-pos-items";
const showTimesKey = "Reach-pos-showTimes";
const cinemaSettings = "Reach-pos-cinemaSettings";
const posLayoutKey = "Reach-pos-posLayout";
const currencyKey = "Reach-pos-currency";
const claimsKey = "Reach-pos-userClaims";
const customChannelsKey = "Reach-pos-customChannelsKey";

const timeString = (x) => {
  return new Date(x).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export {
  STATUS,
  tokenKey,
  loginKey,
  redirectKey,
  userId,
  sessionId,
  userDataKey,
  itemsKey,
  showTimesKey,
  cinemaSettings,
  timeString,
  posLayoutKey,
  currencyKey,
  claimsKey,
  customChannelsKey,
};
