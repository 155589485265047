import { useState } from "react";
import Booking from "./Booking";
import FindBooking from "./FindBooking";

const BookingModule = () => {
  const [module, setModule] = useState("findform");
  return (
    <>
      {module === "findform" && (
        <FindBooking
          func={() => {
            setModule("bookinginfo");
          }}
        />
      )}
      {module === "bookinginfo" && (
        <Booking
          func={() => {
            setModule("findform");
          }}
        />
      )}
    </>
  );
};
export default BookingModule;
