import { useTranslation } from "react-i18next";
import { numToCurrency } from "../../../utils/currencyHelper";
import { useSelector } from "react-redux";
const Ticket = ({ currentOrderId, methodsList = [] }) => {
  const { userData, cinemaSettings } = useSelector((state) => state.profile);
  const orders = useSelector((state) => state.orders);
  const { t } = useTranslation(["receipt"]);

  return (
    <div>
      {orders.orderItems.find((item) => {
        return item.showtimeId;
      })
        ? orders.orderItems
            .filter((item) => {
              return item.showtimeId;
            })
            .map((ticket, i) => {
              if (ticket.orderQuantity === 1) {
                return (
                  <div className="py-3 print:break-after-page" key={i}>
                    <p className="text-center font-bold text-[16px]">
                      {" "}
                      {userData.cinema}
                    </p>
                    <p className="text-center text-[16px] font-bold">
                      {ticket.name}
                    </p>
                    <div className="flex justify-between">
                      <p>
                        {t("labels.date")}: {ticket.showtimeDate}
                      </p>
                      <p>
                        {t("labels.time")}: {ticket.time}
                      </p>
                    </div>
                    <div className="flex items-center justify-between ">
                      <p>
                        {t("labels.price")}:{" "}
                        {ticket.totalPackagePrice
                          ? numToCurrency(ticket.totalPackagePrice, true)
                          : numToCurrency(ticket.price, true)}
                      </p>
                      <p className=" text-[16px] px-2 font-bold">
                        {" "}
                        {ticket.screen}
                      </p>
                    </div>

                    <p className="text-center text-[10px]">
                      {" "}
                      {`${t("labels.orderId")}: ${currentOrderId}`}
                    </p>
                    {ticket?.ticketName && (
                      <p className="text-center text-[10px]">
                        {`Ticket: ${ticket?.ticketName}`}
                      </p>
                    )}
                    <p className="text-center text-[10px]">
                      {t("labels.staff")}:{" "}
                      {`${userData.firstName}  ${userData.lastName}`}
                    </p>
                    {cinemaSettings?.seatAllocation &&
                      ticket?.seatIdentifier && (
                        <p className="text-center text-[10px] font-bold">
                          {" "}
                          {`${t("labels.seatNumber")}: ${
                            ticket?.seatIdentifier
                          }`}
                        </p>
                      )}
                    <p className="text-center text-[10px]">
                      {t("footer.appreciation")} {userData.cinema}
                    </p>
                    {cinemaSettings.includeCutOffTicket && (
                      <div>
                        <div className="border-dashed border-b-2 border-[black] my-4 px-2"></div>
                        <p className="  text-center font-bold text-[16px]">
                          {userData.cinema}
                        </p>
                        <p className="text-center text-[16px] font-bold">
                          {ticket.name}
                        </p>
                        <div className="flex justify-between">
                          <p>
                            {t("labels.date")}: {ticket.showtimeDate}
                          </p>
                          <p>
                            {t("labels.time")}: {ticket.time}
                          </p>
                        </div>
                        <p className=" text-[16px] font-bold text-center ">
                          {" "}
                          {t("receipt_type.header.merchant")}
                        </p>

                        <p className="text-center text-[10px]">
                          {" "}
                          {`${t("labels.orderId")}: ${currentOrderId}`}
                        </p>
                        {ticket?.ticketName && (
                          <p className="text-center text-[10px]">
                            {`Ticket: ${ticket?.ticketName}`}
                          </p>
                        )}
                        <p className="text-center text-[10px]">
                          {t("labels.staff")}:{" "}
                          {`${userData.firstName}  ${userData.lastName}`}
                        </p>
                        {cinemaSettings.includeChannelsOnTicket &&
                          methodsList?.length > 0 && (
                            <p className="py-2">
                              {`${
                                methodsList?.length > 1
                                  ? t("labels.paymentmethod.multiple")
                                  : t("labels.paymentmethod.single")
                              }`}
                              : {methodsList.toString()}
                            </p>
                          )}
                        {cinemaSettings?.seatAllocation &&
                          ticket?.seatIdentifier && (
                            <p className="text-center text-[10px] font-bold">
                              {" "}
                              {`${t("labels.seatNumber")}: ${
                                ticket?.seatIdentifier
                              }`}
                            </p>
                          )}
                        <div className="flex items-center justify-between ">
                          <p>
                            {t("labels.price")}:{" "}
                            {ticket.totalPackagePrice
                              ? numToCurrency(ticket.totalPackagePrice, true)
                              : numToCurrency(ticket.price, true)}
                          </p>
                          <p className=" text-[16px] px-2 font-bold">
                            {" "}
                            {ticket.screen}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                );
              } else {
                const ticketArray = Array.from({
                  length: ticket.orderQuantity,
                });

                return ticketArray.map((sticket, i) => {
                  return (
                    <div className="py-3 print:break-after-page" key={i}>
                      <p className="text-center font-bold text-[16px]">
                        {" "}
                        {userData.cinema}
                      </p>
                      <p className="text-center text-[16px] font-bold">
                        {ticket.name}
                      </p>
                      <div className="flex justify-between">
                        <p>
                          {t("labels.date")}: {ticket.showtimeDate}
                        </p>
                        <p>
                          {t("labels.time")}: {ticket.time}
                        </p>
                      </div>
                      <div className="flex items-center justify-between ">
                        <p>
                          {t("labels.price")}:{" "}
                          {ticket.totalPackagePrice
                            ? numToCurrency(ticket.totalPackagePrice, true)
                            : numToCurrency(ticket.price, true)}
                        </p>
                        <p className=" text-[16px] px-2 font-bold">
                          {" "}
                          {ticket.screen}
                        </p>
                      </div>

                      <p className="text-center text-[10px]">
                        {" "}
                        {`${t("labels.orderId")}: ${currentOrderId}`}
                      </p>
                      {ticket?.ticketName && (
                        <p className="text-center text-[10px]">
                          {`Ticket: ${ticket?.ticketName}`}
                        </p>
                      )}
                      <p className="text-center text-[10px]">
                        {t("labels.staff")}:{" "}
                        {`${userData.firstName}  ${userData.lastName}`}
                      </p>
                      {cinemaSettings?.seatAllocation &&
                        ticket?.seatIdentifier && (
                          <p className="text-center text-[10px] font-bold">
                            {" "}
                            {`${t("labels.seatNumber")}: ${
                              ticket?.seatIdentifier
                            }`}
                          </p>
                        )}
                      <p className="text-center text-[10px]">
                        {t("footer.appreciation")} {userData.cinema}
                      </p>
                      {cinemaSettings.includeCutOffTicket && (
                        <div>
                          <div className="border-dashed border-b-2 border-[black] my-4 px-2"></div>
                          <p className="  text-center font-bold text-[16px]">
                            {userData.cinema}
                          </p>
                          <p className="text-center text-[16px] font-bold">
                            {ticket.name}
                          </p>
                          <div className="flex justify-between">
                            <p>
                              {t("labels.date")}: {ticket.showtimeDate}
                            </p>
                            <p>
                              {t("labels.time")}: {ticket.time}
                            </p>
                          </div>
                          <p className=" text-[16px] font-bold text-center ">
                            {" "}
                            {t("receipt_type.header.merchant")}
                          </p>
                          <p className="text-center text-[10px]">
                            {" "}
                            {`${t("labels.orderId")}: ${currentOrderId}`}
                          </p>
                          {ticket?.ticketName && (
                            <p className="text-center text-[10px]">
                              {`Ticket: ${ticket?.ticketName}`}
                            </p>
                          )}
                          <p className="text-center text-[10px]">
                            {t("labels.staff")}:{" "}
                            {`${userData.firstName}  ${userData.lastName}`}
                          </p>
                          {cinemaSettings.includeChannelsOnTicket &&
                            methodsList?.length > 0 && (
                              <p className="py-2">
                                {`${
                                  methodsList?.length > 1
                                    ? t("labels.paymentmethod.multiple")
                                    : t("labels.paymentmethod.single")
                                }`}
                                : {methodsList.toString()}
                              </p>
                            )}
                          {cinemaSettings?.seatAllocation &&
                            ticket?.seatIdentifier && (
                              <p className="text-center text-[10px] font-bold">
                                {" "}
                                {`${t("labels.seatNumber")}: ${
                                  ticket?.seatIdentifier
                                }`}
                              </p>
                            )}
                          <div className="flex items-center justify-between ">
                            <p>
                              {t("labels.price")}:{" "}
                              {ticket.totalPackagePrice
                                ? numToCurrency(ticket.totalPackagePrice, true)
                                : numToCurrency(ticket.price, true)}
                            </p>
                            <p className=" text-[16px] px-2 font-bold">
                              {" "}
                              {ticket.screen}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                });
              }
            })
        : null}
    </div>
  );
};
export default Ticket;
