import React, { forwardRef } from "react";
import { formatDateTime } from "../../utils/dateHelpers";

const format = "yyyy-LL-dd HH:mm";

const DateTimeInput = forwardRef((props, ref) => {
  const {
    value,
    dateFormat = "",
    onChange,
    disabled,
    onClick,
    className,
    ...otherProps
  } = props;

  return (
    <input
      value={formatDateTime(new Date(value), dateFormat || format)}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      className={`block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg ${className}`}
      ref={ref}
      {...otherProps}
    />
  );
});

export { DateTimeInput, format };
