import { useState, useRef } from "react";
import Modal from "../../../UI/Modal";
import {
  SubmitBtn,
  CancelBtn,
  QtyModBtn,
  InputField,
} from "../../../UI/FormElements";
import { swapTicketSchema } from "../../../../utils/schemas";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { numToCurrency } from "../../../../utils/currencyHelper";
import {
  setShowKeyboard,
  setKeyboardConfiguration,
  swapTicket,
  clearOrder,
} from "../../../../redux/slices";
import { useKeyboard } from "../../../../context/KeyboardContext";
import { useDispatch, useSelector } from "react-redux";
import SwapTicketReprint from "./SwapTicketReprint";
import { useReactToPrint } from "react-to-print";
import PaymentChannels from "../../../orderModule/paymentChannelsModal";

const TicketList = ({
  openStatus,
  closeFunc,
  orderData,
  closeParent,
  isOnlineBooking = false,
}) => {
  const [balancePaymentChannels, setbalancePaymentChannels] = useState([]);
  const [showChannelsModal, setShowChannelsModal] = useState(false);
  const [preservedSwapTicketData, setPreservedSwapTicketData] = useState({});
  const { control, watch, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(swapTicketSchema),
    defaultValues: {},
  });
  const [errMsg, setErrMsg] = useState("");
  const { t } = useTranslation(["Admin", "Common"]);
  const { setSetSharedInput } = useKeyboard();
  const dispatch = useDispatch();
  const { orderItems, customChannels, swapStatus } = useSelector(
    (state) => state?.orders
  );
  const printRef = useRef();

  const updatedCloseFunc = () => {
    dispatch(clearOrder());
    closeFunc();
    closeParent();
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      updatedCloseFunc();
    },
  });

  const newTicket = orderItems?.filter((item) => {
    return item?.showtimeId;
  });

  const { fields } = useFieldArray({
    control,
    name: "refundPaymentChannels",
  });

  const configureForm = (ticketData) => {
    const channels = orderData?.paymentChannels?.map((channel) => {
      return {
        channelId: channel?.channelId,
        amount: 0,
        channel: channel?.channel,
        maxAmount: channel?.amount,
      };
    });

    reset({
      ticketSaleId: ticketData.id,
      quantity: 0,
      maxQuantity: ticketData.quantity,
      refundPaymentChannels: channels,
      ticketPrice: ticketData.ticketPrice,
    });
  };

  const formData = watch();

  const oldTicketSum = formData?.quantity * formData?.ticketPrice;
  const newTicketSum =
    +newTicket[0]?.orderQuantity *
    (+newTicket[0]?.priceInPackage || +newTicket[0]?.price);

  const submitForm = (data) => {
    //this helps to only select channels through which an amount has been entered for a refund
    const formattedRefundChannels = data?.refundPaymentChannels?.filter((x) => {
      return x?.amount > 0;
    })
      ? data?.refundPaymentChannels
          ?.filter((x) => {
            return x?.amount > 0;
          })
          ?.map((x) => {
            //this if check helps to format for cases where its a custom channel..Similar to order creation,
            //it makes the channelId the "others" channelId and puts the actual channelId as otherChannelId
            if (
              customChannels?.find((i) => {
                return i.id === x?.channelId;
              })
            ) {
              return {
                channelId: "channel-2d1980",
                otherChannelId: x?.channelId,
                amount: x?.amount,
              };
            } else {
              return { channelId: x?.channelId, amount: x?.amount };
            }
          })
      : [];

    //calculating what the total value spread across the refund channels is
    const formattedRefundChannelsTotal = formattedRefundChannels
      ?.map((x) => {
        return x?.amount;
      })
      ?.reduce((a, b) => a + b, 0);
    //check for quantity of ticket to be swapped
    if (data.quantity === 0) {
      setErrMsg("Enter quantity of ticket to be swapped");
      return;
    }
    //check for when quantity of replacement is not equal to new quantity in cart
    if (newTicket[0]?.orderQuantity !== data.quantity) {
      setErrMsg(
        "Ensure Quantity of tickets to be swapped is equal to quantity of replacement tickets in the cart"
      );
      return;
    }
    //check for when refund channels does not add up to refunded amount
    if (
      oldTicketSum > newTicketSum &&
      formattedRefundChannelsTotal !== oldTicketSum - newTicketSum
    ) {
      setErrMsg(
        "Ensure the total Amount to be refunded is captured in the Refund Information channels"
      );
      return;
    }

    //did this because I was too lazy don't judge me abeg just go with the flow...Tula Gbolaga
    const singleTicketInstance = newTicket[0];

    const newTicketSale = {
      quantity: singleTicketInstance.orderQuantity,
      voucherCode: singleTicketInstance.voucherCode || null,
      packageTicketId: singleTicketInstance.packageTicketId || null,
      isLoyaltyTicket: false,
      amount: !singleTicketInstance.priceInPackage
        ? +singleTicketInstance.orderQuantity * +singleTicketInstance.price
        : +singleTicketInstance.orderQuantity *
          +singleTicketInstance.priceInPackage,
      showtimeId: singleTicketInstance.showtimeId,
      ticketId: singleTicketInstance.ticketId,
      priceCardTicketId: singleTicketInstance.priceCardTicketId,
      ticketPrice:
        singleTicketInstance.priceInPackage || singleTicketInstance.price,
      seatNumber: singleTicketInstance?.seatNumber,
      seatLayoutId: singleTicketInstance?.seatLayoutId,
      rowName: singleTicketInstance?.rowName,
    };

    const swapTicketData = {
      reason: data.reason || "",
      ticketSaleId: data.ticketSaleId,
      quantity: data.quantity,
      newTicketSale,
      paymentChannels: [],
      refundPaymentChannels: [],
      //   source: "source-d9c05d",
    };

    setErrMsg("");

    //This caters for when the swap does not require a refund or added payment....It just perform swap, prints and resets the cart
    if (newTicketSum === oldTicketSum) {
      dispatch(swapTicket(swapTicketData))
        .unwrap()
        .then(() => {
          setErrMsg("");
          if (!isOnlineBooking) {
            handlePrint();
          } else {
            updatedCloseFunc();
          }
          return;
        })
        .catch((data) => {
          setErrMsg(data);
        });
    }

    //This caters to when the swap requires a refund
    //It captures the refund payment channels then does the swap flow
    if (oldTicketSum > newTicketSum) {
      dispatch(
        swapTicket({
          ...swapTicketData,
          refundPaymentChannels: formattedRefundChannels,
        })
      )
        .unwrap()
        .then(() => {
          setErrMsg("");
          if (!isOnlineBooking) {
            handlePrint();
          } else {
            updatedCloseFunc();
          }
          return;
        })
        .catch((data) => {
          setErrMsg(data);
        });
    }

    //This caters for when the swap requires additional payment
    //It captures the channels for additional payment then does the swap flow
    if (newTicketSum > oldTicketSum) {
      setShowChannelsModal(true);
      setPreservedSwapTicketData(swapTicketData);
    }
  };

  const channelsModalCallBack = () => {
    dispatch(
      swapTicket({
        ...preservedSwapTicketData,
        paymentChannels: balancePaymentChannels,
      })
    )
      .unwrap()
      .then(() => {
        setErrMsg("");
        if (!isOnlineBooking) {
          handlePrint();
        } else {
          updatedCloseFunc();
        }
        return;
      })
      .catch((data) => {
        setErrMsg(data);
      });
  };

  return (
    <Modal
      openStatus={openStatus}
      closeFunc={closeFunc}
      panelStyles="bg-white p-[20px] min-h-[250px] min-w-[550px]"
    >
      <p className="font-bold text-center py-2">
        {!formData?.ticketSaleId
          ? "Select Ticket"
          : "Enter Quantity and Other Information where applicable"}
      </p>
      <form onSubmit={handleSubmit(submitForm)}>
        {orderData?.ticketSales
          ?.filter((x) => {
            if (formData?.ticketSaleId) {
              return x.id === formData?.ticketSaleId;
            } else {
              return x;
            }
          })
          .map((data) => {
            return (
              <div
                className={"flex justify-between border-2 rounded p-2 mb-2"}
                onClick={() => {
                  !formData?.ticketSaleId && configureForm(data);
                }}
              >
                <div>
                  <div className="flex">
                    <p className="mr-2 font-bold truncate max-w-[250px]">
                      {data?.film}
                    </p>
                    <span>{data?.screen}</span>
                  </div>
                  <p>
                    <span className="mr-2">
                      {new Date(data?.startTime).toDateString()}
                    </span>
                    <span>
                      {new Date(data?.startTime).toLocaleTimeString()}
                    </span>
                  </p>
                </div>
                {formData?.ticketSaleId && (
                  <div className="flex items-center justify-between">
                    <QtyModBtn
                      btnTxt={"-"}
                      clickFunc={() => {
                        if (watch(`quantity`) > 0) {
                          setValue(`quantity`, watch(`quantity`) - 1);
                        }
                      }}
                    />
                    <span className="mx-3">{watch("quantity")}</span>
                    <QtyModBtn
                      btnTxt={"+"}
                      clickFunc={() => {
                        if (watch(`quantity`) < watch("maxQuantity")) {
                          setValue(`quantity`, watch(`quantity`) + 1);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        {formData?.ticketSaleId && (
          <>
            {formData?.quantity > 0 && oldTicketSum > newTicketSum && (
              <div className="border-2 rounded p-2">
                <p className="text-center bold">Refund Information</p>
                <p>
                  Amount to Refund: {numToCurrency(oldTicketSum - newTicketSum)}
                </p>
                {formData.refundPaymentChannels && (
                  <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
                    <div className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]">
                      <span className=" text-[#C96FCC] font-bold">
                        {t("modals.order.refund.partial.columns.a")}
                      </span>{" "}
                      <span className=" text-[#C96FCC] font-bold">
                        {t("modals.order.refund.partial.columns.b")}
                      </span>
                      <span className=" text-[#C96FCC] font-bold">
                        {t("modals.order.refund.partial.columns.c")}
                      </span>
                    </div>

                    {fields?.map((field, index) => {
                      return (
                        <div
                          className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                          key={index}
                        >
                          <p>{field.channel}</p>
                          <p>{numToCurrency(field.maxAmount)}</p>
                          <InputField
                            inputType={"number"}
                            value={watch(
                              `refundPaymentChannels.${index}.amount`
                            )}
                            keyboardType="numeric"
                            setValue={(val) =>
                              setValue(
                                `refundPaymentChannels.${index}.amount`,
                                val
                              )
                            }
                            max={field?.maxAmount}
                            min={0}
                            className="max-w-[80px] p-2 rounded bg-app-purple-10 border-2"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            <div className="mb-2">
              <label htmlFor={"reason"} className="block mb-2">
                {" "}
                {t("modals.order.refund.label")}
              </label>
              <textarea
                id={"reason"}
                className="w-full border-2 p-2 rounded bg-app-purple-10"
                rows={4}
                placeholder={"Enter reason for Swap"}
                value={watch(`reason`)}
                onClick={() => {
                  dispatch(setShowKeyboard(true));
                  dispatch(
                    setKeyboardConfiguration({
                      type: "alphaNumeric",
                      sharedInput: watch(`reason`),
                      placeHolder: "Enter reason for Swap",
                    })
                  );
                  setSetSharedInput(() => (val) => setValue(`reason`, val));
                }}
              ></textarea>
            </div>
            {formData?.quantity > 0 && newTicketSum > oldTicketSum && (
              <p>
                Amount to Balance: {numToCurrency(newTicketSum - oldTicketSum)}
              </p>
            )}
          </>
        )}
        {errMsg && (
          <div className="flex justify-center">
            {" "}
            <p className="text-red-500 text-center w-[400px]">{errMsg}</p>
          </div>
        )}
        <div className="flex justify-center my-3">
          {formData?.ticketSaleId && (
            <SubmitBtn
              btnText={"Proceed"}
              type={"submit"}
              status={swapStatus}
            />
          )}
          <CancelBtn
            btnFunc={() => {
              closeFunc();
              setErrMsg("");
              reset({});
            }}
          />
        </div>
      </form>
      <div className="hidden">
        <SwapTicketReprint ref={printRef} currentOrderId={orderData?.id} />
      </div>
      {showChannelsModal && (
        <PaymentChannels
          openStatus={showChannelsModal}
          closeFunc={() => {
            setShowChannelsModal(false);
          }}
          swapTicketUseCase={true}
          swapTicketPaymentTotal={newTicketSum - oldTicketSum}
          swapTicketCallBackFunc={channelsModalCallBack}
          setChannelsForSwapTicket={setbalancePaymentChannels}
        />
      )}
    </Modal>
  );
};
export default TicketList;
