import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  authenticateAdmin2,
  createAdminAction,
  setShowKeyboard,
  setKeyboardConfiguration
} from "../../../redux/slices";
import NumPad from "react-numpad";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../../utils/schemas";
import SalesTable from "./SalesTable";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../context/KeyboardContext";

const AllSalesByUser = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["Common", "POS"]);
  const [showError, setShowError] = useState(false);
  const [validated, setValidated] = useState(false);
  const { adminAuthError2 } = useSelector(state => state.items);
  const [userNumber, setUserNumber] = useState("");
  const [pin, setPin] = useState("");
  const dispatch = useDispatch();
  const { setSetSharedInput } = useKeyboard()

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      userNumber: "",
      pin: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    setValue("userNumber", userNumber);
    setValue("pin", pin);
  }, [userNumber, pin]);

  const onSubmit = data => {
    dispatch(authenticateAdmin2(data))
      .unwrap()
      .then(() => {
        dispatch(
          createAdminAction({
            doneById: data.userNumber,
            details: null,
            action: 12
          })
        );
        setValue("userNumber", "");
        setValue("pin", "");
        setPin("");
        setUserNumber("");
        setShowError(false);
        setValidated(true);
      })
      .catch(err => {
        setShowError(true);
      });
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={` transform overflow-hidden  rounded-2xl   text-left align-middle shadow-xl transition-all ${
                  validated ? "w-[80vw] h-[95vh] bg-secondary" : " p-4 bg-white"
                }`}
              >
                {/* <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Delete Item
                </Dialog.Title> */}
                {!validated && (
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className=" flex justify-center"
                  >
                    <div className="">
                      <p className=" font-bold text-center text-[20px] px-[20px]">
                        {" "}
                        {t("POS:sales.login")}
                      </p>
                      <div className="mt-2 deleteModal">
                        {adminAuthError2 && showError && (
                          <p className="text-red-500 text-center">
                            {" "}
                            {adminAuthError2}
                          </p>
                        )}
                        <label>{t("admin_form.label.user")}</label>
                        <br />
                        <input
                          type="text"
                          value={userNumber}
                          autoComplete="off"
                          placeholder={t("admin_form.placeholder.user")}
                          className=" w-[100%]  h-[35px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "numeric",
                                sharedInput: userNumber,
                                placeHolder: t("keyboard.placeholder.user"),
                                // setSharedInput: x => {
                                //   setUserNumber(x);
                                // }
                              })
                            );
                            setSetSharedInput(() => setUserNumber)
                          }}
                        />
                        {errors?.userNumber?.message && (
                          <p className="text-red-500 text-center">
                            {errors?.userNumber?.message}
                          </p>
                        )}
                      </div>
                      <div className="mt-2 deleteModal">
                        <label>{t("admin_form.label.pin")}</label> <br />
                        <input
                          type="password"
                          value={pin}
                          autoComplete="new-password"
                          placeholder={t("admin_form.placeholder.pin")}
                          className=" w-[100%] h-[35px] border border-secondary rounded py-2 px-3"
                          onClick={() => {
                            dispatch(setShowKeyboard(true));
                            dispatch(
                              setKeyboardConfiguration({
                                type: "numericPin",
                                sharedInput: pin,
                                placeHolder: t("keyboard.placeholder.pin"),
                                // setSharedInput: x => {
                                //   setPin(x);
                                // }
                              })
                            );
                            setSetSharedInput(() => setPin)
                          }}
                        />
                        {errors?.pin?.message && (
                          <p className="text-red-500 text-center">
                            {errors.pin.message}
                          </p>
                        )}
                      </div>
                      {/* {showError && <p className="text-red-700">Invalid pin</p>} */}

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="inline-flex mr-2 border justify-center rounded-md border-blue-900 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        >
                          {t("button.submit")}
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => {
                            setShowError(false);
                            setValue("userNumber", "");
                            setValue("pin", "");
                            setPin("");
                            setUserNumber("");
                            closeFunc();
                          }}
                        >
                          {t("button.cancel")}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {validated && (
                  <SalesTable
                    closeFunc={() => {
                      setValidated(false);
                      closeFunc();
                    }}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default AllSalesByUser;
