import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import PosHome from "./pages/PosHome";
import Login from "./pages/Login";
import { OfflineWarning } from "./components/UI/OfflineWarning";
import ErrorBoundary from "./components/ErrorBoundary";
import "./i18n";
import ChangePin from "./pages/ChangePin";

function App() {
  return (
    <>
      <div>
        <OfflineWarning />
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<PosHome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<ChangePin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default App;
