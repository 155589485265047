import { useEffect } from "react";
import { getTotals } from "../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { numToCurrency } from "../../utils/currencyHelper";
import { useTranslation } from "react-i18next";

const OrderTotal = () => {
  const orders = useSelector(state => state.orders);
  const dispatch = useDispatch();
  const { t } = useTranslation(["OrderTotal"]);

  useEffect(() => {
    dispatch(getTotals());
  }, [orders, dispatch]);
  return (
    <div className=" h-[5%] flex justify-between p-1">
      <p>{t("order_total")}</p>
      <p>{` ${numToCurrency(orders.orderTotalAmount, true)}`}</p>
    </div>
  );
};
export default OrderTotal;
