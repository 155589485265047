import { useEffect, useState } from "react";
import { addToOrder } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import TicketImg from "../../../assets/images/yellowticket.png";
import { useTranslation } from "react-i18next";

const Booking = ({ func }) => {
  const { t } = useTranslation(["Booking", "Common"]);
  const [redeemTicket, setRedeemTicket] = useState(false);
  const { bookingDetails } = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const formattedBookingData = [
    ...bookingDetails.order.itemSales,
    ...bookingDetails.order.ticketSales,
  ].map((x) => {
    return {
      id: x.id,
      orderQuantity: x.quantity,
      price: 0,
      name: x.item || x.film,
      bookedOrder: true,
      showtimeId: x.showtimeId || false,
      screen: x.screen || null,
      ticketName: x.ticketName || "",
      seatIdentifier:
        x?.rowName || x?.seatNumber ? x.rowName + x.seatNumber : null,
      showtimeDate: new Date(x.startTime).toDateString(),
      time:
        ` ${new Date(x.startTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${new Date(x.endTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })} ` || null,
    };
  });

  console.log(formattedBookingData);
  useEffect(() => {
    if (Object.keys(bookingDetails).length < 1) {
      func();
    }
  }, [bookingDetails]);
  return (
    <div className="bg-primary h-[100%] px-[100px] py-5">
      {!redeemTicket ? (
        <>
          <div className="flex items-center">
            <p className="text-white w-[30%] text-xl">{t("title.code")}</p>
            <div className="bg-[#00AB66] text-white p-3 rounded border-0  w-[70%] text-center ">
              {bookingDetails.id}
            </div>
          </div>
          <p className="text-center text-white text-xl mt-[100px]"></p>
        </>
      ) : (
        <p className=" text-[#fee27d] text-[30px] text-center py-[50px]">
          {" "}
          {t("success.ticket")}
        </p>
      )}
      <div className="flex justify-center">
        {" "}
        <div
          className="w-[400px] h-[200px] bg-no-repeat bg-contain bg-center text-center font-bold "
          style={{
            backgroundImage: `url(${TicketImg})`,
          }}
        >
          {" "}
          <p className="pt-5 "></p>
          <p className="text-primary text-[40px] pt-5">
            <span
              dangerouslySetInnerHTML={{
                __html: t("success.booking", { sep: "<br />" }),
              }}
            ></span>
          </p>
          <p className="text-white pt-5"></p>
        </div>
      </div>
      {!redeemTicket ? (
        <div className="flex justify-end mt-[20px]">
          <button
            className="bg-secondary text-white p-2 rounded"
            type="button"
            onClick={() => {
              setRedeemTicket(true);
              formattedBookingData.forEach((x) => {
                dispatch(addToOrder(x));
              });
              // dispatch(
              //   addToOrder({ id: "rdtick", title: "Tenet", price: 10000 })
              // );
            }}
          >
            {t("button.code.submit")}
          </button>
        </div>
      ) : (
        <div className="flex justify-center mt-[20px]">
          {" "}
          <button
            className="bg-secondary text-white p-2 rounded"
            type="button"
            onClick={() => {
              func();
              setRedeemTicket(false);
            }}
          >
            {t("button.code.other")}
          </button>{" "}
        </div>
      )}
    </div>
  );
};
export default Booking;
