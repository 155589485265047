import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  setShowKeyboard,
  setKeyboardConfiguration,
  checkPromoCode,
} from "../../redux/slices";
import { Spinner } from "../UI/Spinner";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../context/KeyboardContext";

const PromoCodeModal = ({ openStatus, closeFunc, setPromoCodeData }) => {
  const dispatch = useDispatch();
  const { error, status } = useSelector((state) => state.promoCode);
  const { userData } = useSelector((state) => state.profile);
  const { orderTotalAmount } = useSelector((state) => state.orders);
  const [promoCode, setPromoCode] = useState("");
  const { t } = useTranslation(["Common", "OrderPayment"])
  const { setSetSharedInput } = useKeyboard()

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(checkPromoCode({ promoCode, cinemaId: userData?.cinemaId }))
      .unwrap()
      .then((data) => {
        // setPromoCodeData({ amount: 0, id: 1 });
        // closeFunc();
        let promoValue = 0;
        if (data.type === "Amount") {
          if (orderTotalAmount < data.value) {
            promoValue = orderTotalAmount;
          } else {
            promoValue = data.value;
          }
        }
        if (data.type === "Percentage") {
          promoValue = [data.value / 100] * orderTotalAmount;
        }

        setPromoCodeData({ amount: promoValue, id: data.id });
        closeFunc();
      })
      .catch((err) => console.log(error));
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <form onSubmit={onSubmit}>
                  {error && (
                    <p className="text-red-500 text-center"> {error}</p>
                  )}
                  <div className="mt-2 deleteModal flex justify-center">
                    <div>
                      <label className="text-center block">{t("OrderPayment:paymentchannel_modal.label.promocd")}</label>
                      <br />
                      <input
                        type="text"
                        value={promoCode}
                        placeholder={t("OrderPayment:paymentchannel_modal.placeholder.promo")}
                        className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "alphaNumeric",
                              sharedInput: promoCode,
                              placeHolder:t("Common:keyboard.placeholder.promo"),
                              // setSharedInput: (x) => {
                              //   setPromoCode(x);
                              // },
                            })
                          );
                          setSetSharedInput(() => setPromoCode)
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-5 flex justify-center">
                    <button
                      type="submit"
                      className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      <span>{t("Common:button.submit")} </span>
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                        status={status}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPromoCode("");
                        closeFunc();
                      }}
                      className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default PromoCodeModal;
