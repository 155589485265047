import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import NumPad from "react-numpad";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderQuantity, changeQuantityForPkg } from "../../redux/slices";
import ConcessionModal from "../posModule/home/concessionModal";
import {useTranslation} from "react-i18next";

const OrderItemQuantityModal = ({ openStatus, closeFunc, itemData }) => {
  const { t } = useTranslation(["OrderList", "Common"]);

  const [quantity, setquantity] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [subItems, setSubItems] = useState([]);
  const [disableClose, setDisableClose] = useState(false);
  const dispatch = useDispatch();
  const { orderItems } = useSelector((state) => state.orders);

  const quantityChangeHandler = (amount) => {
    setquantity(amount);
  };

  const packageHandlerFunc = () => {
    if (quantity <= itemData.orderQuantity) {
      setQuantityError(
        t('quantity.errors.higher')
      );
      return;
    }
    // in cases where it is a packageItem
    if (itemData.packageItemData) {
      const item = itemData.packageItemData;
      //modify the package itself
      const packageIndex = orderItems.findIndex((x) => {
        return x.id === item.id;
      });
      dispatch(changeQuantityForPkg({ index: packageIndex, quantity }));

      //modify single items in the package
      if (item?.items?.length > 0) {
        item?.items?.forEach((x) => {
          let itemIndex = orderItems.findIndex((y) => {
            return y.id === x.itemId && y.packageId === item.id;
          });
          dispatch(
            changeQuantityForPkg({
              index: itemIndex,
              quantity: quantity * x.quantity,
            })
          );
        });
      }

      //modify parent items in the package
      if (item?.parentItems?.length > 0) {
        const newArray = [];
        const arrayOfParentArrays1 = item.parentItems?.filter((data) => {
          return data.quantity === 1;
        });

        arrayOfParentArrays1.forEach((parent) => {
          const quantityArray = Array.from({
            length: quantity - itemData.orderQuantity,
          });

          quantityArray.forEach((_, i) => {
            newArray.push(parent);
          });
        });

        const arrayOfParentArrays2 = item.parentItems?.filter((data) => {
          return data.quantity !== 1;
        });

        arrayOfParentArrays2.forEach((parent) => {
          const quantityArray = Array.from({
            length: parent.quantity * (quantity - itemData.orderQuantity),
          });

          quantityArray.forEach((_, i) => {
            newArray.push(parent);
          });
        });

        const finalArray = newArray?.map((p) => {
          return p?.parent?.itemList?.map((item) => {
            return { ...item, price: 0, priceInPackage: item.price };
          });
        });

        setIsOpen(true);
        setDisableClose(true);
        setSubItems(finalArray);
      } else {
        setQuantityError("");
        closeFunc();
        setquantity("");
      }
    }
    //in cases where its a package Ticket
    if (itemData.packageTicketData) {
      const item = itemData.packageTicketData;
      //modify the package itself
      const ticketPackageIndex = orderItems.findIndex((x) => {
        return x.id === item?.pricecard?.id && x.pkgShowtimeId === item.item.id;
      });
      dispatch(changeQuantityForPkg({ index: ticketPackageIndex, quantity }));

      //modification for the ticket
      if (item?.pricecard?.ticketPackage?.tickets?.length > 0) {
        item?.pricecard?.ticketPackage?.tickets?.forEach((x) => {
          let ticketIndex = orderItems.findIndex((y) => {
            return y.id === x.ticketId && y.showtimeId === item.item.id;
          });
          dispatch(
            changeQuantityForPkg({
              index: ticketIndex,
              quantity: x?.quantity * quantity,
            })
          );
        });
      }
      //modification for the single items
      if (item?.pricecard?.ticketPackage?.items?.length > 0) {
        item?.pricecard?.ticketPackage?.items?.forEach((x) => {
          let itemIndex = orderItems.findIndex((y) => {
            return y.id === x.itemId && y.pkgShowtimeId === item.item.id;
          });
          dispatch(
            changeQuantityForPkg({
              index: itemIndex,
              quantity: x?.quantity * quantity,
            })
          );
        });
      }
      //modification for the parent items

      if (item?.pricecard?.ticketPackage?.itemParents.length > 0) {
        const newArray = [];
        //we first get the parent items with quantity of one
        let parentItemArray = item?.pricecard?.ticketPackage?.itemParents.filter(
          (data) => {
            return data.quantity === 1;
          }
        );

        parentItemArray.forEach((parent) => {
          const quantityArray = Array.from({
            length: quantity - itemData.orderQuantity,
          });

          quantityArray.forEach((_, i) => {
            newArray.push(parent);
          });
        });

        //then we get the parent items where the quantity is more than one
        const parentItemArray2 = item?.pricecard?.ticketPackage?.itemParents.filter(
          (data) => {
            return data.quantity !== 1;
          }
        );

        //then we try to create multiple instances of parentItemArray2 based on the quantity

        parentItemArray2.forEach((parent) => {
          const quantityArray = Array.from({
            length: parent.quantity * (quantity - itemData.orderQuantity),
          });

          quantityArray.forEach((_, i) => {
            newArray.push(parent);
          });
        });

        const finalArray = newArray.map((p) => {
          return p.items.map((singleItem) => {
            return {
              ...singleItem,
              price: 0,
              priceInPackage: singleItem.price,
              id: singleItem.itemId,
              packageTicketId: item?.pricecard?.ticketId,
              itemParentId: p.id,
              pkgShowtimeId: item?.item?.id,
              //this id would serve as a unique identifier for all the children of a package ticket so if any of them gets deleted
              //the siblings would be deleted too
              idForDelete: singleItem.id,
            };
          });
        });

        setIsOpen(true);
        setDisableClose(true);
        setSubItems(finalArray);
      } else {
        setQuantityError("");
        closeFunc();
        setquantity("");
      }
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (itemData?.availableQuantity && itemData?.availableQuantity < quantity) {
      setQuantityError(
        t("quantity.errors.more", {quantity, available: itemData?.availableQuantity})
      );
      return;
    }
    if (+quantity === 0) {
      setQuantityError(t("quantity.errors.invalid"));
    } else {
      if (itemData.packageItemData || itemData.packageTicketData) {
        packageHandlerFunc();
      } else {
        dispatch(
          changeOrderQuantity({
            quantity: +quantity,
            id: itemData?.id,
            price: itemData?.price,
          })
        );
        setQuantityError("");
        closeFunc();
        setquantity("");
      }
    }
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {t("quantity.prompt.quantity")}
                </Dialog.Title>
                <form onSubmit={onSubmit}>
                  {quantityError && (
                    <p className=" text-red-600 text-center">{quantityError}</p>
                  )}
                  <div className="mt-2 deleteModal flex justify-center">
                    <NumPad.Number
                      placeholder= {t("quantity.placeholder.quantity")}
                      value={quantity}
                      onChange={quantityChangeHandler}
                    />
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent mr-4 bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                    >
                      {t("Common:button.submit")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
                      onClick={() => {
                        closeFunc();
                        setquantity("");
                        setQuantityError("");
                      }}
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
                <ConcessionModal
                  openStatus={isOpen}
                  closeFunc={() => {
                    setIsOpen(false);
                    setDisableClose(false);
                    setQuantityError("");
                    closeFunc();
                    setquantity("");
                  }}
                  data={subItems}
                  disableClose={disableClose}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default OrderItemQuantityModal;
