import React, { useEffect, useState } from "react";
import Modal from "./UI/Modal";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { resetPinInit, resetPinSchema } from "../utils/schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  setKeyboardConfiguration,
  setResetPwd,
  setShowKeyboard,
} from "../redux/slices";
import { useKeyboard } from "../context/KeyboardContext";
import { Spinner } from "./UI/Spinner";
import { STATUS } from "../utils/constants";

const ForgotPwdModal = ({ openStatus, setForgotPwd }) => {
  // const { t } = useTranslation(["Common", "POS"]);
  const [authToken, setAuthToken] = useState("");
  const [forgotPin, setForgotPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const dispatch = useDispatch();
  const { resetPwdError, resetPwdStatus } = useSelector(
    (state) => state.profile
  );
  const { setSetSharedInput } = useKeyboard();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPinSchema),
    defaultValues: resetPinInit,
    mode: "onChange",
  });

  useEffect(() => {
    setValue("authToken", authToken);
    setValue("newPin", forgotPin);
    setValue("confirmPin", confirmPin);
  }, [authToken, confirmPin, forgotPin]);
  const handleResetPin = (values) => {
    if (resetPwdStatus === STATUS.RESOLVED) {
      dispatch(setResetPwd())
      setForgotPwd(false);
    } else {
      
      dispatch(
        resetPassword({ authToken: values.authToken, newPin: values.newPin })
      )
        .unwrap()
        .then((res) => {
          
        })
        .catch((err) => console.log({ err }));
    }
  };

  return (
    <Modal
      openStatus={openStatus}
      panelStyles="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
    >
      <Dialog.Title
        as="h3"
        className="text-lg mb-4 text-center font-semibold leading-6 text-gray-900 "
      >
        An email has been sent to reset your password. Enter the details below
      </Dialog.Title>
      {resetPwdError && (
        <p className="text-red-500 text-center">{resetPwdError}</p>
      )}
      {resetPwdStatus===STATUS.RESOLVED?<p className="text-green-500 text-center">Your password has been reset successfully</p> :null}
      <form onSubmit={handleSubmit(handleResetPin)} autoComplete="off">
        <div className="">
          <div className="mt-2 deleteModal">
            <label>Auth pin</label>
            <br />
            <input
              type="text"
              value={authToken}
              placeholder={"Enter auth pin"}
              className=" w-[100%]  h-[35px] border border-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numeric",
                    sharedInput: authToken,
                    placeHolder: "Enter auth pin",
                    // setSharedInput: (x) => {
                    //   setAuthToken(x);
                    // },
                  })
                );
                setSetSharedInput(() => setAuthToken);
              }}
            />
            {errors?.authToken?.message && (
              <p className="text-red-500 text-center">
                {errors?.authToken?.message}
              </p>
            )}
          </div>
        </div>
        <div className="">
          <div className="mt-2 deleteModal">
            <label> New Pin</label> <br />
            <input
              type="password"
              value={forgotPin}
              autoComplete="new-password"
              placeholder="Enter new pin"
              className=" w-[100%] h-[35px] border border-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numericPin",
                    sharedInput: forgotPin,
                    placeHolder: "Enter new pin",
                    // setSharedInput: (x) => {
                    //   setForgotPin(x);
                    // },
                  })
                );
                setSetSharedInput(() => setForgotPin);
              }}
            />
            {errors?.newPin?.message && (
              <p className="text-red-500 text-center">
                {errors.newPin.message}
              </p>
            )}
          </div>
        </div>
        <div className="">
          <div className="mt-2 deleteModal">
            <label> Confirm Pin</label> <br />
            <input
              type="password"
              value={confirmPin}
              autoComplete="new-password"
              placeholder="Confirm new pin"
              className=" w-[100%] h-[35px] border border-secondary rounded py-2 px-3"
              onClick={() => {
                dispatch(setShowKeyboard(true));
                dispatch(
                  setKeyboardConfiguration({
                    type: "numericPin",
                    sharedInput: confirmPin,
                    placeHolder: "Confirm new pin",
                    // setSharedInput: (x) => {
                    //   setConfirmPin(x);
                    // },
                  })
                );
                setSetSharedInput(() => setConfirmPin);
              }}
            />
            {errors?.confirmPin?.message && (
              <p className="text-red-500 text-center">
                {errors.confirmPin.message}
              </p>
            )}
          </div>
        </div>

        <div className="mt-4 flex justify-center">
          <button
            type="submit"
            className=" mr-3 inline-flex justify-center items-center rounded-md border border-transparent bg-app-secondary2 text-white px-4 py-2 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            <span>{resetPwdStatus===STATUS.RESOLVED?'Ok':'Reset'}</span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-pink-400"
              status={resetPwdStatus}
            />
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ForgotPwdModal;
