import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { STATUS } from "../../../utils/constants";
import { Spinner2 } from "../../UI/Spinner2";
import { useTranslation } from "react-i18next";

const OrderProcessingModal = () => {
  const { t } = useTranslation(["POS"]);

  const { completeBookingStatus, createOrderStatus, hasPrinted } = useSelector(
    status => status.orders
  );

  const [orderStatus, setOrderStatus] = useState(false);
  useEffect(() => {
    if (
      completeBookingStatus === STATUS.PENDING ||
      createOrderStatus === STATUS.PENDING ||
      hasPrinted === "isProcessing"
    ) {
      setOrderStatus("inProgress");
    }

    if (
      completeBookingStatus === STATUS.RESOLVED ||
      createOrderStatus === STATUS.RESOLVED
    ) {
      setOrderStatus("");
    }
  }, [completeBookingStatus, createOrderStatus, hasPrinted]);

  const orderStatus2 =
    completeBookingStatus === STATUS.PENDING ||
    createOrderStatus === STATUS.PENDING ||
    hasPrinted === "isProcessing";

  return (
    <Transition appear show={orderStatus2} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* {orderStatus === "inProgress" && ( */}
                <>
                  <p className="text-center text-[20px]">
                    {t("order.loading")}
                  </p>
                  <div className="flex justify-center">
                    <Spinner2 />
                  </div>
                </>

                {/* {orderStatus === "completed" && (
                  <>
                    <p className="text-center text-[20px]">
                      Transaction Completed Successfully
                    </p>
                  </>
                )} */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default OrderProcessingModal;
