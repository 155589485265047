import { useMemo } from "react";
import { ClientPaginatedTable2 } from "../../Tables";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import addHours from "date-fns/addHours";
const InScreenOrdersTable = ({ data = [], closeFunc, onRowClick }) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ row }) => {
          let location = ` ${row?.original?.screen || ""} ( ${
            row?.original?.seatNumber
          })`;
          return location;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          if (value === "Completed") {
            let timeDifference = formatDistanceStrict(
              new Date(row?.original?.dateCompleted),
              new Date(row?.original?.dateCreated)
            );
            let completionString = `Completed In ${timeDifference}`;
            return completionString;
          }
          if (value === "Pending") {
            let timeDifference = formatDistanceToNowStrict(
              addHours(new Date(row?.original?.dateCreated), 1)
            );
            let completionString = `Pending For ${timeDifference}`;
            return completionString;
          }
        },
      },
      {
        Header: "Contact",
        accessor: "phoneNumber",
      },
      //   {
      //     Header: "Total",
      //     accessor: "amount",
      //     Cell: ({ value }) => numToCurrency(value, true),
      //   },
      //   {
      //     Header: "Time",
      //     accessor: "dateCreated",
      //     Cell: ({ value }) => format(new Date(value), "HH:mm:ss"),
      //   },

      //   {
      //     Header: "Payment Channels",
      //     accessor: "paymentChannels",
      //     Cell: ({ value }) => {
      //       let channels = "";
      //       value?.map((channel) => {
      //         return (channels =
      //           channels +
      //           `${channel.channel}: ${numToCurrency(channel.amount, true)}  `);
      //       });

      //       return channels;
      //     },
      //   },
    ];
  }, []);

  return (
    <div className="h-[50%] bg-secondary pb-[10px] ">
      <ClientPaginatedTable2
        className="text-white"
        closeFunc={closeFunc}
        // toolbarChildren={

        // }
        onRowClick={onRowClick}
        // printSummary={handlePrint}
        tableData={data}
        columns={columns}
        getHeaderProps={(column) => ({
          className: "px-2 text-base font-medium border border-[#00000033] ",
          ...(column.id === "item" ? column?.getSortByToggleProps() : {}),
        })}
        getCellProps={(cellInfo) => ({
          style: {},
          className: "text-app-purple-9",
        })}
        getColumnProps={() => ({ style: {} })}
      />
    </div>
  );
};

export default InScreenOrdersTable;
