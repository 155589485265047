import { useState } from "react";
import OpenDrawer from "./OpenDrawer";
import PanelTab from "./PanelTab";
import CloseSession from "./CloseSession";
import OrderListModal from "./RefundModule/OrderListModal";
import { useSelector, useDispatch } from "react-redux";
import { claimsKey } from "../../../utils/constants";
import AllSalesByUser from "../AllSalesByUser";
import { setOfflineRestriction } from "../../../redux/slices";
import { useTranslation } from "react-i18next";
import SwapTicket from "./SwapModule/SwapTicket";
import SwapErrorModal from "./SwapModule/SwapErrorModal";

const AdminPanel = () => {
  const [showSalesByUser, setShowSalesByUser] = useState(false);
  const { t } = useTranslation(["Admin"]);
  const [panelModule, setPanelModule] = useState("");
  const [showOpenDrawer, setOpenDrawer] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showRefundModule, setShowRefundModule] = useState(false);
  const [showSwapTicket, setShowSwapTicket] = useState(false);

  const [swapErrorMsg, setSwapErrorMsg] = useState("");
  const { offlineMode } = useSelector((state) => state.profile);
  const { orderItems } = useSelector((state) => state.orders);
  const cartTickets = orderItems?.filter((x) => {
    return x.showtimeId;
  });

  const dispatch = useDispatch();

  const userClaims = JSON.parse(localStorage?.getItem(claimsKey));

  const swapTicketFunc = () => {
    if (cartTickets.length < 1) {
      setSwapErrorMsg("Ensure you have added the replacement ticket to cart");
      return;
    }
    if (cartTickets.length > 1) {
      setSwapErrorMsg("Ensure you add only one ticket to cart");
      return;
    }

    setShowSwapTicket(true);
  };

  return (
    <>
      <div className="flex justify-center h-[8%] items-center">
        <p className="text-primary font-bold text-[20px]">{t("title")}</p>
      </div>
      <div className="h-[92%] bg-primary flex p-3">
        <PanelTab
          title={t("tabs.a")}
          onClick={() => {
            setOpenDrawer(true);
          }}
        />

        {/* <PanelTab
          title={"Get Sales by User"}
          onClick={() => {
            setShowSalesByUser(true);
          }}
        /> */}

        {(userClaims?.CanRefundOnPOS || userClaims?.CanReprintOrder) && (
          <PanelTab
            title={t("tabs.b")}
            onClick={() => {
              setShowRefundModule(true);
            }}
          />
        )}
        {userClaims?.CanCloseSessionsOnPOS && (
          <PanelTab
            title={t("tabs.c")}
            onClick={() => {
              setShowSessionModal(true);
            }}
          />
        )}

        <PanelTab title={"Swap Ticket"} onClick={swapTicketFunc} />
      </div>

      <OpenDrawer
        openStatus={showOpenDrawer}
        closeFunc={() => {
          setOpenDrawer(false);
        }}
      />
      {showSessionModal && (
        <CloseSession
          openStatus={showSessionModal}
          closeFunc={() => {
            setShowSessionModal(false);
          }}
        />
      )}
      {showRefundModule && (
        <OrderListModal
          openStatus={showRefundModule}
          closeFunc={() => {
            setShowRefundModule(false);
          }}
        />
      )}

      {showSalesByUser && (
        <AllSalesByUser
          openStatus={showSalesByUser}
          closeFunc={() => {
            offlineMode
              ? dispatch(setOfflineRestriction(true))
              : setShowSalesByUser(false);
          }}
        />
      )}

      {swapErrorMsg && (
        <SwapErrorModal
          errMsg={swapErrorMsg}
          closeFunc={() => {
            setSwapErrorMsg("");
          }}
        />
      )}

      {showSwapTicket && (
        <SwapTicket
          openStatus={showSwapTicket}
          closeFunc={() => {
            setShowSwapTicket(false);
          }}
        />
      )}
    </>
  );
};
export default AdminPanel;
