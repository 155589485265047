import React, { useState, useRef, useEffect } from "react";
import KeyboardWrapper from "./KeyBoardWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setShowKeyboard } from "../../redux/slices/profileSlice";
import { useTranslation } from "react-i18next";

const enterPress = input =>
  console.log(input || "enter pressed, but no input value");

const SearchKeyBoard = ({ setSearchResults, closeFunc }) => {
  const keyboard = useRef(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const { t } = useTranslation(["Search", "Common"]);

  const { type, sharedInput, setSharedInput, placeHolder } = useSelector(
    state => state.profile.keyboardConfigurations
  );
  const [input, setInput] = useState("");

  const { itemsList } = useSelector(state => state.items);
  // useEffect(() => {
  //   if (sharedInput && keyboard.current) {
  //     keyboard?.current?.setInput(sharedInput);
  //   }
  // }, [sharedInput]);

  useEffect(() => {
    if (input?.length > 0) {
      const results = itemsList
        ?.filter(item => {
          return item.name.toLowerCase().includes(input.toLowerCase());
        })
        ?.map(x => {
          return { ...x, canModify: true };
        })
        ?.slice(0, 15);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [input]);

  const onChangeInput = event => {
    const newInput = event.target.value;
    setInput(newInput);
    keyboard?.current?.setInput(newInput);
  };

  const onEnterPress = () => {
    // setSharedInput(input.trim());
    closeFunc()
    // dispatch(setShowKeyboard(false));
  };

  return (
    <div>
      <div className="my-4 relative ">
        <p className="italic mb-1 text-blue-500">{t("modal.search.title")}</p>
        <input
          value={input}
          placeholder={"Enter Item Name"}
          onChange={e => onChangeInput(e)}
          className={`border rounded p-2 pr-9 w-[480px] `}
          ref={inputRef}
        />
      </div>
      <KeyboardWrapper
        handleEnter={onEnterPress}
        keyboardRef={keyboard}
        onChange={setInput}
        itemSearch={true}
      />
      <div className="my-3  flex justify-center">
        <button
          className="bg-primary text-white px-3 py-1 rounded"
          onClick={closeFunc}
        >
          {" "}
          {t("Common:button.close")}{" "}
        </button>
      </div>
    </div>
  );
};

export default SearchKeyBoard;
