import { useTranslation } from "react-i18next";
import * as yup from "yup";

export default function useSchema() {
  const { t } = useTranslation("Common");

  const userSalesSchema = yup
    .object()
    .shape({
      userId: yup.string().required(t("schema.required.id")),
      todayDate: yup.string().required(t("schema.required.today"))
    })
    .required();

  const voucherSchema = yup
    .object()
    .shape({
      voucherCode: yup.string().required(t("schema.required.voucher"))
    })
    .required();

  const giftcardSchema = yup
    .object()
    .shape({
      giftcardCode: yup.string().required(t("schema.required.giftcard")),
      amount: yup.string().required(t("schema.required.amount"))
    })
    .required();

  return {
    userSalesSchema,
    voucherSchema,
    giftcardSchema
  };
}
