import { Fragment, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  setShowKeyboard,
  setKeyboardConfiguration,
  addToOrder,
  holdOrder,
  retrieveHeldOrder,
  setOfflineRestriction,
  createAdminAction,
  deleteHeldOrder,
  clearHeldOrders,
} from "../../redux/slices";
import { ClientPaginatedTable2 } from "../Tables";
import AbortModal from "../posModule/AbortModal";
import {useTranslation} from "react-i18next";

const HeldOrdersModal = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["OrderList"]);

  const dispatch = useDispatch();
  const [customerName, setCustomerName] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const { heldOrders } = useSelector((state) => state.orders);
  const { userData, offlineMode } = useSelector((state) => state.profile);
  const [openAbortModal, setOpenAbortModal] = useState(false);

  const deleteFunc = (selectedCustomer) => {
    if (userData.roleName === "POSUser") {
      offlineMode
        ? dispatch(setOfflineRestriction(true))
        : setOpenAbortModal(true);
    } else {
      if (!offlineMode) {
        dispatch(
          createAdminAction({
            doneById: userData.userNumber,
            details: null,
            action: 0,
          })
        );
        dispatch(deleteHeldOrder(selectedCustomer));
      } else {
        dispatch(setOfflineRestriction(true));
      }
    }
  };

  const abortFunc = () => {
    if (userData.roleName === "POSUser") {
      offlineMode
        ? dispatch(setOfflineRestriction(true))
        : setOpenAbortModal(true);
    } else {
      if (!offlineMode) {
        dispatch(
          createAdminAction({
            doneById: userData.userNumber,
            details: null,
            action: 0,
          })
        );
        dispatch(clearHeldOrders());
      } else {
        dispatch(setOfflineRestriction(true));
      }
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t("hold.column.name"),
        accessor: "clientName",
      },
      {
        Header:  t("hold.column.action"),
        Cell: ({
          row: {
            original: { clientName },
          },
        }) => {
          return (
            <div className="p-3 flex justify-around">
              <button
                type="button"
                className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={() => {
                  dispatch(retrieveHeldOrder(clientName));
                  closeFunc();
                }}
              >
                <span>{t("hold.column.actions.retrieve")} </span>
              </button>
              <button
                type="button"
                onClick={() => {
                  setCustomerName(clientName);
                  deleteFunc(clientName);
                }}
                className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              >
                {t("hold.column.actions.delete")}
              </button>
            </div>
          );
        },
      },
    ];
  }, []);

  const heldOrdersData = useMemo(() => {
    return heldOrders;
  }, [heldOrders]);

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setErrorMessage(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[80vw] h-[95vh] bg-secondary transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                <div className="bg-primary">
                  <p className="text-center text-[#FEE27D] py-2 text-[20px]  ">
                    {" "}
                    {t("hold.title")}
                  </p>
                  <AbortModal
                    openStatus={openAbortModal}
                    closeFunc={() => {
                      setOpenAbortModal(false);
                    }}
                    forHeldOrders={true}
                    customerName={customerName}
                  />
                  <div className="h-[50%] bg-secondary pb-[10px] ">
                    <ClientPaginatedTable2
                      className="text-white"
                      closeFunc={closeFunc}
                      abortFunc={abortFunc}
                      forHeldOrders={true}
                      tableData={heldOrdersData}
                      columns={columns}
                      perPage={6}
                      getHeaderProps={(column) => ({
                        className:
                          "px-2 text-base font-medium border border-[#00000033] ",
                        ...(column.id === "item"
                          ? column?.getSortByToggleProps()
                          : {}),
                      })}
                      getCellProps={(cellInfo) => ({
                        style: {},
                        className: "text-app-purple-9",
                      })}
                      getColumnProps={() => ({ style: {} })}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default HeldOrdersModal;
