import React from "react";
import { numToCurrency } from "../../../utils/currencyHelper";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ReprintReceipt = React.forwardRef(
  ({ orderData, selectedOrder, singleOrder }, ref) => {
    const { cinemaSettings, userData } = useSelector((state) => state.profile);
    const methodsList = singleOrder?.paymentChannels?.map((channel) => {
      return ` ${channel?.channel}:${numToCurrency(channel?.amount)}`;
    });
const {t} = useTranslation(["receipt"])
    return (
      <div
        id="orderReprint"
        className=" relative z-10  max-w-[300px] text-[8px] p-[10px] "
        ref={ref}
      >
        <table className="w-[100%] text-[10px] pb-5">
          <thead>
            <tr className="text-left">
              <th className="w-[50%]">{t("order_details.header.item")}</th>
              <th className="w-[10%] text-center">{t("order_details.header.unit")}</th>
              <th className="w-[20%]">{t("order_details.header.price")}</th>
              <th className="w-[20%]">{t("order_details.header.subtotal")}</th>
            </tr>
          </thead>
          <tbody>
            {orderData.length > 0 &&
              orderData.map((item, index) => {
                return (
                  <tr className="border-b-2" key={index}>
                    <td className="w-[50%]">{item?.name || item?.title}</td>
                    <td className="w-[10%] text-center">
                      {item.orderQuantity}
                    </td>
                    <td className="w-[20%]">
                      {numToCurrency(item?.price, true)}
                    </td>
                    <td className="w-[20%]">{` ${numToCurrency(
                      item?.price * item?.orderQuantity,
                      true
                    )}  `}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <p className="text-center font-bold text-[14px]">{`${t("labels.total")}: ${numToCurrency(
          selectedOrder?.amount
        )}`}</p>
        <p className="text-[14px] text-center font-bold">{t("receipt_type.header.reprint")}</p>
        <div className="flex justify-between px-2">
          <p className="text-center">{t("labels.staff")}: {`${selectedOrder?.user}`}</p>
          <p className="text-center">
            {new Date(selectedOrder?.dateCreated).toDateString() +
              " " +
              new Date(selectedOrder?.dateCreated).toLocaleTimeString()}
          </p>
        </div>
        <p className="text-center text-[10px]">
          {" "}
          {`${t("labels.orderId")}: ${selectedOrder?.id}`}
        </p>
        {methodsList?.length > 0 && (
          <p className="py-2">
              {`${
                  methodsList?.length > 1
                    ? t("labels.paymentmethod.multiple")
                    : t("labels.paymentmethod.single")
                }`}
                : {methodsList.toString()}
          </p>
        )}
       <p className="text-center mb-[15px]">
              {t("footer.top")}
              <br />
              {t("footer.bottom")}
            </p>
        <hr
          className={`mt-[15px] font-[15px] ${
            singleOrder?.ticketSales?.length > 0
              ? "print:break-after-page "
              : ""
          } `}
        />

        <div>
          {singleOrder?.ticketSales?.length > 0
            ? singleOrder?.ticketSales.map((ticket, i) => {
                if (ticket.quantity === 1) {
                  return (
                    <div className="py-3 print:break-after-page" key={i}>
                      <p className="text-center font-bold text-[16px]">
                        {" "}
                        {userData.cinema}
                      </p>
                      <p className="text-center text-[16px] font-bold">
                        {ticket.film}
                      </p>
                      <div className="flex justify-between">
                        <p>{t("labels.date")}: {new Date(ticket.startTime).toDateString()}</p>
                        <p>
                          {t("labels.time")}:{" "}
                          {new Date(ticket.startTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          }) +
                            "-" +
                            new Date(ticket.endTime).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                        </p>
                      </div>
                      <div className="flex items-center justify-between ">
                        <p>
                          {t("labels.price")}:{" "}
                          {ticket.packagePrice
                            ? numToCurrency(ticket.packagePrice, true)
                            : numToCurrency(ticket.ticketPrice, true)}
                        </p>
                        <p className=" text-[16px] px-2 font-bold">
                          {" "}
                          {ticket.screen}
                        </p>
                      </div>

                      <p className="text-center text-[10px]">
                        {" "}
                        {`${t("labels.orderId")}: ${selectedOrder?.id}`}
                      </p>
                      <p className="text-[14px] text-center font-bold">
                        {t("receipt_type.header.reprint")}
                      </p>
                      <p className="text-center text-[10px]">
                        {t("labels.staff")}: {`${selectedOrder?.user}`}
                      </p>
                      {cinemaSettings?.seatAllocation && ticket?.rowName && (
                        <p className="text-center text-[10px] font-bold">
                          {" "}
                          {`${t("labels.seatNumber")}: ${ticket?.rowName +
                            ticket?.seatNumber}`}
                        </p>
                      )}
                      <p className="text-center text-[10px]">
                        {t("footer.appreciation")}{" "}
                        {userData.cinema}
                      </p>
                      <div className="border-dashed border-b-2 border-[black] my-4 px-2"></div>
                      <p className="  text-center font-bold text-[16px]">
                        {userData.cinema}
                      </p>
                      <p className="text-center text-[16px] font-bold">
                        {ticket.film}
                      </p>
                      <div className="flex justify-between">
                        <p>{t("labels.date")}: {new Date(ticket.startTime).toDateString()}</p>
                        <p>
                          {t("labels.time")}:{" "}
                          {new Date(ticket.startTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          }) +
                            "-" +
                            new Date(ticket.endTime).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                        </p>
                      </div>
                      <p className=" text-[16px] font-bold text-center ">
                        {" "}
                         {t("receipt_type.header.merchant")}
                      </p>

                      <p className="text-center text-[10px]">
                        {" "}
                        {`${t("labels.orderId")}: ${selectedOrder?.id}`}
                      </p>
                      <p className="text-[14px] text-center font-bold">
                        {t("receipt_type.header.reprint")}
                      </p>
                      <p className="text-center text-[10px]">
                        {t("labels.staff")}: {`${singleOrder?.user}`}
                      </p>
                      {cinemaSettings.includeChannelsOnTicket &&
                        methodsList?.length > 0 && (
                          <p className="py-2">
                             {`${
                  methodsList?.length > 1
                    ? t("labels.paymentmethod.multiple")
                    : t("labels.paymentmethod.single")
                }`}
                : {methodsList.toString()}
                          </p>
                        )}
                      {cinemaSettings?.seatAllocation && ticket?.rowName && (
                        <p className="text-center text-[10px] font-bold">
                          {" "}
                          {`${t("labels.seatNumber")}: ${ticket?.rowName +
                            ticket?.seatNumber}`}
                        </p>
                      )}
                      <div className="flex items-center justify-between ">
                        <p>
                          {t("labels.price")}:{" "}
                          {ticket.packagePrice
                            ? numToCurrency(ticket.packagePrice, true)
                            : numToCurrency(ticket.ticketPrice, true)}
                        </p>
                        <p className=" text-[16px] px-2 font-bold">
                          {" "}
                          {ticket.screen}
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  const ticketArray = Array.from({
                    length: ticket.quantity,
                  });

                  return ticketArray.map((sticket, i) => {
                    return (
                      <div className="py-3 print:break-after-page" key={i}>
                        <p className="text-center font-bold text-[16px]">
                          {" "}
                          {userData.cinema}
                        </p>
                        <p className="text-center text-[16px] font-bold">
                          {ticket.film}
                        </p>
                        <div className="flex justify-between">
                          <p>
                            {t("labels.date")}: {new Date(ticket.startTime).toDateString()}
                          </p>
                          <p>
                            {t("labels.time")}:{" "}
                            {new Date(ticket.startTime).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            }) +
                              "-" +
                              new Date(ticket.endTime).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                          </p>
                        </div>
                        <div className="flex items-center justify-between ">
                          <p>
                            {t("labels.price")}:{" "}
                            {ticket.packagePrice
                              ? numToCurrency(ticket.packagePrice, true)
                              : numToCurrency(ticket.ticketPrice, true)}
                          </p>
                          <p className=" text-[16px] px-2 font-bold">
                            {" "}
                            {ticket.screen}
                          </p>
                        </div>

                        <p className="text-center text-[10px]">
                          {" "}
                          {`${t("labels.orderId")}: ${selectedOrder?.id}`}
                        </p>
                        <p className="text-[14px] text-center font-bold">
                          {t("receipt_type.header.reprint")}
                        </p>
                        <p className="text-center text-[10px]">
                          {t("labels.staff")}: {`${selectedOrder?.user}`}
                        </p>
                        {cinemaSettings?.seatAllocation && ticket?.rowName && (
                          <p className="text-center text-[10px] font-bold">
                            {" "}
                            {`${t("labels.seatNumber")}: ${ticket?.rowName +
                              ticket?.seatNumber}`}
                          </p>
                        )}
                        <p className="text-center text-[10px]">
                          {t("footer.appreciation")}{" "}
                          {userData.cinema}
                        </p>
                        <div className="border-dashed border-b-2 border-[black] my-4 px-2"></div>
                        <p className="  text-center font-bold text-[16px]">
                          {userData.cinema}
                        </p>
                        <p className="text-center text-[16px] font-bold">
                          {ticket.film}
                        </p>
                        <div className="flex justify-between">
                          <p>
                            {t("labels.date")}: {new Date(ticket.startTime).toDateString()}
                          </p>
                          <p>
                            {t("labels.time")}:{" "}
                            {new Date(ticket.startTime).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            }) +
                              "-" +
                              new Date(ticket.endTime).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                          </p>
                        </div>
                        <p className=" text-[16px] font-bold text-center ">
                          {" "}
                           {t("receipt_type.header.merchant")}
                        </p>

                        <p className="text-center text-[10px]">
                          {" "}
                          {`${t("labels.orderId")}: ${selectedOrder?.id}`}
                        </p>
                        <p className="text-[14px] text-center font-bold">
                          {t("receipt_type.header.reprint")}
                        </p>
                        <p className="text-center text-[10px]">
                          {t("labels.staff")}: {`${singleOrder?.user}`}
                        </p>
                        {cinemaSettings.includeChannelsOnTicket &&
                          methodsList?.length > 0 && (
                            <p className="py-2">
                               {`${
                  methodsList?.length > 1
                    ? t("labels.paymentmethod.multiple")
                    : t("labels.paymentmethod.single")
                }`}
                : {methodsList.toString()}
                            </p>
                          )}
                        {cinemaSettings?.seatAllocation && ticket?.rowName && (
                          <p className="text-center text-[10px] font-bold">
                            {" "}
                            {`${t("labels.seatNumber")}: ${ticket?.rowName +
                              ticket?.seatNumber}`}
                          </p>
                        )}
                        <div className="flex items-center justify-between ">
                          <p>
                            {t("labels.price")}:{" "}
                            {ticket.packagePrice
                              ? numToCurrency(ticket.packagePrice, true)
                              : numToCurrency(ticket.ticketPrice, true)}
                          </p>
                          <p className=" text-[16px] px-2 font-bold">
                            {" "}
                            {ticket.screen}
                          </p>
                        </div>
                      </div>
                    );
                  });
                }
              })
            : null}
        </div>
      </div>
    );
  }
);
export default ReprintReceipt;
