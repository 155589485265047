import Ticket from "../../../orderModule/Receipt/Ticket";
import React from "react";
const SwapTicketReprint = React.forwardRef(({ currentOrderId }, ref) => {
  return (
    <div
      id="receipt"
      className=" relative z-10  max-w-[300px] text-[8px] p-[10px]"
      ref={ref}
    >
      <Ticket currentOrderId={currentOrderId} />
    </div>
  );
});

export default SwapTicketReprint;
