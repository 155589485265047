import OrderList from "./OrderList";
import OrderPayment from "./OrderPayment";
import OrderTotal from "./OrderTotal";

const OrderModule = () => {
  return (
    <div className=" w-1/4 p-2">
      {/* bolu */}
      <OrderList />
      {/* imole */}
      <OrderTotal />
      <OrderPayment />
    </div>
  );
};
export default OrderModule;
