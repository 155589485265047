import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  setShowKeyboard,
  setKeyboardConfiguration,
  addToOrder
} from "../../../redux/slices";

import { Spinner } from "../../UI/Spinner";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../context/KeyboardContext";

const VariablePriceModal = ({ openStatus, closeFunc, data }) => {
  const { t } = useTranslation(["Home", "Common"]);
  const dispatch = useDispatch();
  const [itemPrice, setItemPrice] = useState(data?.price);
  const [showError, setShowError] = useState(false);
const { setSetSharedInput } = useKeyboard()
  useEffect(() => {
    setItemPrice(data?.price);
  }, [data]);

  const submitHandler = e => {
    e.preventDefault();

    if (+itemPrice < 1) {
      setShowError(true);
      return;
    }

    dispatch(addToOrder({ ...data, price: +itemPrice }));
    setItemPrice("");
    setShowError(false);
    closeFunc();
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Enter Admin Pin
                </Dialog.Title> */}
                <form onSubmit={submitHandler}>
                  {showError && (
                    <p className="text-red-500 text-center">
                      {" "}
                      {t("variable.error")}
                    </p>
                  )}
                  <div className="mt-2 deleteModal flex justify-center">
                    <div>
                      <label className="text-center block">
                        {t("variable.label")}
                      </label>
                      <br />
                      <input
                        type="text"
                        value={itemPrice}
                        placeholder={t("variable.placeholder")}
                        className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "numeric",
                              sharedInput: itemPrice,
                              placeHolder: t(
                                "Common:keyboard.placeholder.price"
                              ),
                              // setSharedInput: x => {
                              //   setItemPrice(x);
                              // }
                            })
                          );
                          setSetSharedInput(() => setItemPrice)
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-5 flex justify-center">
                    <button
                      type="submit"
                      className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      <span>{t("Common:button.submit")} </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setItemPrice("");
                        closeFunc();
                        setShowError(false);
                      }}
                      className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default VariablePriceModal;
