import { combineReducers, configureStore } from "@reduxjs/toolkit";
import orderReducer from "../redux/slices/orderSlice";
import {
  profileReducer,
  itemsReducer,
  showtimesReducer,
  voucherReducer,
  giftcardReducer,
  discountCodeReducer,
  promoCodeReducer,
  loyaltyReducer,
} from "./slices";

const rootReducer = combineReducers({
  orders: orderReducer,
  profile: profileReducer,
  items: itemsReducer,
  showtimes: showtimesReducer,
  voucher: voucherReducer,
  giftcard: giftcardReducer,
  discountCode: discountCodeReducer,
  promoCode: promoCodeReducer,
  loyalty: loyaltyReducer,
});

const appReducer = (rootState, action) => {
  if (action.type === "profile/logout") {
    return rootReducer(undefined, action);
  }
  return rootReducer(rootState, action);
};

export const store = configureStore({
  reducer: appReducer,
});
