import { useEffect, useMemo, useRef, useState } from "react";
import { ClientPaginatedTable, DefaultColumnFilter } from "../../Tables";
import { getSalesReport, getSalesSummary } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { numToCurrency } from "../../../utils/currencyHelper";
import { useTranslation } from "react-i18next";

const SalesTable = ({ closeFunc }) => {
  const { t } = useTranslation(["POS"]);
  const [salesCategory, setSalesCategory] = useState("allsales");

  const { userData, salesSummary } = useSelector(state => state.profile);
  const { salesReport } = useSelector(state => state.orders);
  const [filteredSalesReport, setFilteredSalesReport] = useState(salesReport);
  const dispatch = useDispatch();
  const summaryRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => summaryRef.current
  });

  const SummaryReport = () => {
    const { t } = useTranslation(["POS"]);
    return (
      <div
        id="summaryReport"
        className=" relative z-10  max-w-[300px] text-[8px] p-[10px]"
        ref={summaryRef}
      >
        <p className="text-center">{t("sales.summary")}</p>
        <p className="text-center">
          {new Date().toDateString() + " " + new Date().toLocaleTimeString()}
        </p>
        <p>
          {" "}
          {t("sales.user")}:{" "}
          {salesSummary?.paramFilter?.user.firstName +
            salesSummary?.paramFilter?.user.lastName}
        </p>
        <p>
          {" "}
          {t("sales.total.title")}:{" "}
          {numToCurrency(salesSummary?.result?.totalSales, true)}
        </p>
        {salesSummary?.result?.channelSales?.map(channel => {
          return (
            <p>
              {t("sales.total.channel", {
                channel: channel.channel,
                total: numToCurrency(channel.total)
              })}
            </p>
          );
        })}
        <p className="my-2 font-bold">{t("sales.single.title")}</p>
        {salesSummary?.result?.itemClassList.length > 0 ? (
          <>
            <table className="w-[100%]">
              <thead>
                <tr className="text-left">
                  <th className="w-[70%]">{t("sales.single.columns.a")}</th>
                  <th className="w-[30%]">{t("sales.single.columns.b")}</th>
                </tr>
              </thead>
              <tbody>
                {salesSummary?.result?.itemClassList.map((itemClass, index) => {
                  return (
                    <tr className="border-b-2" key={index}>
                      {" "}
                      <td className="w-[70%]"> {itemClass.itemClass} </td>{" "}
                      <td className="w-[30%]">
                        {" "}
                        {numToCurrency(itemClass.amount, true)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p className="text-center py-2">
              {" "}
              {t("sales.single.total")}:
              {numToCurrency(salesSummary?.result?.totalItemClassSales)}
            </p>
          </>
        ) : (
          <p className="text-center">{t("sales.single.empty")}</p>
        )}
        <p className="my-2 font-bold">{t("sales.package.title")}</p>
        {salesSummary?.result?.itemPackageList.length > 0 ? (
          <>
            <table className="w-[100%]">
              <thead>
                <tr className="text-left">
                  <th className="w-[70%]">{t("sales.package.columns.a")}</th>
                  <th className="w-[30%]">{t("sales.package.columns.b")}</th>
                </tr>
              </thead>
              <tbody>
                {salesSummary?.result?.itemPackageList.map(
                  (itemPackage, index) => {
                    return (
                      <tr className="border-b-2" key={index}>
                        {" "}
                        <td className="w-[70%]">
                          {" "}
                          {itemPackage.itemPackage}{" "}
                        </td>{" "}
                        <td className="w-[30%]">
                          {" "}
                          {numToCurrency(itemPackage.amount)}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
            <p className="text-center py-2">
              {" "}
              {t("sales.package.total")}:
              {numToCurrency(salesSummary?.result?.totalItemPackageSales)}
            </p>
          </>
        ) : (
          <p className="text-center">{t("sales.package.empty")}</p>
        )}
        <p className="my-2 font-bold">{t("sales.ticket.title")}</p>
        {salesSummary?.result?.ticketList.length > 0 ? (
          <>
            <table className="w-[100%]">
              <thead>
                <tr className="text-left">
                  <th className="w-[70%]">{t("sales.ticket.columns.a")}</th>
                  <th className="w-[30%]">{t("sales.ticket.columns.b")}</th>
                </tr>
              </thead>
              <tbody>
                {salesSummary?.result?.ticketList.map((ticket, index) => {
                  return (
                    <tr className="border-b-2" key={index}>
                      {" "}
                      <td className="w-[70%]"> {ticket.ticket} </td>{" "}
                      <td className="w-[30%]">
                        {" "}
                        {numToCurrency(ticket.amount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p className="text-center py-2">
              {" "}
              {t("sales.ticket.total")}:
              {numToCurrency(salesSummary?.result?.totalTicketSales)}
            </p>
          </>
        ) : (
          <p className="text-center">{t("sales.ticket.empty")}</p>
        )}
      </div>
    );
  };

  const currentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("/");

  useEffect(() => {
    dispatch(getSalesReport(userData.id))
      .unwrap()
      .then(data => {
        setFilteredSalesReport(data);
      });
    dispatch(getSalesSummary());
  }, [dispatch]);

  const columns = useMemo(() => {
    return [
      {
        Header: t("sales.columns.a"),
        accessor: "name",
        Filter: DefaultColumnFilter
      },
      {
        Header: t("sales.columns.b"),
        accessor: "quantity"
      },
      {
        Header: t("sales.columns.c"),
        accessor: "price",
        Cell: ({ value }) => {
          return numToCurrency(value);
        }
      },
      {
        Header: t("sales.columns.d"),
        accessor: "amount",
        Cell: ({ value }) => {
          return numToCurrency(value);
        }
      }
    ];
  }, []);
  return (
    <div className="bg-primary">
      <p className="text-center text-[#FEE27D] py-2 text-[20px] h-[10%] ">
        {" "}
        {t("sales.title.today")} ({currentDate})
      </p>

      <div className=" flex justify-around py-2 px-[15%] text-white  ">
        {" "}
        <div
          className={` w-[20%] rounded-xl border-2 p-2  ${
            salesCategory === "allsales" ? "bg-[#bc8bbd]" : "bg-[#8b378d]"
          } `}
          onClick={() => {
            setSalesCategory("allsales");
            setFilteredSalesReport(salesReport);
          }}
        >
          <p>{t("sales.tabs.a")}</p>
          <p
            className={`text-[15px] ${
              salesCategory === "allsales" ? "text-[#FEE27D]" : "text-[black]"
            }`}
          ></p>
        </div>{" "}
        <div
          className={` w-[20%] rounded-xl border-2 p-2  ${
            salesCategory === "concessions" ? "bg-[#bc8bbd]" : "bg-[#8b378d]"
          } `}
          onClick={() => {
            setSalesCategory("concessions");
            setFilteredSalesReport(
              salesReport.filter(data => {
                return data.type === "Item";
              })
            );
          }}
        >
          <p>{t("sales.tabs.b")}</p>
          <p
            className={`text-[15px] ${
              salesCategory === "concessions"
                ? "text-[#FEE27D]"
                : "text-[black]"
            }`}
          ></p>
        </div>{" "}
        <div
          className={` w-[20%] rounded-xl border-2 p-2  ${
            salesCategory === "tickets" ? "bg-[#bc8bbd]" : "bg-[#8b378d]"
          } `}
          onClick={() => {
            setSalesCategory("tickets");
            setFilteredSalesReport(
              salesReport.filter(data => {
                return data.type === "Ticket";
              })
            );
          }}
        >
          <p>{t("sales.tabs.c")}</p>
          <p
            className={`text-[15px] ${
              salesCategory === "tickets" ? "text-[#FEE27D]" : "text-[black]"
            }`}
          ></p>
        </div>
      </div>
      <div className="h-[50%] bg-secondary pb-[10px] ">
        <ClientPaginatedTable
          closeFunc={closeFunc}
          filterColumnsKeys={["name"]}
          // toolbarChildren={

          // }
          // onRowClick={onRowClick}
          printSummary={handlePrint}
          tableData={filteredSalesReport}
          columns={columns}
          getHeaderProps={column => ({
            className: "px-2 text-base font-medium border border-[#00000033] ",
            ...(column.id === "item" ? column?.getSortByToggleProps() : {})
          })}
          getCellProps={cellInfo => ({
            style: {},
            className: "text-app-purple-9"
          })}
          getColumnProps={() => ({ style: {} })}
        />
      </div>
      <div className="hidden">
        <SummaryReport />
      </div>
    </div>
  );
};
export default SalesTable;
