import { useEffect } from "react";
import Modal from "../UI/Modal";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumPad from "react-numpad";
import { customChannelsSchema } from "../../utils/schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomChannels,
  setShowKeyboard,
  setKeyboardConfiguration,
} from "../../redux/slices";
import { useKeyboard } from "../../context/KeyboardContext";

const CustomChannelsModal = ({
  openStatus,
  closeFunc,
  customChannelsAmount,
  setCustomChannelsAmount,
}) => {
  const { customChannels } = useSelector((state) => state.orders);
  const { userData } = useSelector((state) => state.profile);

  const { t } = useTranslation(["Home", "Common"]);
const {setSetSharedInput}=useKeyboard()
  const dispatch = useDispatch();
  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(customChannelsSchema),
    defaultValues: {},
  });

  const { fields } = useFieldArray({
    control,
    name: "channels",
  });

  useEffect(() => {
    dispatch(getCustomChannels(userData?.cinemaId));
    if (!customChannelsAmount?.length > 0) {
      reset({
        channels: customChannels?.map((x) => {
          return {
            name: x?.name,
            channelId: x?.id,
            amount:
              customChannelsAmount?.find((y) => {
                return y?.channelId === x?.id;
              })?.amount || "",
          };
        }),
      });
    } else {
      reset({
        channels: customChannelsAmount,
      });
    }
  }, []);

  return (
    <Modal
      openStatus={openStatus}
      closeFunc={closeFunc}
      panelStyles="bg-white min-h-[250px]"
    >
      <p className="text-center m-3">Custom Channels</p>
      <div className="mx-5 grid grid-cols-3 gap-x-5 ">
        {fields?.map((field, index) => {
          return (
            <div className="mt-2 deleteModal">
              <label>{field.name}</label>
              <br />
              <input
                type="text"
                value={getValues(`channels.${index}.amount`)}
                placeholder={t("Common:input.placeholder.amount")}
                className=" w-[200px]  h-[35px] border border-secondary rounded py-2 px-3"
                onClick={() => {
                  dispatch(setShowKeyboard(true));
                  dispatch(
                    setKeyboardConfiguration({
                      type: "numeric",
                      placeholder: 0,
                      sharedInput: getValues(`channels.${index}.amount`),
                      placeHolder: t("Common:keyboard.placeholder.amount"),
                      // setSharedInput: (x) => {
                      //   setValue(`channels.${index}.amount`, x);
                      // },
                    })
                  );
                  setSetSharedInput(() => (val) => setValue(`channels.${index}.amount`,val))
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="my-5 flex justify-center">
        <button
          type="button"
          onClick={() => {
            setCustomChannelsAmount(
              watch("channels")?.filter((x) => {
                return x.amount > 0;
              })
            );
            closeFunc();
          }}
          className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          <span>{t("Common:button.submit")} </span>
          {/* <Spinner
                className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                //   status={status}
              /> */}
        </button>
        <button
          type="button"
          onClick={() => {
            // setQty("");
            closeFunc();
            // setErrorMsg("");
          }}
          className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          {t("Common:button.cancel")}
        </button>
      </div>
    </Modal>
  );
};
export default CustomChannelsModal;
