import { useMemo } from "react";
import { timeString } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
const FilmsList = ({
  NewTicketData,
  start,
  end,
  setShowtimeId,
  showTickets
}) => {
  const { t } = useTranslation(["Home"]);
  const filmsInShowtime = useMemo(() => {
    if (NewTicketData) {
      return NewTicketData?.filter((showtime, i, ar) => {
        return (
          ar
            ?.map(x => {
              return x.filmId;
            })
            .indexOf(showtime?.filmId) === i
        );
      });
    } else return [];
  }, [NewTicketData]);

  return (
    <div className="h-[85%]">
      {filmsInShowtime?.slice(start, end)?.map((film, index) => {
        return (
          <div
            className="rounded border-2 mb-1 p-1 bg-white h-[20%] "
            key={index}
          >
            <div className="flex justify-between">
              <p className="text-[12px] font-bold ">{film?.film}</p>
              <p className="text-[10px]">
                {t("ticket.film.details.duration", {
                  duration: film?.duration
                })}
              </p>
            </div>
            <div className="flex ">
              {NewTicketData?.filter(x => {
                return x.filmId === film.filmId;
              })
                ?.sort((a, b) => {
                  return (
                    new Date(a.startTime).getTime() -
                    new Date(b.startTime).getTime()
                  );
                })
                ?.map((showtime, index) => {
                  return (
                    <div
                      className={` px-1 py-[2px] mr-1 border-2 text-[9px] border-secondary rounded-md text-black ${showtime.seatsSold >=
                        showtime.totalSeats &&
                        "border-red-500 text-[7px]"} hover:bg-secondary hover:text-white`}
                      key={index}
                      onClick={() => {
                        if (showtime.seatsSold < showtime.totalSeats) {
                          setShowtimeId(showtime.id);
                          showTickets();
                        }
                      }}
                    >
                      <p>{showtime?.screen}</p>
                      <p>{`${timeString(showtime?.startTime)} - ${timeString(
                        showtime?.endTime
                      )}`}</p>
                      {showtime.seatsSold >= showtime.totalSeats ? (
                        <p className="  text-center text-red-500">
                          {t("ticket.finished")}
                        </p>
                      ) : (
                        <p>
                          <span className="text-[8px]">
                            {`${showtime.totalSeats}/${
                              showtime.seatsSold
                            }/${showtime.totalSeats -
                              showtime.seatsSold}   `}{" "}
                          </span>{" "}
                          <span className="ml-1 text-[8px]">{`${(
                            (showtime.seatsSold / showtime.totalSeats) *
                            100
                          ).toFixed(0)}%`}</span>{" "}
                        </p>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export { FilmsList };
