import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  setShowKeyboard,
  setKeyboardConfiguration,
  setLoyaltyData,
} from "../../redux/slices";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../context/KeyboardContext";

const LoyaltyRewardModal = ({
  openStatus,
  closeFunc,
  rewardData,
  setLoyaltyValue,
  closeParent,
}) => {
  const dispatch = useDispatch();
  const { customerData } = useSelector((state) => state.loyalty);
  const [amount, setAmount] = useState("");
  const [actionError, setActionError] = useState(true);
  const { t } = useTranslation(["OrderPayment", "Common"])
  const { setSetSharedInput } = useKeyboard()

  const onSubmit = (e) => {
    e.preventDefault();
    if (amount && !customerData?.member?.points) {
      setActionError(t("OrderPayment:paymentchannel_modal.errors.points.a"));
      return;
    }
    if (customerData?.member?.points && amount > customerData?.member?.points) {
      setActionError(
       t("OrderPayment:paymentchannel_modal.errors.points.b")
      );
      return;
    }
    if (amount > 0) {
      setLoyaltyValue({ amount, memberId: customerData.member.id });
    }
    dispatch(
      setLoyaltyData({
        phoneNumber: rewardData?.phoneNumber || null,
        email: rewardData?.email || null,
        firstName: rewardData?.firstName || null,
        lastName: rewardData?.lastName || null,
      })
    );
    closeFunc();
    closeParent();
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <form onSubmit={onSubmit}>
                  {actionError && (
                    <p className="text-red-500 text-center"> {actionError}</p>
                  )}
                  {customerData.isExistingMember && (
                    <p className="text-center">
                      {/* Current balance is{" "}
                      <span className="font-bold">
                        {customerData.member.points}
                      </span> */}
                      <span dangerouslySetInnerHTML={{__html:t("OrderPayment:paymentchannel_modal.label.isMember_true",{points:`<strong>${customerData.member.points}</strong>`})}}></span>
                    </p>
                  )}
                  {!customerData.isExistingMember && (
                    <p className="text-center">
                      {t("OrderPayment:paymentchannel_modal.label.isMember_false")}
                    </p>
                  )}
                  <p className="text-center">
                    {/* Estimated points to be awarded is{" "}
                    {
                      <span className="font-bold">
                        {customerData.loyaltyPoints}
                      </span>
                    }{" "}
                    points */}
                     <span dangerouslySetInnerHTML={{__html:t("OrderPayment:paymentchannel_modal.label.point_est",{points:`<strong>${customerData.loyaltyPoints}</strong>`})}}></span>
                  </p>
                  <div className="mt-5 deleteModal flex justify-center">
                    <div>
                      <label className="text-center block">
                        {t("OrderPayment:paymentchannel_modal.label.redeem")}
                      </label>

                      <input
                        type="text"
                        value={amount}
                        placeholder={t("Common:keyboard.placeholder.amount")}
                        className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "numeric",
                              sharedInput: amount,
                              placeHolder: t("Common:keyboard.placeholder.amount"),
                              // setSharedInput: (x) => {
                              //   setAmount(x);
                              // },
                            })
                          );
                          setSetSharedInput(() => setAmount)
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-5 flex justify-center">
                    <button
                      type="button"
                      onClick={() => {
                        setAmount("");
                        setActionError("");
                        closeFunc();
                      }}
                      className=" inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                     {t("Common:button.cancel")}
                    </button>
                    <button
                      type="submit"
                      className=" ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      <span>{t("Common:button.proceed")}</span>
                      {/* <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                        status={status}
                      /> */}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default LoyaltyRewardModal;
