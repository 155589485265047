import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoggedIn, useLogout } from "../hooks";
import { redirectKey } from "../utils/constants";
import SmallScreen from "./UI/SmallSceen";

const PrivateRoute = ({ children }) => {
  const { logout } = useLogout();
  const { pathname } = useLocation();
  const { checkAuth } = useLoggedIn();
  const navigate = useNavigate();
  const valid = checkAuth();

  useEffect(() => {
    if (!valid) {
      localStorage.setItem(redirectKey, pathname);
      logout();
    }
  }, [valid, logout, pathname]);

  if (valid) {
    return (
      <div>
        <div className="sm:hidden md:block">{children}</div>
        <div className="sm:block md:hidden">
          <SmallScreen />
        </div>
      </div>
    );
  } else {
    // return null;
    navigate("/");
  }
};

export { PrivateRoute };
