import React, { useMemo, useState } from "react";
import { usePagination, useTable } from "react-table";
import PaginatePills from "./PaginatePills";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ClientPaginatedTable2 = ({
  columns = [],
  tableData = [],
  perPage = 10,
  rowClass = "",
  onRowClick = () => {},
  toolbarChildren = null,
  getColumnProps = () => {},
  getHeaderProps = () => {},
  getRowProps = () => {},
  getCellProps = () => {},
  tableClass = "",
  toolBarWrapperClass = "",
  filtersWrapperClass = "",
  toolbarChildrenWrapperClass = "",
  closeFunc,
  printSummary,
  forHeldOrders = false,
  abortFunc
}) => {
  const { t } = useTranslation(["Common"]);
  const data = useMemo(() => tableData, [tableData]);
  const [openAbortModal, setOpenAbortModal] = useState(false);
  const { offlineMode, userData } = useSelector(state => state.profile);
  const { heldOrders } = useSelector(state => state.orders);
  const dispatch = useDispatch();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: { pageSize: perPage, pageIndex: 0 }
    },

    // useSortBy,
    usePagination
  );

  if (!data.length) {
    return (
      <div>
        <div className=" flex justify-around pt-3">
          <button
            type="submit"
            className="inline-flex mr-3 justify-center rounded-md border border-transparent hover: border-primary bg-primary px-4 py-1 text-sm  text-[#FEE27D] hover:bg-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-[#FEE27D] focus-visible:ring-offset-2"
            onClick={() => {
              closeFunc();
            }}
          >
            {t("button.close")}
          </button>
        </div>
        <br />
        <h4 className="m-4 text-base font-semibold text-center text-app-purple-4">
          {t("table.empty")}
        </h4>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between px-3 bg-secondary">
        <div className=" flex w-[100%]  justify-center h-[10%] py-4">
          {closeFunc && (
            <button
              type="button"
              className="inline-flex mr-3 justify-center rounded-md border border-transparent hover: border-primary bg-primary px-4 py-1 text-sm  text-[#FEE27D] hover:bg-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-[#FEE27D] focus-visible:ring-offset-2"
              onClick={() => {
                closeFunc();
              }}
            >
              {t("button.close")}
            </button>
          )}

          {forHeldOrders && (
            <button
              type="submit"
              className="inline-flex mr-3 justify-center rounded-md border border-transparent hover: border-primary bg-primary px-4 py-1 text-sm  text-[#FEE27D] hover:bg-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-[#FEE27D] focus-visible:ring-offset-2"
              onClick={() => {
                abortFunc();
              }}
            >
              {t("table.clear.held")}
            </button>
          )}
        </div>
      </div>
      <table
        className={`w-full text-sm  text-white text-left border-collapse bg-app-purple-2 ${tableClass}`}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column?.className,
                      style: column?.style
                    },
                    getColumnProps(column),
                    getHeaderProps(column)
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr
                className={`text-base hover:bg-app-purpl-7  transition-colors cursor-pointer border-b border-[#7E20804D] ${rowClass} `}
                onClick={onRowClick(row)}
                {...row.getRowProps(getRowProps(row))}
              >
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps([
                      {
                        className: `px-2 font-medium    ${cell?.column?.className}`,
                        style: cell?.column?.style
                      },
                      getColumnProps(cell?.column),
                      getCellProps(cell)
                    ])}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <PaginatePills
        currentPage={pageIndex}
        noOfPages={pageCount}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};

export { ClientPaginatedTable2 };
