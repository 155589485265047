import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
// import { OrderDetailsModal } from "./OrderDetailsModal";
import { Spinner } from "../../UI/Spinner";
import { numToCurrency } from "../../../utils/currencyHelper";
import Modal from "../../UI/Modal";
import InScreenOrdersTable from "./InScreenOrdersTable";
import { sampleInScreenOrder } from "../../../utils/data";
import { getInScreenOrders } from "../../../redux/slices";

const InScreenModal = ({ openStatus, closeFunc }) => {
  const { userData } = useSelector((state) => state.profile);
  const { inScreenOrders } = useSelector((state) => state.orders);
  const [show, setShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [status, setStatus] = useState("");
  const [screen, setScreen] = useState("");
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getInScreenOrders());
  }, []);

  const data = useMemo(() => {
    return inScreenOrders.filter((sample) => {
      return (
        (status ? sample.status === status : true) &&
        (screen ? sample.screen === screen : true)
      );
    });
  }, [status, screen, inScreenOrders]);

  const sortedScreens = useMemo(() => {
    return sampleInScreenOrder
      ?.filter((order, i, ar) => {
        return (
          ar
            ?.map((x) => {
              return x?.screen;
            })
            .indexOf(order?.screen) === i
        );
      })
      ?.map((x) => {
        let count = data?.filter((y) => {
          return y.screen === x.screen;
        })?.length;
        return { screen: x?.screen, count };
      });
  }, [status]);

  const summaryRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => summaryRef.current,
  });

  const SummaryReport = () => {
    return (
      <div
        id="summaryReport"
        className=" relative z-10  max-w-[300px] text-[8px] p-[10px]"
        ref={summaryRef}
      ></div>
    );
  };

  const onRowClick = (row) => () => {
    setSelectedOrder(row?.original);
    setShow(true);
  };
  return (
    <div className="bg-primary">
      <Modal openStatus={openStatus}>
        <p className="text-center text-[#FEE27D] py-2 text-[20px]  ">
          {" "}
          In Screen Orders
        </p>
        <div className="flex justify-around">
          <select
            className="rounded-lg ml-[50px]  mr-3 h-[38px]"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value={""}>
              All Orders ( {sampleInScreenOrder?.length} )
            </option>
            <option value={"Pending"}>
              Pending (
              {
                sampleInScreenOrder?.filter((x) => {
                  return x.status === "Pending";
                })?.length
              }{" "}
              )
            </option>
            <option value={"Completed"}>
              Completed (
              {
                sampleInScreenOrder?.filter((x) => {
                  return x.status === "Completed";
                })?.length
              }{" "}
              )
            </option>
          </select>
          <select
            className="rounded-lg ml-[50px]  mr-3 h-[38px]"
            onChange={(x) => {
              setScreen(x.target.value);
            }}
          >
            <option value={""}>All Screens </option>
            {sortedScreens?.map((screen) => {
              return (
                <option value={screen?.screen}>
                  {screen?.screen + " (" + screen.count + ")"}
                </option>
              );
            })}
          </select>
          <button
            type="submit"
            className="  py-1 px-3  bg-[#FEE27D] text-primary rounded flex items-center justify-center "
          >
            <span>Refresh</span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-primary"
              // status={userSalesStatus}
            />
          </button>
        </div>
        <InScreenOrdersTable
          closeFunc={closeFunc}
          onRowClick={onRowClick}
          data={data}
        />

        {/* {show && (
          <OrderDetailsModal
            show={show}
            onClose={() => {
              setShow(false);
            }}
            selectedOrder={selectedOrder}
          />
        )} */}
      </Modal>
    </div>
  );
};
export default InScreenModal;
