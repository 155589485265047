import React, { Fragment } from "react";
import Modal from "../../UI/Modal";
import NumPad from "react-numpad";
import { numToCurrency } from "../../../utils/currencyHelper";
import { useTranslation } from "react-i18next";

const OtherChannelModal = ({
  openStatus,
  cashierSession,
  setChannelValues,
  channelValues,
  customChannels,
  onClose,
}) => {
  const { t } = useTranslation(["Admin"]);

   const handleNumPadChange = (channelId, amount) => {
    setChannelValues((prev) => ({
      ...prev,
      [channelId]: {
        ...prev[channelId],
        actual: amount||0,
      },
    }));
  };
  return (
    <Modal
      closeFunc={onClose}
      openStatus={openStatus}
      panelStyles="bg-white p-[30px] max-w-[700px]"
    >
      
      <div className="flex justify-between">
        <div></div>
          <p className="text-center text-[20px] font-bold">
            {t("modals.session.custom")}
          </p>
        <button
          onClick={onClose}
          className="font-bold text-[20px] border-2 rounded px-2 text-blue-500"
        >
          X
        </button>
      </div>
      <div className="mt-7 grid grid-cols-3 gap-x-5 gap-y-8 mb-2">
        {customChannels?.map((channel) => (
          <Fragment key={channel.id}>
            <div className="flex flex-col text-center gap-2 items-center deleteModal">
              <label>{`Expected ${channel?.name}`}</label>
              <p className="  ">{numToCurrency(channelValues[channel?.id]?.expected||0)}</p>
            </div>
            <div className="flex flex-col gap-2 items-center deleteModal">
              <label>{`Actual ${channel?.name}`}</label>
              <NumPad.Number
                placeholder={`Actual ${channel?.name}`}
                value={channelValues[channel?.id]?.actual}
                onChange={(amount) =>handleNumPadChange(channel.id,amount) }

              />
            </div>
            <div className="flex flex-col text-center gap-2 items-center deleteModal">
              <label>{`${channel?.name} Variance:`}</label>
              <p
                className={`${channelValues[channel?.id]?.actual -
                  channelValues[channel?.id]?.expected<
                  0 && "text-red-500"} ${channelValues[channel?.id]?.actual -
                  channelValues[channel?.id]?.expected>0&&'text-green-500'}`}
              >
                {numToCurrency(
                  channelValues[channel?.id]?.actual - channelValues[channel?.id]?.expected||0
                )}
              </p>
            </div>
          </Fragment>
        ))}
        </div>    
        <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={onClose}
          className="relative min-w-[150px] flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary"
        >
          <span>Close</span>
        </button>
      </div>
    </Modal>
  );
};

export default OtherChannelModal;
