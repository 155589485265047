const dataForJsonFile = (data) => {
  //formatting tax
  const {
    taxData,
    isRefund = false,
    order,
    userData,
    paymentMode,
    cashAmount,
    invoiceRefOrig,
  } = data;

  const tax = taxData?.[0]?.taxes?.find((x) => {
    return !x?.isSectorial;
  });

  //formatting date the way they want
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because month index starts from 0
  const day = String(currentDate.getDate()).padStart(2, "0");
  const invoiceDate = year + month + day;

  //getting payment code
  let paymentCode = "";
  // cash = e
  // carc = c
  // mobilemoney = m
  // others = a
  const paymentCodeOptions = [
    { channel: "channel-55402e", code: "E" },
    { channel: "channel-c2e01f", code: "C" },
    { channel: "channel-2d1980", code: "M" },
  ];

  const mobileOperators = [
    {
      name: "MTN",
      channelId: "992a4993-8f18-4937-80c1-37ac564bc2e9",
      code: "MTN",
    },
    {
      name: "Moov",
      channelId: "71b00635-a9fc-41fe-971b-eda37df74c02",
      code: "MOV",
    },
    {
      name: "Celtis",
      channelId: "5fcefbb4-03e7-48c0-898d-88778ca0e53a",
      code: "CLT",
    },
  ];

  // it would be others channel then the mobile operator

  if (paymentMode?.length === 1) {
    //if it finds the channel it sets the payment code else it sets it to the code for others

    paymentCode =
      paymentCodeOptions?.find((x) => {
        return x.channel === paymentMode[0]?.channelId;
      })?.code || "A";
  } else {
    //find the channel with the highest value
    const maxChannel = paymentMode.reduce((max, obj) => {
      return obj.amount > max.amount ? obj : max;
    }, paymentMode[0]);

    //if it finds the channel it sets the payment code else it sets it to the code for others
    paymentCode =
      paymentCodeOptions?.find((x) => {
        return x.channel === maxChannel?.channelId;
      })?.code || "A";
  }

  //formatting itemList
  const formattedItems = order?.orderItems?.map((item, index) => {
    return {
      itemNo: index + 1,
      itemCode: item?.ticketId || item?.id,
      itemDesc: item?.showtimeId ? `Ticket for ${item?.name}` : item?.name,
      vatRate: tax?.rate || 0,
      itemPrice: item?.price,
      itemQty: item?.orderQuantity,
      itemAmount: item?.price * item?.orderQuantity,
      ChangeDesc: "",
      origPrice: item?.price,
    };
  });

  let cashGiven = 0;
  let chgReturn = 0;

  if (paymentCode === "E") {
    cashGiven = order.orderTotalAmount;
    if (cashAmount) {
      cashGiven = +cashAmount;
      chgReturn = order.orderTotalAmount - cashAmount;
    }
  }

  return {
    invoiceType: !isRefund ? "NS" : "NR",
    invoiceNumber: taxData?.[0]?.orderSerial,
    invoiceDate,
    invoiceRefOrig: isRefund ? invoiceRefOrig : "",
    totalVatAmount: Math.abs(Math.round(tax?.amount)) || 0,
    totalAmount: order.orderTotalAmount,
    paymentCode,
    cashGiven,
    chgReturn: Math.abs(chgReturn),
    mobileOperator:
      paymentCode === "M" &&
      mobileOperators?.find((x) => {
        return x.channelId === paymentMode[0]?.otherChannelId;
      })
        ? mobileOperators?.find((x) => {
            return x.channelId === paymentMode[0]?.otherChannelId;
          })?.code
        : "",
    seller: {
      personId: userData?.id,
      personName: userData?.firstName + " " + userData?.lastName,
    },
    buyer: {
      customerType: "NVTR",
      customerName: "",
      customerAdd: "",
      customerPhone: "",
      customerEmail: "",
      customerTin: "",
      customerExempt: "",
    },
    itemList: formattedItems,
  };
};
const downloadJsonFile = (taxData) => {
  // Define the JSON data
  const jsonData = dataForJsonFile(taxData);

  // Convert JSON object to a string
  const jsonString = JSON.stringify(jsonData, null, 2);

  // Create a Blob object with the JSON data
  const blob = new Blob([jsonString], { type: "application/json" });

  // Create a temporary anchor element
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = `${jsonData?.invoiceNumber}.json`; // Specify the file name

  // Append the anchor element to the document body
  document.body.appendChild(a);

  // Trigger a click on the anchor element to start the download
  a.click();

  // Remove the anchor element from the document body
  document.body.removeChild(a);
};
export const downloadMcf = (data) => {
  downloadJsonFile(data);
};
