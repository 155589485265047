import React, { useState } from "react";

import Pop1 from "../../../assets/images/pop1.png";
import Pop2 from "../../../assets/images/pop2.png";
import Tick1 from "../../../assets/images/tick1.png";
import Tick2 from "../../../assets/images/tick2.png";
import Ticket from "./ticket";
import Concession from "./concession";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation(["Home"]);
  const [module, setModule] = useState("concessions");
  const { showtimesList, status } = useSelector(state => state.showtimes);
  const showtimesDate =
    new Date(showtimesList?.paramFilter?.dateTo).toDateString() ||
    new Date().toDateString();

  return (
    <>
      <div className="flex justify-center h-[8%]">
        <div
          className={`w-[40%] flex items-center px-2 ${
            module === "concessions" ? " bg-primary text-white" : "text-primary"
          }`}
          onClick={() => {
            setModule("concessions");
          }}
        >
          <img
            src={module === "concessions" ? Pop2 : Pop1}
            alt="popcorn"
            className="pr-2"
          />{" "}
          {t("tabs.a")}
        </div>
        <div
          className={`w-[40%] flex items-center px-2 ${
            module === "tickets" ? " bg-primary text-white" : "text-primary"
          }`}
          onClick={() => {
            setModule("tickets");
          }}
        >
          <img src={module === "tickets" ? Tick2 : Tick1} alt="ticket" />
          {t("tabs.b", { date: showtimesDate })}
        </div>
      </div>
      <div className="h-[92%]">
        {module === "concessions" && (
          <Concession serviceArea={"Cinema"} serviceAreaNum={0} />
        )}
        {module === "tickets" && <Ticket />}
      </div>
    </>
  );
};

export default Home;
