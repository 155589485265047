import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { STATUS } from "../../../utils/constants";
import { Spinner2 } from "../../UI/Spinner2";
import { useTranslation } from "react-i18next";

const FilmDetailsModal = ({ openStatus, closeFunc }) => {
  const { t } = useTranslation(["Home", "Common"]);

  const { filmDetails, filmDetailsStatus } = useSelector(
    state => state.showtimes
  );

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[650px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Film Details
                </Dialog.Title> */}
                {filmDetailsStatus === STATUS.PENDING && (
                  <div className="flex justify-center">
                    <Spinner2 />
                  </div>
                )}
                {Object.keys(filmDetails).length > 0 && (
                  <>
                    <p className="font-bold text-[20px] text-primary my-5">
                      {filmDetails?.description}
                    </p>
                    <p className="flex justify-between my-5">
                      <span>
                        {t("ticket.film.details.time", {
                          hour: Math.trunc(filmDetails?.duration / 60),
                          minute: filmDetails?.duration % 60
                        })}
                      </span>{" "}
                      <span>{filmDetails?.filmRating}</span>{" "}
                      <span>
                        {filmDetails?.filmGenre
                          ?.map(film => film.genre)
                          .toString()}
                      </span>
                    </p>
                    <p className="my-5">{filmDetails?.synopsis}</p>
                  </>
                )}
                <div className="flex justify-center">
                  <button
                    type="button"
                    className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeFunc}
                  >
                    {" "}
                    {t("Common:button.close")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default FilmDetailsModal;
