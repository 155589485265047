import Concession from "../home/concession";
import { useTranslation } from "react-i18next";

const Cafe = () => {
  const { t } = useTranslation(["Cafe"]);
  return (
    <>
      <div className="flex justify-center h-[8%] items-center">
        <p className="text-primary font-bold text-[20px]">{t("title")}</p>
      </div>
      <div className="h-[92%]">
        <Concession serviceArea={"Cafe"} serviceAreaNum={3} />
      </div>
    </>
  );
};
export default Cafe;
