import React from "react";
import { useSelector } from "react-redux";
import { numToCurrency } from "../../../utils/currencyHelper";
import { useTranslation } from "react-i18next";
import Ticket from "./Ticket";

const getCurrentOrderId = (orders, offlineSaleId) => {
  if (orders.printLastOrder) {
    return JSON.parse(localStorage.getItem("lastOrderId"));
  }
  return offlineSaleId || orders.createdOrderId || orders.completedBookingId;
};

const ReceiptContainer = React.forwardRef(
  ({ offlineSaleId = "", methodsList = [] }, ref) => {
    const orders = useSelector((state) => state.orders);
    const { userData, cinemaSettings, offlineMode } = useSelector(
      (state) => state.profile
    );

const isSeatAllocated = cinemaSettings?.seatAllocation
    const { t } = useTranslation(["receipt"]);
    const currentOrderId = getCurrentOrderId(orders, offlineSaleId);

    const taxes = orders?.taxData?.length > 0 ? orders.taxData[0].taxes : [];

    const hasTickets = orders?.orderItems?.find((item) => {
      return item?.showtimeId;
    });

    const isSilverbird = userData?.circuitId === "circuit-2e9293e2";

    return (
      <div
        id="receipt"
        className=" relative z-10  max-w-[300px] text-[8px] p-[10px]"
        ref={ref}
      >
        {cinemaSettings.logoUrl && cinemaSettings.cinemaLogo && (
          <div
            className=" mb-1  w-full h-[70px] mx-[20px]  bg-no-repeat bg-contain bg-center"
            style={{
              backgroundImage: `url(${cinemaSettings.logoUrl})`,
            }}
          ></div>
        )}
        {((cinemaSettings.customerReceipts === 0 && !isSilverbird) ||
          (isSilverbird && !hasTickets) ||
          (isSilverbird && orders.completedBookingId)) && (
          <>
            <p className="text-[14px] text-center font-bold">
              {userData.cinema}
            </p>
            <table className="w-[100%] text-[10px] ">
              <thead>
                <tr className="text-left">
                  <th className="w-[50%]">{t("order_details.header.item")}</th>
                  <th className="w-[10%] text-center">
                    {t("order_details.header.unit")}
                  </th>
                  <th className="w-[20%]">{t("order_details.header.price")}</th>
                  <th className="w-[20%]">
                    {t("order_details.header.subtotal")}
                  </th>
                  {isSeatAllocated ?
                  <th>Seat</th>:null
                  }
                </tr>
              </thead>
              <tbody>
                {orders?.orderItems?.length > 0 &&
                  orders?.orderItems?.filter(item => !item?.hideItem).map((item, index) => {
                    return (
                      <tr className="border-b-2" key={index}>
                        <td className="w-[50%]">{item.name || item.title}</td>
                        <td className="w-[10%] text-center">
                          {item.orderQuantity}
                        </td>
                        <td className="w-[20%]">
                          {numToCurrency(item.price, true)}
                        </td>
                        <td className="w-[20%]">{` ${numToCurrency(
                          item.price * item.orderQuantity,
                          true
                        )}  `}</td>
                        {isSeatAllocated ?
                        <td>{item.seatIdentifier||"" }</td>:null
                        }
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {cinemaSettings?.includeTax && (
              <>
                <p className="text-center  mt-1 font-bold">
                  {" "}
                  {t("order_details.header.tax")}
                </p>
                <p className=" mb-1">
                  {taxes.map((tax) => {
                    return (
                      <span>
                        <span className="font-bold">{tax.name}: </span>{" "}
                        <span>{numToCurrency(tax.amount, true)}, </span>
                      </span>
                    );
                  })}
                </p>
              </>
            )}

            {/* <p className="text-center font-bold text-[14px]">{`Total: ${numToCurrency(
              orders.orderTotalAmount,
              true
            )}`}</p> */}
            {/* <p className="text-center text-[10px] mt-1"> Taxes</p>
            {Taxes.map((tax) => {
              return (
                <p className=" flex px-2 justify-between text-[10px]">
                  {" "}
                  <span>{tax.title}</span>{" "}
                  <span> {numToCurrency(tax.amount, true)}</span>
                </p>
              );
            })} */}
            <p className="text-center font-bold text-[14px]">{`${t(
              "labels.total"
            )}: ${numToCurrency(orders.orderTotalAmount, true)}`}</p>
            <div className="flex justify-between px-2">
              <p className="text-center">
                {t("labels.staff")}:{" "}
                {`${userData.firstName}  ${userData.lastName}`}
              </p>
              <p className="text-center">
                {new Date().toDateString() +
                  " " +
                  new Date().toLocaleTimeString()}
              </p>
            </div>
            <p className="text-center text-[10px]">
              {" "}
              {`${t("labels.orderId")}: ${currentOrderId}`}
            </p>

            {methodsList?.length > 0 && (
              <p className="py-2">
                {`${
                  methodsList?.length > 1
                    ? t("labels.paymentmethod.multiple")
                    : t("labels.paymentmethod.single")
                }`}
                : {methodsList.toString()}
              </p>
            )}
            <p className="text-center mb-[15px]">
              {t("footer.top")}
              <br />
              {t("footer.bottom")}
            </p>

            <hr
              className={`mt-[30px] font-[15px] ${
                orders.orderItems.find((item) => {
                  return item.showtimeId;
                }) ||
                cinemaSettings.includeMerchantCopy ||
                (offlineMode && !isSilverbird)
                  ? "print:break-after-page"
                  : ""
              } `}
            />
          </>
        )}
        {/* merchant copy of receipt...include based on settings */}
        {cinemaSettings.includeMerchantCopy && (
          <>
            <p className=" text-[14px] font-bold text-center py-3 ">
              {t("receipt_type.header.merchant")}
            </p>
            <table className="w-[100%] text-[10px] ">
              <thead>
                <tr className="text-left">
                  <th className="w-[50%]">{t("order_details.header.item")}</th>
                  <th className="w-[10%] text-center">
                    {t("order_details.header.unit")}
                  </th>
                  <th className="w-[20%]">{t("order_details.header.price")}</th>
                  <th className="w-[20%]">
                    {t("order_details.header.subtotal")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.orderItems.length > 0 &&
                  orders.orderItems?.filter(item => !item?.hideItem)?.map((item, index) => {
                    return (
                      <tr className="border-b-2" key={index}>
                        <td className="w-[50%]">{item.name || item.title}</td>
                        <td className="w-[10%] text-center">
                          {item.orderQuantity}
                        </td>
                        <td className="w-[20%]">
                          {numToCurrency(item.price, true)}
                        </td>
                        <td className="w-[20%]">{`${numToCurrency(
                          item.price * item.orderQuantity,
                          true
                        )}`}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <p className="text-center font-bold text-[14px]">{`${t(
              "labels.total"
            )}:  ${numToCurrency(orders.orderTotalAmount, true)}`}</p>
            <div className="flex justify-between px-2">
              <p className="text-center">
                {t("labels.staff")}:{" "}
                {`${userData.firstName}  ${userData.lastName}`}
              </p>
              <p className="text-center">
                {new Date().toDateString() +
                  " " +
                  new Date().toLocaleTimeString()}
              </p>
            </div>
            <p className="text-center text-[10px]">
              {" "}
              {`${t("labels.orderId")}: ${currentOrderId}`}
            </p>
            {methodsList?.length > 0 && (
              <p className="py-2">
                {`${
                  methodsList?.length > 1
                    ? t("labels.paymentmethod.multiple")
                    : t("labels.paymentmethod.single")
                }`}
                :{" "}
              </p>
            )}
            <p className="text-center mb-[15px]">
              {t("footer.top")}
              <br />
              {t("footer.bottom")}
            </p>

            <hr
              className={`mt-[15px] font-[15px] ${
                orders.orderItems.find((item) => {
                  return item.showtimeId;
                }) || offlineMode
                  ? "print:break-after-page"
                  : ""
              } `}
            />
          </>
        )}
        {offlineMode && !isSilverbird && (
          <>
            <p className=" text-[14px] font-bold text-center py-3 ">
              {t("receipt_type.header.offline")}
            </p>
            <table className="w-[100%] text-[10px] ">
              <thead>
                <tr className="text-left">
                  <th className="w-[50%]">{t("order_details.header.item")}</th>
                  <th className="w-[10%] text-center">
                    {t("order_details.header.unit")}
                  </th>
                  <th className="w-[20%]">{t("order_details.header.price")}</th>
                  <th className="w-[20%]">
                    {t("order_details.header.subtotal")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.orderItems.length > 0 &&
                  orders.orderItems?.filter(item => !item?.hideItem)?.map((item, index) => {
                    return (
                      <tr className="border-b-2" key={index}>
                        <td className="w-[50%]">{item.name || item.title}</td>
                        <td className="w-[10%] text-center">
                          {item.orderQuantity}
                        </td>
                        <td className="w-[20%]">
                          {numToCurrency(item.price, true)}
                        </td>
                        <td className="w-[20%]">{` ${numToCurrency(
                          item.price * item.orderQuantity
                        )}  `}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <p className="text-center font-bold text-[14px]">{`${t(
              "labels.total"
            )}: ${numToCurrency(orders.orderTotalAmount)}`}</p>
            <div className="flex justify-between px-2">
              <p className="text-center">
                {t("labels.staff")}:{" "}
                {`${userData.firstName}  ${userData.lastName}`}
              </p>
              <p className="text-center">
                {new Date().toDateString() +
                  " " +
                  new Date().toLocaleTimeString()}
              </p>
            </div>
            <p className="text-center text-[10px]">
              {" "}
              {`${t("labels.orderId")}: ${currentOrderId}`}
            </p>
            {methodsList?.length > 0 && (
              <p className="py-2">{methodsList.toString()}</p>
            )}
            <p className="text-center mb-[15px]">
              {t("footer.top")}
              <br />
              {t("footer.bottom")}
            </p>

            <hr
              className={`mt-[15px] font-[15px] ${
                orders.orderItems.find((item) => {
                  return item.showtimeId;
                })
                  ? "print:break-after-page "
                  : ""
              } `}
            />
          </>
        )}
        <Ticket currentOrderId={currentOrderId} methodsList={methodsList} />
      </div>
    );
  }
);

export default ReceiptContainer;
