import Concession from "../home/concession";
import { useTranslation } from "react-i18next";

const Arcade = () => {
  const { t } = useTranslation(["Arcade"]);

  return (
    <>
      <div className="flex justify-center h-[8%] items-center">
        <p className="text-primary font-bold text-[20px]">{t("title")}</p>
      </div>
      <div className="h-[92%]">
        <Concession serviceArea={"Arcade"} serviceAreaNum={2} />
      </div>
    </>
  );
};
export default Arcade;
