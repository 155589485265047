import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import {
  addToOrder,
  checkVoucherCode,
  setKeyboardConfiguration,
  setShowKeyboard
} from "../../../redux/slices";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner } from "../../UI/Spinner";
import { useTranslation } from "react-i18next";
import useSchema from "../../../hooks/useSchema";
import { useKeyboard } from "../../../context/KeyboardContext";

const VoucherModal = ({ openStatus, closeFunc, voucherTicketData }) => {
  const { t } = useTranslation(["Home", "Common"]);
  const dispatch = useDispatch();
  const { error, status } = useSelector(state => state.voucher);
  const { userData } = useSelector(state => state.profile);
  const [voucherCode, setVoucherCode] = useState("");
const { setSetSharedInput } = useKeyboard()
  const { voucherSchema } = useSchema();
  console.log("up")

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(voucherSchema),
    defaultValues: {
      voucherCode: ""
    },
    mode: "onChange"
  });

  useEffect(() => {
    setValue("voucherCode", voucherCode);
  }, [voucherCode]);

  const onSubmit = data => {
    dispatch(
      checkVoucherCode({
        voucherCode: data?.voucherCode,
        ticketId: voucherTicketData?.id,
        cinemaId: userData?.cinemaId,
        filmId:voucherTicketData?.filmId,
      })
    )
      .unwrap()
      .then(data => {
        let newPrice = 0;
        //check to evaluate price based on value of the voucher
        if (data.type === "Amount") {
          newPrice = voucherTicketData.price - data.value;
        } else {
          newPrice =
            voucherTicketData.price -
            [voucherTicketData.price * [data.value / 100]];
        }
        //check to ensure new price is not a negative value after adding voucher

        if (newPrice < 0) {
          newPrice = 0;
        }

        dispatch(
          addToOrder({
            ...voucherTicketData,
            price: newPrice,
            voucherCode: { voucherId: data.id, voucherCodeId: data.code.id },
            voucherCodeId: data.code.id
          })
        );
        setValue("voucherCode", "");
        setVoucherCode("");
        closeFunc();
      })
      .catch(err => console.log(error));
  };

  return (
    <Transition appear show={openStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          // setShowError(false);
          // setEnteredPin("");
          // closeFunc();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Enter Admin Pin
                </Dialog.Title> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <p className="text-red-500 text-center"> {error}</p>
                  )}
                  <div className="mt-2 deleteModal flex justify-center">
                    <div>
                      <label className="text-center block">
                        {t("ticket.voucher.label")}
                      </label>
                      <br />
                      <input
                        type="text"
                        value={voucherCode}
                        placeholder={t("ticket.voucher.placeholder")}
                        className=" w-[100%]  h-[50px] border border-secondary rounded py-2 px-3"
                        onClick={() => {
                          dispatch(setShowKeyboard(true));
                          dispatch(
                            setKeyboardConfiguration({
                              type: "alphaNumeric",
                              sharedInput: voucherCode,
                              placeHolder: t(
                                "Common:keyboard.placeholder.voucher"
                              ),
                              // setSharedInput: x => {
                              //   setVoucherCode(x);
                              // }
                            })
                          );
                          setSetSharedInput(() => setVoucherCode)
                        }}
                      />
                    </div>
                    {errors?.voucherCode?.message && (
                      <p className="text-red-500 text-center">
                        {errors?.voucherCode?.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-5 flex justify-center">
                    <button
                      type="submit"
                      className=" mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      <span>{t("Common:button.submit")}</span>
                      <Spinner
                        className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                        status={status}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setValue("voucherCode", "");
                        setVoucherCode("");
                        closeFunc();
                      }}
                      className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {t("Common:button.cancel")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default VoucherModal;
