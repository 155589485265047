import { useState, useEffect, useMemo } from "react";
import { numToCurrency } from "../../../../utils/currencyHelper";
import Modal from "../../../UI/Modal";
import { Spinner } from "../../../UI/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  initiatePartialRefund,
  getSalesByUser,
  getOrderById,
  setShowKeyboard,
  setKeyboardConfiguration,
} from "../../../../redux/slices";
import { partialRefundSchema } from "../../../../utils/schemas";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { STATUS } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";
import { useKeyboard } from "../../../../context/KeyboardContext";
import { downloadMcf } from "../../../../utils/mcfHelper";
const PartialRefundModal = ({ openStatus, closeFunc, orderInfo }) => {
  const { t } = useTranslation(["Admin", "Common"]);
  const dispatch = useDispatch();
  const [refundReason, setRefundReason] = useState("");
  const { userData } = useSelector((state) => state.profile);
  const { setSetSharedInput } = useKeyboard();
  const {
    partialRefundStatus,
    partialRefundErr,
    singleOrderStatus,
    singleOrder,
    customChannels,
  } = useSelector((state) => state.orders);
  const [formError, setFormError] = useState(false);
  const { register, control, watch, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(partialRefundSchema),
    defaultValues: {},
  });

  const { fields } = useFieldArray({
    control,
    name: "refundTickets",
  });

  const { fields: fields2 } = useFieldArray({
    control,
    name: "refundItems",
  });

  const { fields: fields3 } = useFieldArray({
    control,
    name: "refundPaymentChannels",
  });

  useEffect(() => {
    dispatch(getOrderById(orderInfo?.orderId))
      .unwrap()
      .then((data) => {
        const items = data?.itemSales?.map((item) => {
          return {
            itemSaleId: item?.id,
            quantity: 0,
            name: item?.item,
            max: item?.quantity,
            price: item?.price,
          };
        });
        const tickets = data?.ticketSales?.map((ticket) => {
          return {
            ticketSaleId: ticket?.id,
            quantity: 0,
            name: ticket?.film,
            max: ticket?.quantity,
            price: ticket?.ticketPrice,
          };
        });

        const channels = data?.paymentChannels?.map((channel) => {
          return {
            channelId: channel?.channelId,
            amount: 0,
            channel: channel?.channel,
            maxAmount: channel?.amount,
          };
        });

        const applicableChannels = data.paymentChannels
          ?.filter((data) => {
            return (
              data?.channel === "Card" ||
              data?.channel === "Cash" ||
              data?.channel === "Transfer" ||
              customChannels?.find((x) => {
                return x.id === data?.channelId;
              })
            );
          })
          ?.map((x) => {
            return x?.amount;
          });

        const applicableChannelsTotal = applicableChannels?.reduce(
          (a, b) => a + b,
          0
        );

        reset({
          refundItems: items,
          refundTickets: tickets,
          amountInSession: applicableChannelsTotal,
          refundPaymentChannels: channels,
        });
      })
      .catch(() => {});
  }, [orderInfo]);

  const formData = watch();

  const amountToBeRefunded = useMemo(() => {
    const amountInSession = formData.amountInSession;

    let ticketRefundAmount = 0;
    let itemRefundAmount = 0;

    if (formData?.refundItems?.length > 0) {
      itemRefundAmount = formData?.refundItems
        ?.map((x) => {
          return x.quantity * x.price;
        })
        .reduce((a, b) => a + b, 0);
    }

    if (formData?.refundTickets?.length > 0) {
      ticketRefundAmount = formData?.refundTickets
        ?.map((x) => {
          return x.quantity * x.price;
        })
        .reduce((a, b) => a + b, 0);
    }

    let refundAmount = ticketRefundAmount + itemRefundAmount;

    if (amountInSession >= refundAmount) {
      return refundAmount;
    }

    if (amountInSession < refundAmount) {
      return amountInSession;
    }

    return 0;
  }, [formData]);

  const partialRefundFunc = async (data) => {
    const body = {
      reason: data.reason || null,
      // supplimentaryOrderId: data.supplimentaryOrderId || null,
      refundItems: data?.refundItems?.find((x) => {
        return x?.quantity > 0;
      })
        ? data?.refundItems
            ?.filter((x) => {
              return x?.quantity > 0;
            })
            ?.map((x) => {
              return { quantity: x?.quantity, itemSaleId: x?.itemSaleId };
            })
        : [],
      refundTickets: data?.refundTickets?.find((x) => {
        return x?.quantity > 0;
      })
        ? data?.refundTickets
            ?.filter((x) => {
              return x?.quantity > 0;
            })
            ?.map((x) => {
              return { quantity: x?.quantity, ticketSaleId: x?.ticketSaleId };
            })
        : [],
      refundPaymentChannels: data?.refundPaymentChannels?.filter((x) => {
        return x?.amount > 0;
      })
        ? data?.refundPaymentChannels
            ?.filter((x) => {
              return x?.amount > 0;
            })
            ?.map((x) => {
              //this if check helps to format for cases where its a custom channel..Similar to order creation,
              //it makes the channelId the "others" channelId and puts the actual channelId as otherChannelId
              if (
                customChannels?.find((i) => {
                  return i.id === x?.channelId;
                })
              ) {
                return {
                  channelId: "channel-2d1980",
                  otherChannelId: x?.channelId,
                  amount: x?.amount,
                };
              } else {
                return { channelId: x?.channelId, amount: x?.amount };
              }
            })
        : [],
      refundVouchers: [],
    };

    if (body.refundItems.length < 1 && body.refundTickets.length < 1) {
      setFormError(t("modals.order.refund.partial.errors.quantity"));
      return;
    }

    if (
      amountToBeRefunded &&
      body.refundPaymentChannels
        ?.map((x) => {
          return x?.amount;
        })
        .reduce((a, b) => a + b, 0) !== +amountToBeRefunded
    ) {
      setFormError(t("modals.order.refund.partial.errors.quantity"));
      return;
    }

    setFormError(false);
    dispatch(initiatePartialRefund({ ...body, orderId: orderInfo.orderId }))
      .unwrap()
      .then((data) => {
        downloadMcf({
          taxData: data,
          isRefund: true,
          order: {
            orderTotalAmount: Math.abs(data?.[0]?.orderTotal),
            orderItems: [
              ...singleOrder?.itemSales
                ?.filter((i) => {
                  return body?.refundItems?.find((item) => {
                    return i.id === item?.itemSaleId;
                  });
                })
                ?.map((x) => {
                  return {
                    id: x.itemId,
                    name: x.item,
                    price: x.price,
                    orderQuantity: body?.refundItems?.find((item) => {
                      return x.id === item?.itemSaleId;
                    }).quantity,
                  };
                }),
              ...singleOrder?.ticketSales
                ?.filter((i) => {
                  return body?.refundTickets?.find((item) => {
                    return i.id === item?.ticketSaleId;
                  });
                })
                ?.map((x) => {
                  return {
                    id: x.ticketId,
                    name: x.film,
                    price: x.ticketPrice,
                    orderQuantity: body?.refundTickets?.find((item) => {
                      return x.id === item?.ticketSaleId;
                    }).quantity,
                    showtimeId: x.showtimeId,
                  };
                }),
            ],
          },
          userData,
          paymentMode: body?.refundPaymentChannels,
          cashAmount:
            body?.refundPaymentChannels?.find((x) => {
              return x.channelId === "channel-55402e";
            })?.amount || 0,
          invoiceRefOrig: singleOrder?.serialNo,
        });
        dispatch(
          getSalesByUser({
            userId: userData.id,
            todayDate: new Date().toISOString(),
          })
        );
        closeFunc();
      })
      .catch((error) => {
        setFormError(error);
      });
  };

  return (
    <Modal openStatus={openStatus} panelStyles="bg-white p-[30px] w-[40vw] ">
      {singleOrderStatus === STATUS.PENDING && (
        <div className="flex justify-center p-5 items-center">
          <Spinner
            className="!w-10 !h-10 mx-1 text-white fill-primary"
            status={singleOrderStatus}
          />
        </div>
      )}
      <form onSubmit={handleSubmit(partialRefundFunc)} className="p-3">
        <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
          <div className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]">
            <span className="w-[50%] text-[#C96FCC] font-bold">
              {t("modals.order.details.name")}
            </span>{" "}
            <span className="w-[10%] text-[#C96FCC] font-bold">
              {t("modals.order.details.quantity")}
            </span>
          </div>
          {fields?.map((field, index) => {
            return (
              <div
                className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                key={index}
              >
                <p className="truncate">{field.name}</p>

                <div className="flex items-center justify-between">
                  <button
                    className="text-primary text-center bg-white font-extrabold rounded shadow-md p-2  flex items-center justify-center"
                    type={"button"}
                    onClick={() => {
                      if (watch(`refundTickets.${index}.quantity`) > 0) {
                        setValue(
                          `refundTickets.${index}.quantity`,
                          watch(`refundTickets.${index}.quantity`) - 1
                        );
                      }
                    }}
                  >
                    -
                  </button>
                  <span className="mx-3">
                    {" "}
                    {watch(`refundTickets.${index}.quantity`)}
                  </span>
                  <button
                    className="text-primary text-center bg-white font-extrabold rounded shadow-md p-2 flex items-center justify-center"
                    type={"button"}
                    onClick={() => {
                      if (
                        watch(`refundTickets.${index}.quantity`) < field?.max
                      ) {
                        setValue(
                          `refundTickets.${index}.quantity`,
                          watch(`refundTickets.${index}.quantity`) + 1
                        );
                      }
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
          {fields2?.map((field, index) => {
            return (
              <div
                className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                key={index}
              >
                <p className="truncate">{field.name}</p>
                <div className="flex items-center justify-between">
                  <button
                    className="text-primary text-center bg-white font-extrabold rounded shadow-md p-2  flex items-center justify-center"
                    type={"button"}
                    onClick={() => {
                      if (watch(`refundItems.${index}.quantity`) > 0) {
                        setValue(
                          `refundItems.${index}.quantity`,
                          watch(`refundItems.${index}.quantity`) - 1
                        );
                      }
                    }}
                  >
                    -
                  </button>
                  <span className="mx-3">
                    {" "}
                    {watch(`refundItems.${index}.quantity`)}
                  </span>
                  <button
                    className="text-primary text-center bg-white font-extrabold rounded shadow-md p-2 flex items-center justify-center"
                    type={"button"}
                    onClick={() => {
                      if (watch(`refundItems.${index}.quantity`) < field?.max) {
                        setValue(
                          `refundItems.${index}.quantity`,
                          watch(`refundItems.${index}.quantity`) + 1
                        );
                      }
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <p className="py-3">
          <span>{t("modals.order.refund.partial.label.amount")}: </span>
          <span className="text-[#C96FCC]">
            {numToCurrency(amountToBeRefunded)}
          </span>
        </p>

        {formData.refundPaymentChannels && (
          <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
            <div className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]">
              <span className=" text-[#C96FCC] font-bold">
                {t("modals.order.refund.partial.columns.a")}
              </span>{" "}
              <span className=" text-[#C96FCC] font-bold">
                {t("modals.order.refund.partial.columns.b")}
              </span>
              <span className=" text-[#C96FCC] font-bold">
                {t("modals.order.refund.partial.columns.c")}
              </span>
            </div>
            {fields3?.map((field, index) => {
              return (
                <div
                  className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                  key={index}
                >
                  <p>{field.channel}</p>
                  <p>{numToCurrency(field.maxAmount)}</p>
                  <input
                    type={"number"}
                    className="w-[80px] p-2 rounded bg-app-purple-10"
                    // {...register(`refundPaymentChannels.${index}.amount`)}
                    max={field?.maxAmount}
                    min={0}
                    value={watch(`refundPaymentChannels.${index}.amount`)}
                    onClick={() => {
                      dispatch(setShowKeyboard(true));
                      dispatch(
                        setKeyboardConfiguration({
                          type: "numeric",
                          sharedInput: watch(
                            `refundPaymentChannels.${index}.amount`
                          ),
                          placeHolder: t("Common:keyboard.placeholder.amount"),
                          // setSharedInput: x => {
                          //   setValue(
                          //     `refundPaymentChannels.${index}.amount`,
                          //     x
                          //   );
                          // }
                        })
                      );
                      setSetSharedInput(() => (val) =>
                        setValue(`refundPaymentChannels.${index}.amount`, val)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}

        <div className="mb-2">
          <label htmlFor={"reason"} className="block mb-2">
            {" "}
            {t("modals.order.refund.label")}
          </label>
          <textarea
            id={"reason"}
            className="w-full border-2 p-2 rounded bg-app-purple-10"
            rows={4}
            placeholder={t("modals.order.refund.placeholder")}
            value={watch(`formData?.reason`)}
            onClick={() => {
              dispatch(setShowKeyboard(true));
              dispatch(
                setKeyboardConfiguration({
                  type: "alphaNumeric",
                  sharedInput: watch(`formData?.reason`),
                  placeHolder: t("Common:keyboard.placeholder.refund.reason"),
                  // setSharedInput: x => {
                  //   setValue(`formData?.reason`, x);
                  // }
                })
              );
              setSetSharedInput(() => (val) =>
                setValue(`formData?.reason`, val)
              );
            }}
          ></textarea>
        </div>

        {formError && (
          <p className="text-red-500 italic text-center text-sm p-2">
            {formError}
          </p>
        )}

        <div className="flex justify-around my-2">
          <button
            type="submit"
            className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
          >
            <span> {t("Common:button.confirm")} </span>
            <Spinner
              className="!w-3 !h-3 mx-1 text-white fill-blue-800"
              status={partialRefundStatus}
            />
          </button>
          <button
            className="inline-flex justify-center items-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-white mr-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 "
            onClick={() => {
              closeFunc();
              setRefundReason("");
              setFormError(false);
            }}
          >
            <span> {t("Common:button.cancel")} </span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default PartialRefundModal;
