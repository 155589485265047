import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById, reprintOrder } from "../../../redux/slices";
import { STATUS } from "../../../utils/constants";
import { Spinner2 } from "../../UI/Spinner2";
import { useReactToPrint } from "react-to-print";
import { numToCurrency } from "../../../utils/currencyHelper";
import { Spinner } from "../../UI/Spinner";
import ReprintReceipt from "./ReprintReceipt";
import { claimsKey } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const OrderDetailsModal = ({ onClose = () => {}, show, selectedOrder }) => {
  const { t } = useTranslation(["Admin", "Common"]);
  const dispatch = useDispatch();
  const reprintRef = useRef();
  const [orderData, setOrderData] = useState([]);

  const { singleOrder, singleOrderStatus, reprintOrderStatus } = useSelector(
    state => state.orders
  );
  const { userData } = useSelector(state => state.profile);
  const userClaims = JSON.parse(localStorage?.getItem(claimsKey));

  const handlePrint = useReactToPrint({
    content: () => reprintRef.current
  });

  const printHandler = () => {
    dispatch(
      reprintOrder({
        orderId: selectedOrder?.id,
        doneById: userData?.id,
        action: 10,
        details: `Reprinted order ${selectedOrder?.id}`
      })
    )
      .unwrap()
      .then(() => {
        handlePrint();
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedOrder) {
      dispatch(getOrderById(selectedOrder.id));
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (
      singleOrder?.itemSales?.length > 0 ||
      singleOrder?.ticketSales?.length > 0
    ) {
      const data = [...singleOrder?.itemSales, ...singleOrder?.ticketSales];

      const formattedOrderData = data?.map(x => {
        return {
          id: x.id,
          orderQuantity: x.quantity,
          price: x.price || x.amount,
          name: x.item || x.film
        };
      });

      setOrderData(formattedOrderData);
    }
  }, [singleOrder]);

  let singleItems = [];
  let tickets = [];

  if (singleOrder?.itemSales?.length > 0) {
    singleItems = singleOrder?.itemSales?.map(item => {
      return {
        name: item.item,
        amount: item.amount,
        quantity: item.quantity,
        unitPrice: item.price
      };
    });
  }
  if (singleOrder?.ticketSales?.length > 0) {
    tickets = singleOrder?.ticketSales?.map(ticket => {
      return {
        name: ticket.film,
        amount: ticket.amount,
        quantity: ticket.quantity,
        unitPrice: ticket.ticketPrice
      };
    });
  }
  let orderDetails = [...singleItems, ...tickets];

  return (
    <>
      <Transition show={show} appear as={Fragment}>
        <Dialog as="div" className="relative z-[10000]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm max-h-[90vh] text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white bg-[#a86aaa] rounded-t-2xl px-2 py-2 text-center"
                  >
                    {t("modals.order.details.title")}
                  </Dialog.Title>
                  {singleOrderStatus === STATUS.PENDING && (
                    <>
                      <div className="flex justify-center my-[30px]">
                        <Spinner2 />
                      </div>
                    </>
                  )}
                  {singleOrderStatus === STATUS.RESOLVED && (
                    <div className=" py-2">
                      {/* <form onSubmit={handleSubmit(submitHandler)}> */}
                      <div className=" max-h-[60vh] overflow-y-auto border-t-[1px] border-[#7E208080]">
                        <div className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]">
                          <span className="w-[50%] text-[#C96FCC] font-bold">
                            {t("modals.order.details.name")}
                          </span>{" "}
                          <span className="w-[10%] text-[#C96FCC] font-bold">
                            {t("modals.order.details.quantity")}
                          </span>
                          <span className="w-[20%] text-[#C96FCC] font-bold">
                            {t("modals.order.details.price")}
                          </span>
                          <span className="w-[20%] text-[#C96FCC] font-bold">
                            {t("modals.order.details.subTotal")}
                          </span>
                        </div>
                        {orderDetails.map((orderChild, index) => {
                          return (
                            <div
                              className="flex items-center justify-between px-2 py-1 border-b-[1px] border-[#7E208080]"
                              key={index}
                            >
                              <span className="text-[#C96FCC] w-[50%]">
                                {orderChild.name}
                              </span>
                              <span className="text-[#C96FCC]  w-[10%]">
                                {orderChild.quantity}
                              </span>
                              <span className="text-[#C96FCC]  w-[20%]">
                                {numToCurrency(orderChild.unitPrice, true)}
                              </span>
                              <span className="text-[#C96FCC]  w-[20%]">
                                {numToCurrency(orderChild.amount, true)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center gap-3 buttongroup mt-2 ">
                        <button
                          className="p-1 px-3 rounded-md text-[#C96FCC] bg-[#ffe583] shadow-md hover:shadow-sm"
                          type="button"
                          onClick={() => {
                            onClose();
                            orderDetails = [];
                          }}
                        >
                          {t("Common:button.close")}
                        </button>
                        {!singleOrder?.hasRefunds &&
                          !singleOrder?.hasBeenRefunded &&
                          userClaims?.CanReprintOrder && (
                            <button
                              className="inline-flex justify-center items-center p-1 px-3 rounded-md text-[#ffe583] bg-[#C96FCC] shadow-md hover:shadow-sm"
                              type="button"
                              onClick={printHandler}
                            >
                              <span>
                                {t("modals.order.details.button.submit")}
                              </span>{" "}
                              <Spinner
                                className="!w-3 !h-3 mx-1 text-white fill-blue-800"
                                status={reprintOrderStatus}
                              />
                            </button>
                          )}
                      </div>
                      {/* </form> */}
                    </div>
                  )}
                  <div className="hidden">
                    <ReprintReceipt
                      ref={reprintRef}
                      orderData={orderData}
                      selectedOrder={selectedOrder}
                      singleOrder={singleOrder}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { OrderDetailsModal };
